import React, { Fragment, PureComponent } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate, FormatCurrency, FormatDate } from "../../../utils/lang/translate";
import StatusElement from "../_Common/StatusElement";
import Table from "../_Common/Table";
import BtnLg from "../_Common/BtnLg";
import GroupeBtn from "../_Common/GroupeBtn";
import { DeletSvg, EditSvg, CachbackSvg, ExchangeSvg, SubstitutionSvg, FlechDwnSvg, AddSvg, CloseSvg, PrintSvg, MoneySvg, FileDownSvg, ExcelSvg, MoreHSvg, FilterSvg, RefreshSvg, OutSvg, CheckSvg, PickupSvg, PlayListAddSvg } from "../_Common/IconSvg";
import InlinedElement from "../_Common/InlinedElement";
import { userActions, ordersActions, pickupsActions, alertActions, commonActions, productActions } from "../../../actions";
import TableLoader from "../_Common/TableLoader";
import Loader from "../../_Common/Loader";
import DatePicker from "../../_Common/DatePicker";
import TabShow from "../_Common/TabShow";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import SmallText from "../_Common/SmallText";
import { EmptyScreen } from "../_Common/EmptyScreen";
import DropedList from "../_Common/DropedList";
import PaginationBar from "../_Common/PaginationBar";
import { FeatureLoked } from "../_Common/FeatureLoked";
import PropTypes from "prop-types";
import { addUrlProps, UrlQueryParamTypes, Serialize } from "react-url-query";
import { initGAPg, Event } from "../_Common";
import { OLD_PREMIUM_FILTER_STATUS_LIST, PREMIUM_FILTER_STATUS_LIST, STARTER_FILTER_STATUS_LIST } from "../../constants";
import PickupQuickForm from "../_Custom/PickupQuickForm";
import ToggleSwitch from "../../_Common/ToggleSwitch";
import OrdersFiltersToolBar from "../_Custom/toolbars/OrdersFiltersToolBar";
import { is_exclusive_test_store } from "../../helpers/functions";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import SearchInputType from "../_Common/SearchInputType";
import { Link } from "react-router-dom";
const DotSeparatedQueryParamType = {
  encode: (val) => Serialize.encodeArray(val, ","),
  decode: (val) => Serialize.decodeArray(val, ","),
};
const urlPropsQueryConfig = {
  status: { type: DotSeparatedQueryParamType, queryParam: "status" },
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
  page: { type: UrlQueryParamTypes.number },
  wilaya: { type: DotSeparatedQueryParamType, queryParam: "wilaya" },
  commune: { type: DotSeparatedQueryParamType, queryParam: "commune" },
  products: { type: DotSeparatedQueryParamType, queryParam: "products" },
  display_id: { type: UrlQueryParamTypes.string },
};

class OrdersPage extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      orders: this.props.OrderGeting === "2" ? this.props.OrdersList.ressuc.list.results : [],
      nextpage: this.props.OrderGeting === "2" ? this.props.OrdersList.ressuc.list.next : null,
      prevpage: this.props.OrderGeting === "2" ? this.props.OrdersList.ressuc.list.previous : null,
      count: this.props.OrderGeting === "2" ? this.props.OrdersList.ressuc.list.count : 0,
      // statusList:this.props.dtStoreinfo.stock_managed ? PREMIUM_FILTER_STATUS_LIST:STARTER_FILTER_STATUS_LIST,
      statusList: this.props.dtStoreinfo.stock_managed ? (is_exclusive_test_store(this.props.dtStoreinfo) ? PREMIUM_FILTER_STATUS_LIST : OLD_PREMIUM_FILTER_STATUS_LIST) : STARTER_FILTER_STATUS_LIST,
      // statusList:['4','5','6','7','8','9','11','31','41','42','22','50'],
      alertdelet: null,
      alertpickup: false,
      showPickupForm: false,
      keepDefaultPickupSettings: true,
      permission: this.props.dataProfile.is_store || this.props.dataProfile.view_order,
      premisionedit: this.props.dataProfile.is_store || this.props.dataProfile.change_order,
      allSelect: null,
    };
    this.crntfilter = this.props.OrderGeting === "2" ? this.props.OrdersList.filter : "";
    this.displayid = null;
    this.selectIdlist = [];
    this.submit = false;
    this.refrs = {
      Status_Container: React.createRef(),
      wilaya_Container: React.createRef(),
      commune_Container: React.createRef(),
      product_Container: React.createRef(),
    };
    this.evnfunc = {
      Status_Container: (e) => {
        if (this.refrs.Status_Container.current && !this.refrs.Status_Container.current.contains(e.target)) {
          this.ControlFilter("Status_Container");
        }
      },
      wilaya_Container: (e) => {
        if (this.refrs.wilaya_Container.current && !this.refrs.wilaya_Container.current.contains(e.target)) {
          this.ControlFilter("wilaya_Container");
        }
      },
      commune_Container: (e) => {
        if (this.refrs.commune_Container.current && !this.refrs.commune_Container.current.contains(e.target)) {
          this.ControlFilter("commune_Container");
        }
      },
      product_Container: (e) => {
        if (this.refrs.product_Container.current && !this.refrs.product_Container.current.contains(e.target)) {
          this.ControlFilter("product_Container");
        }
      },
    };
    this.vahiculesTypesOptions =
      this.props.VehiculesData && this.props.VehiculesData.list
        ? this.props.VehiculesData.list
            .map((vType) => ({
              text: Translate("vehiculetypes", vType.id + ""),
              val: vType.id,
            }))
            .slice(0, 3)
        : [];
    this.resFilter = false;
    this.CallProps = false;
    this.firstTime = true;
    this.Gotodetail = this.Gotodetail.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.RemoveOrder = this.RemoveOrder.bind(this);
    this.controlExport = this.controlExport.bind(this);
    this.controlMore = this.controlMore.bind(this);
    this.clearDate = this.clearDate.bind(this);
    this.FilterRef = React.createRef();
    this.ExpRef = React.createRef();
    this.handleClickOutsideExpRef = this.handleClickOutsideExpRef.bind(this);
    this.ShowFilter = this.ShowFilter.bind(this);
    this.handleClickOutsideFilter = this.handleClickOutsideFilter.bind(this);
    this.setOptionChnage = this.setOptionChnage.bind(this);
    this.SetDisplayId = this.SetDisplayId.bind(this);
    this.CallPropsS = this.CallPropsS.bind(this);
    this.handleUseSelect = this.handleUseSelect.bind(this);
    this.handleSelectAll = this.handleSelectAll.bind(this);
  }
  static defaultProps = {
    status: [],
    wilaya: [],
    commune: [],
    products: [],
    page: 1,
  };
  static propTypes = {
    status: PropTypes.array,
    began: PropTypes.string,
    end: PropTypes.string,
    page: PropTypes.number,
    display_id: PropTypes.string,
    products: PropTypes.array,
    wilaya: PropTypes.array,
    commune: PropTypes.array,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeStatus: PropTypes.func,
    onChangeWilaya: PropTypes.func,
    onChangeCommune: PropTypes.func,
    onChangeProducts: PropTypes.func,
    onChangeDisplay_id: PropTypes.func,
  };
  componentWillReceiveProps(nextProps) {
    if (this.state.permission) {
      const { OrderGeting, OrdersList, OrderUpdSta, gtStoreInf, dtStoreinfo, GettingTypes, VehiculesData } = nextProps;
      if (this.props.OrderGeting === "1" && OrderGeting === "2" && OrdersList && OrdersList.ressuc && OrdersList.ressuc.list) {
        document.getElementById("Container-root").scrollTo(0, 0);
        this.setState({
          orders: OrdersList.ressuc.list.results,
          nextpage: OrdersList.ressuc.list.next,
          prevpage: OrdersList.ressuc.list.previous,
          count: OrdersList.ressuc.list.count,
          wilaysData: this.state.wilaysData,
        });
      }
      if (this.props.OrderGeting === "1" && OrderGeting === "3") {
        if (OrdersList && OrdersList.data && OrdersList.data.detail === "Page invalide.") {
          this.props.onChangePage(undefined);
        }
      }
      if (this.props.OrderUpdSta === "0" && OrderUpdSta === "1") {
        this.exctuteFilterUrl(this.getFilterToState(), true);
      }
      if (this.submit && this.props.OrderUpdSta === "0" && OrderUpdSta === "2") {
        this.submit = false;
        this.props.SendAlert("50", Translate("alert", "failupdateorder"), "");
      }
      if (nextProps.page !== this.props.page || this.resFilter || this.CallProps) {
        this.resFilter = false;
        this.CallProps = false;
        this.exctuteFilterUrl(this.getFilterToState(nextProps));
      }
      if (!dtStoreinfo.stock_managed && this.props.GettingTypes === "1" && GettingTypes === "2") {
        this.vahiculesTypesOptions =
          VehiculesData && VehiculesData.list
            ? VehiculesData.list
                .map((vType) => ({
                  text: Translate("vehiculetypes", vType.id + ""),
                  val: vType.id,
                }))
                .slice(0, 3)
            : [];
      }
      if (this.props.gtStoreInf === "0" && gtStoreInf === "1") {
        this.setState({
          statusList: dtStoreinfo.stock_managed ? PREMIUM_FILTER_STATUS_LIST : STARTER_FILTER_STATUS_LIST,
        });
      }

      // if(PickupAdded==="2" && this.submit){
      //     this.props.SendAlert("41",Translate('alert','pickupadded'),"")
      //     this.submit=false
      //     this.exctuteFilterUrl(this.getFilterToState(),true)

      // }
      // if(PickupAdded==="3" && this.submit){
      //     if(dataAddPickup && dataAddPickup.response && dataAddPickup.response.data){
      //         Translate("error",dataAddPickup.response.data.detail)
      //     }
      //     this.submit=false
      //     this.props.SendAlert("50",Translate('alert','pickupfail'),"")
      // }
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "order");
    initGAPg();
    if (this.state.permission) {
      let nextFilter = this.getFilterToState();
      if (this.props.GetingWilaya !== "1") {
        this.props.Getwilayas();
      }
      this.props.Getcommunes("");
      this.exctuteFilterUrl(nextFilter, true);
      if (this.props.ProductFinded !== "2") {
        this.props.GetAllProduct();
      }
    }
  }
  EditOrder(path, pop, isUnique) {
    const { dtStoreinfo } = this.props;
    if (pop) {
      let bonwindow = window.open(path);
      bonwindow.propsended = dtStoreinfo.is_store ? dtStoreinfo : { name: dtStoreinfo.store_name, phone: dtStoreinfo.store_phone };
      if (isUnique) {
        bonwindow.more = [isUnique];
      } else {
        bonwindow.more = this.state.allSelect
          ? this.state.orders.map((elem) => {
              return elem.display_id;
            })
          : this.selectIdlist;
      }
      bonwindow.bonwindow = this.state.orders;
    } else {
      this.props.history.push(path);
    }
  }
  RemoveOrder(id) {
    let idremove = this.state.alertdelet;
    this.submit = true;
    if (idremove) {
      this.props.UpdateStaOrder({ status: 50, abort_reason: 21 }, idremove);
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }
  CreatePickup(confirmed, append = false) {
    const { dtStoreinfo } = this.props;
    let orders = this.state.allSelect ? this.state.orders.filter((elem) => elem.status === 4).map((el) => el.id) : this.state.orders.filter((elem) => elem.status === 4 && this.selectIdlist.indexOf(elem.display_id) !== -1).map((el) => el.id);

    if (!confirmed) {
      Event("ORDERS", "USE_CREATE_PICKUP_BUTTON", "CLICK_EVENT");
      // Fetch pending pickups
      this.props.GetPickups("?status=5");

      if (this.props.GettingTypes !== "2") this.props.GetVehiculeTypes();
      this.setState({
        alertpickup: true,
        nbrPickupsCmd: orders.length,
      });
      return;
    }

    const { pickupInfos } = this.state;

    if (append) {
      // Append requested orders to last requested pickup
      Event("ORDERS_PICKUP", "USE_APPEND_TO_EXISTING_BUTTON", "CLICK_EVENT");
      let lastPickup = this.props.PickupsList?.ressuc?.results[0];
      if (lastPickup) {
        const data = {
          store: dtStoreinfo.id,
        };

        if (!this.state.allSelect) data.orders = orders.concat(lastPickup.orders.map((order) => order.id));
        if (orders.length) {
          this.props.UpdatePickup(data, lastPickup.id);
          this.submit = true;
        }
      }
    } else {
      // Create a new pickup request
      Event("ORDERS_PICKUP", "USE_CREATE_NEW_PICKUP_BUTTON", "CLICK_EVENT");
      const data = !pickupInfos
        ? {
            store: dtStoreinfo.id,
            commune: dtStoreinfo.commune,
            pickup_address: dtStoreinfo.address,
          }
        : {
            store: dtStoreinfo.id,
            commune: dtStoreinfo.commune,
            ...pickupInfos,
          };

      if (!this.state.allSelect) data.orders = orders;
      if (orders.length) {
        this.props.AddPickup(data);
        this.submit = true;
      }
    }
    this.setState({
      alertpickup: false,
      nbrPickupsCmd: null,
    });
  }

  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  Gotodetail(id) {
    this.props.history.push("/order/detail/" + id + "?source=order");
  }
  getFilterToState(props) {
    let Filter = props ? props.location.search : this.props.location.search;
    return Filter;
  }
  exctuteFilterUrl(nextFilter, force) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || force) {
      this.crntfilter = nextFilter;
      this.props.GetOrders(nextFilter);
    }
  }
  ChangeDisId(e) {
    const { value } = e.target;
    this.displayid = value;
  }
  ShowFilter() {
    Event("ORDERS", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", this.handleClickOutsideFilter);
    } else {
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideFilter);
    }
  }
  handleClickOutsideFilter(event) {
    if (this.FilterRef && this.FilterRef.current && !this.FilterRef.current.contains(event.target)) {
      this.ShowFilter();
    }
  }
  SetDisplayId(e) {
    e.preventDefault();
    Event("ORDERS", "FILTER_BY_ID", "CLICK_EVENT");
    this.CallProps = true;
    if (this.props.display_id) {
      this.props.onChangeDisplay_id(undefined);
    } else {
      this.props.onChangeDisplay_id(this.displayid);
    }
  }
  ControlFilter(idTraget) {
    let clsList = document.getElementById(idTraget);
    if (clsList.classList.value.indexOf("hidElem3") !== -1) {
      clsList.classList.remove("hidElem3");
      clsList.style.maxHeight = "400px";
      document.addEventListener("mousedown", this.evnfunc[idTraget]);
    } else {
      clsList.style.maxHeight = "0px";
      clsList.classList.add("hidElem3");
      document.removeEventListener("mousedown", this.evnfunc[idTraget]);
    }
  }
  /***  manage query */
  manageQueryStatus(statu) {
    const { status, onChangeStatus } = this.props;
    let TmpTab = status;
    let TmpTab2 = [...status, statu];
    if (TmpTab.indexOf(statu) !== -1) {
      TmpTab2 = TmpTab2.filter((elem) => elem !== statu);
    }
    this.CallProps = true;
    onChangeStatus(TmpTab2);
  }
  setOptionChnage(name, value) {
    if (name === "wilaya") {
      const { wilaya, onChangeWilaya } = this.props;
      let TmpTab = wilaya;
      let TmpTab2 = [...wilaya, value];
      if (TmpTab.indexOf(value) !== -1) {
        TmpTab2 = TmpTab2.filter((elem) => elem !== value);
      }
      this.CallProps = true;
      onChangeWilaya(TmpTab2);
    }
    if (name === "commune") {
      const { commune, onChangeCommune } = this.props;
      let TmpTab = commune;
      let TmpTab2 = [...commune, value];
      if (TmpTab.indexOf(value) !== -1) {
        TmpTab2 = TmpTab2.filter((elem) => elem !== value);
      }
      this.CallProps = true;
      onChangeCommune(TmpTab2);
    }
    if (name === "products") {
      const { products, onChangeProducts } = this.props;
      let TmpTab = products;
      let TmpTab2 = [...products, value];
      if (TmpTab.indexOf(value) !== -1) {
        TmpTab2 = TmpTab2.filter((elem) => elem !== value);
      }
      this.CallProps = true;
      onChangeProducts(TmpTab2);
    }
  }
  /****** end query */
  RefreshOredr() {
    Event("ORDERS", "USE_REFRESH_ORDERS_BUTTON", "CLICK_EVENT");
    let clsList = document.getElementById("List_Export").classList;
    if (clsList.value.indexOf("hidElem2") === -1) {
      this.controlExport();
    }
    let clsList2 = document.getElementById("List-more-Ac").classList;
    if (clsList2.value.indexOf("hidElem2") === -1) {
      this.controlMore();
    }
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }
  clearDate() {
    this.props.onChangeBegan(undefined);
    this.props.onChangeEnd(undefined);
    this.CallProps = true;
  }
  CallPropsS() {
    this.CallProps = true;
  }
  resetFilter() {
    const { onChangeStatus, onChangeWilaya, onChangeCommune, onChangeProducts, onChangeDisplay_id } = this.props;
    Event("ORDERS", "RESET_ALL_FILTERS", "CLICK_EVENT");
    this.clearDate();
    onChangeDisplay_id(undefined);
    onChangeStatus([]);
    onChangeWilaya([]);
    onChangeCommune([]);
    onChangeProducts([]);
    this.resFilter = true;
  }
  controlExport() {
    Event("ORDERS", "DROP_EXPORT_LIST", "CLICK_EVENT");
    let clsList = document.getElementById("List_Export");
    let clsList2 = document.getElementById("List-more-Ac").classList;
    if (clsList2.value.indexOf("hidElem2") === -1) {
      this.controlMore();
    }
    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideExpRef);
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "165px";
      document.addEventListener("mousedown", this.handleClickOutsideExpRef);
    }
  }
  controlMore() {
    Event("ORDERS", "USE_IMPORT_BUTTON", "CLICK_EVENT");
    let clsList = document.getElementById("List-more-Ac");
    let clsList2 = document.getElementById("List_Export").classList;
    if (clsList2.value.indexOf("hidElem2") === -1) {
      this.controlExport();
    }
    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "55px";
    }
  }
  handleClickOutsideExpRef(event) {
    if (this.ExpRef && !this.ExpRef.current.contains(event.target)) {
      this.controlExport();
    }
  }
  CallExportOrder(fileEx) {
    if (this.props.Exporting !== "0") {
      Event("ORDERS", "EXPORT_ORDERS", `EXORT_AS_${fileEx.toUpperCase()}`);
      this.props.SendAlert("31", Translate("alert", "exportreqstsent"), "a04");
      this.props.ExportOrder(this.props.location.search.replaceAll(".", ",").replace("prduord", "products"), fileEx);
    }
  }
  handleUseSelect(e) {
    const { name } = e.target;
    let cuurent = this.selectIdlist;
    let idOrder = name;
    let index = cuurent.indexOf(idOrder);
    if (index === -1) {
      cuurent.push(idOrder);
    } else {
      cuurent.splice(index, 1);
    }
    this.selectIdlist = cuurent;
    if (this.state.allSelect) {
      this.setState({
        allSelect: null,
      });
    } else {
      this.setState({ state: this.state });
    }
  }
  handleSelectAll(e) {
    Event("ORDERS", "USE_SELECTALL_CHECKBOX", `${this.state.allSelect ? "UNCHECK" : "CHECK"}`);
    this.setState({
      allSelect: this.state.allSelect ? null : true,
    });
  }
  togglePickupsSettings() {
    const { keepDefaultPickupSettings } = this.state;
    Event("ORDERS_PICKUP", "USE_TOGGLE_DEFAULT_SETTINGS_BUTTON", keepDefaultPickupSettings ? "TOGGLE_TO_CUSTOM" : "TOGGLE_TO_DEFAULT");
    this.setState({ keepDefaultPickupSettings: !keepDefaultPickupSettings });
  }
  handleChangePickupInfos(name, value) {
    const { pickupInfos } = this.state;
    this.setState({
      pickupInfos: pickupInfos ? { ...pickupInfos, [name]: value } : { [name]: value },
    });
  }
  render() {
    const { PickupGetting, PickupsList, PickupAdded, dataAddPickup, PickupUpdating, dtStoreinfo, GettingTypes } = this.props;
    if ((PickupAdded === "2" || PickupUpdating === "2") && this.submit) {
      this.props.SendAlert("41", Translate("alert", "pickupadded"), "");
      this.submit = false;
      this.exctuteFilterUrl(this.getFilterToState(), true);
    }
    let Alertfail = "";
    if ((PickupAdded === "3" || PickupUpdating === "3") && this.submit) {
      if (dataAddPickup && dataAddPickup.response && dataAddPickup.response.data) {
        Alertfail = Translate("error", dataAddPickup.response.data.detail);
      }
      this.submit = false;
      this.props.SendAlert("50", Translate("alert", "pickupfail"), "");
    }
    if (this.state.permission) {
      const { OrderGeting, OrderUpdSta, dataWilaya, DataCommune, data, page } = this.props;
      var bodytab = [];
      if (OrderGeting === "2") {
        bodytab = this.state.orders.map((elem, index) => {
          const dup = {
            ...elem,
          };
          const { id, display_id, product_name, customer_name, ordered_at, product_price, price, status, wilaya, commune_name, products, is_exchange, is_refund, exchanged_for_order, refunded_for_order } = dup;

          const EDIT_ACTION = {
            type: "BDgInpc",
            action: () => this.EditOrder("/order/add/" + display_id + "?source=order"),
            svgbtn: EditSvg,
            tooltip: Translate("orders", "editorder"),
          };
          const DELETE_ACTION = {
            type: "BDgInpc",
            action: () => this.RemoveOrder(id),
            svgbtn: DeletSvg,
            tooltip: Translate("profile", "cancel"),
          };
          const PRINT_ACTION = {
            type: "BDgInpc",
            action: () => this.EditOrder("/print/facture/boncm", true, display_id),
            svgbtn: PrintSvg,
            tooltip: Translate("tooltips", "printorderslip"),
          };

          let checked = this.selectIdlist.indexOf(display_id) !== -1 || this.state.allSelect;
          let ordered_at2 = <div style={{ minWidth: "95px" }}>{FormatDate(ordered_at)}</div>;
          let btns = "";
          let act_btns = [];
          if (this.state.premisionedit) {
            if (status < 31) act_btns.push(EDIT_ACTION);
            if ([4, 5, 8, 9, 11, 12, 15, 22].indexOf(status) > -1) act_btns.push(DELETE_ACTION);
            if (dtStoreinfo.stock_managed && status === 50)
              act_btns.push({
                type: "BDgInpc",
                action: () => this.EditOrder("/order/add/" + display_id + "?source=order"),
                svgbtn: AddSvg,
              });

            if (status === 41) {
              let acts = [];
              if (dtStoreinfo && dtStoreinfo.stock_managed) {
                if (!refunded_for_order && !is_refund)
                  acts.push({
                    type: "BDgInpc",
                    action: () => this.props.history.push("/order/reimbursement/" + display_id),
                    svgbtn: CachbackSvg,
                    tooltip: Translate("orders", "makerefund"),
                  });

                if (!exchanged_for_order && !is_refund)
                  acts.push({
                    type: "BDgInpc",
                    action: () => this.props.history.push("/order/exchange/" + display_id),
                    svgbtn: ExchangeSvg,
                    tooltip: Translate("orders", "makeexchange"),
                  });
                // btns=(<GroupeBtn data_btn={[{type:"BDgInpc",action:()=>this.props.history.push("/order/reimbursement/"+display_id),svgbtn:CachbackSvg,title: Translate("orders","makerefund")}, {type:"BDgInpc",action:()=>this.props.history.push("/order/exchange/"+display_id),svgbtn:ExchangeSvg,title: Translate("orders","makeexchange")}]}/>)
                act_btns = acts;
              }
            } else {
              if (!dtStoreinfo.stock_managed) act_btns.push(PRINT_ACTION);
            }
            btns = <GroupeBtn data_btn={act_btns} />;
          }
          let productsf = "";
          let blockindex = [0, 6, 8];
          if (Array.isArray(products) && products.length > 0) {
            if (products.length > 1) {
              let PrdctList = (
                <div className="InFlx Stclmnf" onClick={() => this.Gotodetail(display_id)}>
                  {products.map((elem, key) => {
                    return (
                      <div key={key} className="Inlflx TxTrnf Mrtpvl">
                        <strong className="StPaddingVal">{" (" + elem.quantity + ") "}</strong>
                        <SmallText>{elem.logistical_description}</SmallText>
                      </div>
                    );
                  })}
                </div>
              );
              productsf = (
                <DropedList
                  props={{ ...this.props }}
                  title={
                    <div className="InFlx AlgnItm RlPs">
                      <span>{products.length}</span>
                      <span className="StPaddingVal">{Translate("titles", "product")}</span>
                      <span className="DsPlCnt">{FlechDwnSvg}</span>
                    </div>
                  }
                  dataDrop={PrdctList}
                />
              );
              blockindex.push(2);
            } else {
              productsf = (
                <div className="Inlflx TxTrnf">
                  <strong className="StPaddingVal">{" (" + products[0].quantity + ") "}</strong>
                  <SmallText>{products[0].logistical_description}</SmallText>
                </div>
              );
            }
          } else {
            productsf = product_name;
          }
          let displayIdDiv = (
            <div className="Inlflx Stclmnf AlgnItm JstfCnt">
              {elem.is_exchange === true && <div title={Translate("orders", "isanexchange")}>{SubstitutionSvg}</div>}
              {elem.exchanged_for_order && (
                <div className="disabledSvg" title={Translate("orders", "isexchanged")}>
                  {SubstitutionSvg}
                </div>
              )}
              {elem.is_refund && (
                <div className="" title={Translate("orders", "isrefun")}>
                  {MoneySvg}
                </div>
              )}
              {elem.refunded_for_order && (
                <div className="StNoFil disabledSvg " title={Translate("orders", "isrefunded")}>
                  {MoneySvg}
                </div>
              )}
              <div>{display_id}</div>
            </div>
          );
          return [
            <CheckBoxForm name={display_id} rmMarg={true} workfun={this.handleUseSelect} check={checked} id={"order_print_" + display_id} />,
            displayIdDiv,
            <div onClick={() => Event("ORDERS", "CLICK_IN_STATUS_ORDER_LIST_" + status, "CLICK_EVENT")}>
              <StatusElement>{this.props.dtStoreinfo.stock_managed && status === 8 ? "p" + status : status}</StatusElement>
            </div>,
            productsf,
            <div>{this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(is_exchange || is_refund ? product_price : product_price + price) : is_exchange || is_refund ? product_price : product_price + price + "  " + "TND"} </div>,
            customer_name,
            commune_name + " ( " + wilaya + " ) ",
            ordered_at2,
            btns,
            { type: "forindex", blocked: blockindex, idaction: display_id },
          ];
        });
      }
      return (
        <ContainerPage
          page_title={Translate("titles", "order")}
          data_top={
            <div>
              <div className="d-large">
                <DatePicker forceUp={this.CallPropsS} from={this.props.began} to={this.props.end} changeFrom={this.props.onChangeBegan} changeTo={this.props.onChangeEnd} chnageDate={this.props.onChangeDate} />
              </div>
            </div>
          }
        >
          {(PickupAdded === "1" || PickupUpdating === "1") && <Loader />}
          {OrderGeting === "2" && this.state.count === 0 && !(this.props.location.search && (this.props.location.search.replace("?page=" + this.props.page, "") || this.props.location.search.replace("&page=" + this.props.page, "")) !== "") ? (
            <EmptyScreen
              titleGlb={Translate("orders", "noorder")}
              TextShow={Translate("orders", "youhavntcreat")}
              actionBtns={[
                {
                  text: Translate("orders", "addneworder"),
                  to: "/order/add/new",
                },
                {
                  text: Translate("orders", "importorder"),
                  to: "/order/import",
                  className: "status_41",
                },
              ]}
            />
          ) : (
            <div>
              <div className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc" style={{ height: "48px", padding: "7px 0", top: "-20px" }}>
                <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
                  <div className="FlHg ClSidTh StBrdRdS StMrtg2" ref={this.FilterRef}>
                    <div className="CrsPoi FlHg" onClick={this.ShowFilter}>
                      <InlinedElement secnd={<div className="IcnSiz">{FilterSvg}</div>} />
                    </div>
                    <div id="Filter_container" className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2" style={{ width: "350px", maxWidth: "200px" }}>
                      <div id="Sub_Container_filter">
                        <div style={{ marginTop: "8px" }}>
                          <div className="RlPs">
                            <InlinedElement
                              secnd={<strong>{Translate("orders", "filter")}</strong>}
                              leftElm={
                                <div onClick={this.ShowFilter} className="InFlx CrsPoi IcnSizSm">
                                  {CloseSvg}
                                </div>
                              }
                              style={{ width: "100%", padding: "0px" }}
                            />
                          </div>
                          {this.props.location.search && (this.props.location.search.replace("?page=" + this.props.page, "") !== "" || this.props.location.search.replace("&page=" + this.props.page, "")) !== "" ? (
                            <span className="CrsPoi StBle DsBlc StdirLn" onClick={this.resetFilter}>
                              {Translate("orders", "resetfilter")}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                        <div ref={this.refrs.Status_Container} className="BdgClN StBrdRdS StPdsml SmMarS1">
                          <div className="StHeivl RlPs CrsPoi" onClick={() => this.ControlFilter("Status_Container")}>
                            <InlinedElement secnd={<strong>{Translate("orders", "orderstatus")}</strong>} leftElm={<div className="InFlx">{FlechDwnSvg}</div>} style={{ width: "100%", padding: "0px" }} />
                          </div>
                          <div id="Status_Container" className="hidElem3 stTranEs" style={{ overflowY: "scroll" }}>
                            <div>
                              {this.state.statusList.map((elem, key) => {
                                let el = elem.replace("p", "");
                                return <CheckBoxForm key={key} id={"S" + elem} name={"S" + elem} text={Translate("statusorder", elem)} workfun={() => this.manageQueryStatus(el)} check={Array.isArray(this.props.status) && this.props.status.indexOf(el) !== -1} />;
                              })}
                            </div>
                          </div>
                        </div>
                        <div ref={this.refrs.product_Container} className="BdgClN StBrdRdS StPdsml SmMarS1">
                          <div className="StHeivl RlPs CrsPoi" onClick={() => this.ControlFilter("product_Container")}>
                            <InlinedElement secnd={<strong>{Translate("product", "productname")}</strong>} leftElm={<div className="InFlx">{FlechDwnSvg}</div>} style={{ width: "100%", padding: "0px" }} />
                          </div>
                          <div id="product_Container" className="hidElem3 stTranEs">
                            <div>
                              <SearchInputType nameOption="products" NameAffect="display_id" NameShow="logistical_description" CheckedValues={this.props.products} options={data ? data.list : []} placeholder={Translate("product", "productname")} onChangeSearch={this.setOptionChnage} />
                            </div>
                          </div>
                        </div>
                        <div ref={this.refrs.wilaya_Container} className="BdgClN StBrdRdS StPdsml SmMarS1">
                          <div className="StHeivl RlPs CrsPoi" onClick={() => this.ControlFilter("wilaya_Container")}>
                            <InlinedElement secnd={<strong>{Translate("product", "wilaya")}</strong>} leftElm={<div className="InFlx">{FlechDwnSvg}</div>} style={{ width: "100%", padding: "0px" }} />
                          </div>
                          <div id="wilaya_Container" className="hidElem3 stTranEs">
                            <div>
                              <SearchInputType nameOption="wilaya" NameShow={1} NameAffect={0} CheckedValues={this.props.wilaya} options={dataWilaya} placeholder={Translate("product", "wilaya")} onChangeSearch={this.setOptionChnage} />
                            </div>
                          </div>
                        </div>
                        <div ref={this.refrs.commune_Container} className="BdgClN StBrdRdS StPdsml SmMarS1">
                          <div className="StHeivl RlPs CrsPoi" onClick={() => this.ControlFilter("commune_Container")}>
                            <InlinedElement secnd={<strong>{Translate("orders", "district")}</strong>} leftElm={<div className="InFlx">{FlechDwnSvg}</div>} style={{ width: "100%", padding: "0px" }} />
                          </div>
                          <div id="commune_Container" className="hidElem3 stTranEs">
                            <div>
                              <SearchInputType nameOption="commune" NameShow="name" NameAffect="id" CheckedValues={this.props.commune} options={DataCommune} placeholder={Translate("orders", "district")} onChangeSearch={this.setOptionChnage} />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <form onSubmit={this.SetDisplayId}>
                    <div className="FlHg StBrdRdS ClSidTh">
                      <InlinedElement
                        style={{ padding: "0" }}
                        secnd={
                          <div>
                            <input type="text" autoComplete="off" onChange={this.ChangeDisId.bind(this)} name="filterbyid" style={{ width: "300px", maxWidth: "30vw" }} className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl" value={this.props.display_id} placeholder={Translate("orders", "filterbyid")} />
                          </div>
                        }
                        leftElm={
                          <button className="BdgGc DlBrd CrsPoi Inlflx StBgbrd" style={{ padding: "10px" }}>
                            {this.props.display_id ? OutSvg : CheckSvg}
                          </button>
                        }
                      />
                    </div>
                  </form>
                </div>
                <div className="" ref={this.ExpRef}>
                  <div className="" style={{ height: "48px", marginLeft: "20px" }}>
                    <GroupeBtnSp
                      style={{ margin: "0", zoom: "1.2" }}
                      data_btn={[
                        {
                          type: "BDgInpc",
                          action: this.RefreshOredr.bind(this),
                          svgbtn: RefreshSvg,
                          tooltip: Translate("tooltips", "refresh"),
                        },
                        this.selectIdlist.length > 0 || this.state.allSelect
                          ? {
                              type: "BDgInpc",
                              action: () => this.EditOrder("/print/facture/boncm", true),
                              svgbtn: PrintSvg,
                              tooltip: Translate("tooltips", "printorderslip"),
                            }
                          : {},
                        (this.selectIdlist.length > 0 || this.state.allSelect) && !dtStoreinfo.stock_managed
                          ? {
                              type: "BDgInpc",
                              action: () => this.CreatePickup(),
                              svgbtn: PickupSvg,
                              title: Translate("pickups", "addpickup"),
                              tooltip: Translate("tooltips", "createpickup"),
                            }
                          : {},
                        {
                          type: "BDgInpc",
                          action: this.controlExport,
                          svgbtn: PlayListAddSvg,
                        },
                        {
                          type: "BDgInpc",
                          action: this.controlMore,
                          svgbtn: MoreHSvg,
                        },
                      ]}
                    />
                  </div>
                  {/* Export orders */}
                  <div className="RlPs InFlx StDrc StFlxStr" style={{ zIndex: "2" }}>
                    <div id="List_Export" className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1" style={{ height: "0px" }}>
                      <div className="CrsPoi" style={{ height: "55px" }} onClick={() => this.CallExportOrder("csv")}>
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {FileDownSvg}
                            </div>
                          }
                          secnd={<div>{Translate("orders", "exportordcsv")}</div>}
                        />
                      </div>
                      <div className="CrsPoi" style={{ height: "55px" }} onClick={() => this.CallExportOrder("xlsx")}>
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {ExcelSvg}
                            </div>
                          }
                          secnd={<div>{Translate("orders", "exportordxsl2007")}</div>}
                        />
                      </div>
                      <div className="CrsPoi" style={{ height: "55px" }} onClick={() => this.CallExportOrder("xls")}>
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {ExcelSvg}
                            </div>
                          }
                          secnd={<div>{Translate("orders", "exportordxsl97")}</div>}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="RlPs InFlx StDrc StFlxStr" style={{ zIndex: "2" }}>
                    <div id="List-more-Ac" className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1" style={{ height: "0px" }}>
                      <Link to="/order/import" className="CrsPoi" style={{ height: "55px" }}>
                        <InlinedElement
                          style={{ flexDirection: "row" }}
                          first={
                            <div className="IcnSiz" style={{ zoom: "1.2" }}>
                              {ExcelSvg}
                            </div>
                          }
                          secnd={<div>{Translate("orders", "importorder")}</div>}
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-sm">
                <DatePicker forceUp={this.CallPropsS} from={this.props.began} to={this.props.end} changeFrom={this.props.onChangeBegan} changeTo={this.props.onChangeEnd} chnageDate={this.props.onChangeDate} />
              </div>
              {/* <OrdersFiltersToolBar 
                        binder={this}
                        dtStoreinfo={dtStoreinfo}
                        refrs={this.refrs}
                        /> */}

              {/* Selected filters display section */}
              <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
                {this.props.status.length > 0 && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">{Translate("orders", "orderstatus")}</div>
                    {this.props.status.map((elem, key) => {
                      let el = elem === "8" ? "p" + elem : elem;
                      return (
                        <div key={key} onClick={() => this.manageQueryStatus(elem)} className="stBdp CrsPoi SthgMaxC">
                          <InlinedElement
                            style={{
                              border: "2px solid #3498DB",
                              padding: "3px 6px",
                              backgroundColor: "#3498DB35",
                              borderRadius: "5px",
                            }}
                            secnd={
                              <div className="IcnSiz" style={{ zoom: ".48" }}>
                                {CloseSvg}
                              </div>
                            }
                            first={<div className="StSmlS StwdMaxC">{Translate("statusorder", el)}</div>}
                          />
                        </div>
                      );
                    })}
                  </Fragment>
                )}
                {this.props.wilaya.length > 0 && Array.isArray(dataWilaya) && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">{Translate("product", "wilaya")}</div>
                    {this.props.wilaya.map((elem, key) => {
                      let wilayaGet = dataWilaya.find((wilaya) => wilaya[0].toString() === elem.toString());
                      if (wilayaGet) {
                        return (
                          <div key={key} className="stBdp CrsPoi SthgMaxC" onClick={() => this.setOptionChnage("wilaya", elem)}>
                            <InlinedElement
                              style={{
                                border: "2px solid #3498DB",
                                padding: "3px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                              }}
                              secnd={
                                <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                                  {CloseSvg}
                                </div>
                              }
                              first={<div className="StSmlS StwdMaxC">{wilayaGet[1]}</div>}
                            />
                          </div>
                        );
                      }
                    })}
                  </Fragment>
                )}

                {this.props.commune.length > 0 && Array.isArray(DataCommune) && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">{Translate("orders", "district")}</div>
                    {this.props.commune.map((elem, key) => {
                      let communeGet = DataCommune.find((commune) => commune.id.toString() === elem.toString());
                      if (communeGet) {
                        return (
                          <div key={key} className="stBdp CrsPoi SthgMaxC" onClick={() => this.setOptionChnage("commune", elem)}>
                            <InlinedElement
                              style={{
                                border: "2px solid #3498DB",
                                padding: "3px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                              }}
                              secnd={
                                <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                                  {CloseSvg}
                                </div>
                              }
                              first={<div className="StSmlS StwdMaxC">{communeGet.name}</div>}
                            />
                          </div>
                        );
                      }
                    })}
                  </Fragment>
                )}
                {this.props.products.length > 0 && data && Array.isArray(data.list) && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">{Translate("titles", "product")}</div>
                    {this.props.products.map((elem, key) => {
                      let productGeted = data.list.find((product) => product.display_id === elem);
                      if (productGeted) {
                        return (
                          <div key={key} className="stBdp CrsPoi SthgMaxC" onClick={() => this.setOptionChnage("products", elem)}>
                            <InlinedElement
                              style={{
                                border: "2px solid #3498DB",
                                padding: "3px 6px",
                                backgroundColor: "#3498DB35",
                                borderRadius: "5px",
                              }}
                              secnd={
                                <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                                  {CloseSvg}
                                </div>
                              }
                              first={<div className="StSmlS StWhSnwr SetElptx HdOvrfl sTmxWdtvl">{productGeted.logistical_description}</div>}
                            />
                          </div>
                        );
                      }
                    })}
                  </Fragment>
                )}
                {this.props.began && this.props.end && (
                  <Fragment>
                    <div className="stBdp StSmlS StwdMaxC">{Translate("datepicker", "daterange")}</div>
                    <div className="stBdp CrsPoi SthgMaxC" onClick={() => this.clearDate()}>
                      <InlinedElement
                        style={{
                          border: "2px solid #3498DB",
                          padding: "3px 6px",
                          backgroundColor: "#3498DB35",
                          borderRadius: "5px",
                        }}
                        secnd={
                          <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                            {CloseSvg}
                          </div>
                        }
                        first={<div className="StSmlS StwdMaxC">{FormatDate(this.props.began, true) + " => " + FormatDate(this.props.end, true)}</div>}
                      />
                    </div>
                  </Fragment>
                )}
              </div>
              {OrderUpdSta === "0" && <Loader />}
              {OrderGeting === "1" ? (
                <TableLoader />
              ) : OrderGeting === "2" && this.state.count === 0 && this.props.location.search && (this.props.location.search.replace("?page=" + this.props.page, "") || this.props.location.search.replace("&page=" + this.props.page, "")) !== "" ? (
                <div className="RlPs">
                  <EmptyScreen titleGlb={Translate("orders", "noorder")} TextShow={Translate("orders", "filterempty")} TxtBtn={Translate("orders", "resetfilter")} FuncCall={this.resetFilter} />
                </div>
              ) : (
                <div>
                  <Table thead_elem={[<CheckBoxForm rmMarg={true} name="all_order" labelClass="MrAot" check={this.state.allSelect} workfun={this.handleSelectAll} id="order_all_order_printed" />, Translate("product", "id"), Translate("orders", "status"), Translate("product", "productname"), Translate("orders", "totalprice"), Translate("orders", "customername"), Translate("orders", "district") + " / " + Translate("product", "wilaya"), Translate("orders", "creationdate"), Translate("product", "action")]} tbody_elem={bodytab} stickySecnd={true} goTo={this.Gotodetail} />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span className="d-large">{Translate("orders", "showing") + " " + (page * 20 - 19) + " - " + (page * 20 < this.state.count ? page * 20 : this.state.count) + " " + Translate("orders", "of") + " " + this.state.count}</span>
                      <PaginationBar className="StAutMr" NbPage={Math.trunc(this.state.count / 20) + 1} currentPage={parseInt(page)} ChangePage={this.props.onChangePage} blockNext={this.state.nextpage} blockPrev={this.state.prevpage} />
                    </div>
                  </div>
                </div>
              )}
              {/* Confirm pickup request modal */}
              {this.state.alertpickup && (
                <TabShow tab_title={Translate("pickups", "confirmation")} style={{ width: "max-content", maxWidth: "90vw" }} NotCls={false}>
                  <div className="MrAot">
                    <div className="InFlx flxDrc">
                      <p className="FlWd DltTpMar TxtCn">{Translate("pickups", "aresure")}</p>
                    </div>
                    <p className="FlWd DltTpMar TxtCn">
                      {this.state.nbrPickupsCmd} {Translate("titles", "order")} {Translate("titles", "requested")}{" "}
                    </p>
                    <div className="InFlx flxDrc">
                      {Translate("pickups", "usedefaultparams")}
                      <ToggleSwitch isOn={this.state.keepDefaultPickupSettings} toggleHandler={() => this.togglePickupsSettings()} />
                    </div>
                    {this.state.nbrPickupsCmd > 0 && PickupGetting === "2" && PickupsList?.ressuc?.results?.length > 0 && <p className="FlWd Bgstatus_11 TxtCn">{Translate("pickups", "alreadypending")}</p>}
                    {!this.state.keepDefaultPickupSettings && (
                      <div>
                        <PickupQuickForm
                          // btnTitle={Translate("orders","save")}
                          GettingTypes={GettingTypes}
                          typesOptions={this.vahiculesTypesOptions}
                          providerphonenumber={dtStoreinfo.phone}
                          pickup_address={dtStoreinfo.address}
                          onChange={(name, value) => this.handleChangePickupInfos(name, value)}
                          onSuccessFill={(pickup) => this.setState({ pickupInfos: pickup })}
                        />
                      </div>
                    )}
                    <div className="InFlx responsFlx flxDrc StMrtp MrAot StwdMaxC">
                      {this.state.nbrPickupsCmd > 0 && (
                        <Fragment>
                          {PickupGetting === "2" && PickupsList?.ressuc?.results?.length > 0 && (
                            <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.CreatePickup(true, true)}>
                              <BtnLg text={Translate("pickups", "yesappend")} classSet={"status_41"} />
                            </div>
                          )}
                          <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.CreatePickup(true)}>
                            <BtnLg text={PickupGetting === "2" && PickupsList?.ressuc?.results?.length > 0 ? Translate("pickups", "inseparate") : Translate("orders", "yes")} classSet={""} disabled={PickupGetting === "1"} />
                          </div>
                        </Fragment>
                      )}
                      <div
                        className="StwdMaxC CrsPoi StWdDst"
                        style={{ height: "30px" }}
                        onClick={() => {
                          this.showOpt("alertpickup");
                          Event("ORDERS_PICKUP", "USE_CANCEL_BUTTON", "CLICK_EVENT");
                        }}
                      >
                        <BtnLg text={Translate("profile", "cancel")} classSet={"TxDng"} />
                      </div>
                    </div>
                  </div>
                </TabShow>
              )}
              {/* Confirm order delete modal */}
              {this.state.alertdelet && (
                <TabShow tab_title={Translate("profile", "confirmaction")} wdth="550px" NotCls={false}>
                  <div className="MrAot" style={{ width: "94%" }}>
                    <p className="StdirLn FlWd DltTpMar">{Translate("orders", "confrmdelete")}</p>
                    <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                      <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.showOpt("alertdelet")}>
                        <BtnLg text={Translate("profile", "cancel")} classSet={""} />
                      </div>
                      <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={this.RemoveOrder}>
                        <BtnLg text={Translate("orders", "cancelorder")} classSet={"TxDng"} />
                      </div>
                    </div>
                  </div>
                </TabShow>
              )}
            </div>
          )}
        </ContainerPage>
      );
    } else {
      return (
        <ContainerPage page_title={Translate("titles", "order")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
  }
}
function mapState(state) {
  const { OrderGeting, OrdersList, OrderUpdSta, DataUpdSta, Exporting } = state.orders;
  const { PickupGetting, PickupsList, PickupAdded, dataAddPickup, PickupUpdating, DataUpdatePickup } = state.pickups;
  const { GetingWilaya, dataWilaya, GetigCommune, DataCommune, GettingTypes, VehiculesData } = state.common;
  const { ProductFinded, data } = state.product;
  const { gtStoreInf, dtStoreinfo, dataProfile } = state.user;
  return {
    OrderGeting,
    OrdersList,
    OrderUpdSta,
    DataUpdSta,
    Exporting,
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GettingTypes,
    VehiculesData,
    ProductFinded,
    data,
    dataProfile,
    gtStoreInf,
    dtStoreinfo,
    PickupGetting,
    PickupsList,
    PickupAdded,
    dataAddPickup,
    PickupUpdating,
    DataUpdatePickup,
  };
}

const actionCreators = {
  GetOrders: ordersActions.GetOrders,
  GetPickups: pickupsActions.GetPickups,
  AddPickup: pickupsActions.AddPickup,
  UpdatePickup: pickupsActions.UpdatePickup,
  UpdateStaOrder: ordersActions.UpdateStaOrder,
  ExportOrder: ordersActions.ExportOrder,
  SendAlert: alertActions.SendAlert,
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetVehiculeTypes: commonActions.GetVehiculeTypes,
  GetAllProduct: productActions.GetAllProduct,
  GetStoreInfo: userActions.GetStoreInfo,
  ClearStore: commonActions.ClearStore,
};
const connectedOrdersPage = connect(mapState, actionCreators)(OrdersPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedOrdersPage);
export { QueryProps as OrdersPage };
