import React, { Component } from "react";
import { connect } from "react-redux";
import { productActions, ordersActions, exchangeActions, commonActions, alertActions } from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import ToggleSwitch from "../../_Common/ToggleSwitch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link, Redirect } from "react-router-dom";
import { initGAPg, Event, Select } from "../_Common";
import Cookies from "js-cookie";
import { CloseSvg, ExchangeSvg } from "../_Common/IconSvg";

const lang = Cookies.get("lang");
const excludeProduct = (allProducts, excluded) => {
  return allProducts.filter((el) => el.logistical_description !== excluded.logistical_description);
};
class ExchangePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [
        {
          product_id: "",
          quantity: 0,
          logistical_description: "",
          defective: false,
        },
      ],
      orderproducts: [],
      replaceproducts: [],
      extraproducts: [],
      customername: "",
      customerphonenumber: "",
      adresse: "",
      district: null,
      city: null,
      orderprice: 0,
      notetodriver: "",
      delivryprice: 0,
      totalprice: 0,

      error: {},
      express_delivery: false,
      notfound: false,
      nbproduct: 1,
      productnames: [],
      titlepage: Translate("orders", "echangeorder"),
      titlebtn: Translate("exchanges", "submit"),
      tab_id: null,
      source: this.$_GET("source"),
      permmisionedit: this.props.dataProfile.is_store || this.props.dataProfile.change_order,
      permmisionadd: this.props.dataProfile.is_store || this.props.dataProfile.add_order,
    };
    this.isExternCity = null;
    this.isExternCommune = null;
    this.submit = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.handleReplaceProduct = this.handleReplaceProduct.bind(this);
    // this.AddFieldProduct = this.AddFieldProduct.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const { data, ProductFinded, GetingDelivery, dataDelivery, ExchangeAdded, OrderGeting, OrdersList } = nextProps;
    var dataCommed = null;
    var externTitle, FilterSource;
    const {
      match: { params },
    } = this.props;
    let idorder = params.idorder;
    if (this.props.OrderGeting === "1" && OrderGeting === "2") {
      dataCommed = OrdersList.ressuc.list.results.filter((elem) => elem.display_id === idorder)[0];

      FilterSource = OrdersList.filter;
      externTitle = (
        <div className="StDrc">
          <Link className="StOpcVal" to={"/orders"}>
            {Translate("titles", "order")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <div className="Inlflx">{Translate("orders", "echangeorder")}</div>
          <div className="Inlflx StWdDst">#</div>
          <div className="Inlflx">{dataCommed.display_id}</div>
        </div>
      );
    }

    if (dataCommed) {
      this.isExternCommune = dataCommed.commune;
      this.isExternCity = dataCommed.wilaya;
      this.setState({
        id: dataCommed.id,
        products: dataCommed.products.map((el) => ({ ...el, quantity: 0 })),
        orderproducts: dataCommed.products.map((el) => ({ ...el })),
        replaceproducts: dataCommed.products.map((el) => []),
        customername: dataCommed.customer_name,
        customerphonenumber: dataCommed.customer_phone,
        adresse: dataCommed.destination_text,
        // orderprice: dataCommed.product_price,
        notetodriver: dataCommed.note_to_driver,
        delivryprice: dataCommed.price ? dataCommed.price : 0,
        ordersprice: (dataCommed.price ? dataCommed.price : 0) + dataCommed.product_price,
        express_delivery: dataCommed.express,
        notfound: false,
        status: dataCommed.status,
        nbproduct: dataCommed.products.length,
        titlepage: externTitle,
        //the changes
        display_id: dataCommed.display_id,
        titlebtn: Translate("orders", "save"),
        // filtersource: FilterSource,
      });
    }

    if (ProductFinded === "2") {
      this.setState({ productnames: data.list });
    }
    if (this.props.GetingDelivery === "1" && GetingDelivery === "2") {
      this.setState({
        delivryprice: dataDelivery.delivery_price,
      });
    }
    if (ExchangeAdded === "3" && this.submit) {
      document.getElementById("Container-root").scrollTo(0, 0);
    }
  }
  componentWillUnmount() {
    this.props.ClearStore("CLEAR_COMMON");
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "exchanges");
    initGAPg();
    const {
      match: { params },
    } = this.props;
    let idorder = params.idorder;
    this.props.GetOrders("?display_id=" + idorder, false);
    this.props.GetCountry();
    this.props.GetWilayasStore(this.props.dtStoreinfo?.country?.id);
    //this.props.Getwilayas();
    this.props.GetAllProduct();
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.city && prevState.city !== this.state.city) {
      this.props.GetCommuneStore(this.state.city[0]);
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    if (name === "express_delivery") {
      if (this.state.district && this.state.district.id) {
        this.props.GetDelivery(this.state.district.id, value);
      }
    }
    if (name === "orderprice") {
      let prix = parseInt(value.replace(/\D/g, ""));
      this.setState({
        orderprice: prix ? prix : 0,
        totalprice: prix ? prix : 0,
      });
      return;
    }
    this.setState({ [name]: value });
  }

  handleChangeProduct(e) {
    const { name, value } = e.target;
    let product = this.state.products;
    let tabn = name.split("_");
    let indx = parseInt(tabn[1]);
    product[indx - 1].quantity = Number(value);
    if (value > 0) product[indx - 1].is_exchange = true;
    this.setState({
      products: product,
    });
  }
  handleReplaceProduct(e) {
    const { name, value } = e.target;
    let tabn = name.split("_");
    let indx = parseInt(tabn[1]);
    let extraproduct = tabn[0].startsWith("extra");
    let products = extraproduct ? this.state.extraproducts : this.state.replaceproducts;
    if (tabn[0].endsWith("productname")) {
      let key_elem = this.state.productnames.filter((elem) => elem.logistical_description === value)[0];
      if (key_elem) {
        if (extraproduct) {
          // changing productname for extra products
          products[indx - 1].product_id = key_elem.id;
          products[indx - 1].logistical_description = value;
          this.setState({
            extraproducts: products,
            notfound: false,
          });
        } else {
          products[indx - 1][0].product_id = key_elem.id;
          products[indx - 1][0].logistical_description = value;
          this.setState({
            replaceproducts: products,
            // products: product,
            notfound: false,
          });
        }
      }
      if (!key_elem) {
        if (extraproduct) {
          products[indx - 1].logistical_description = value;
          products[indx - 1].product_id = "";
          this.setState({
            notfound: true,
            extraproducts: products,
          });
        } else {
          products[indx - 1][0].logistical_description = value;
          products[indx - 1][0].product_id = "";
          this.setState({
            notfound: true,
            replaceproducts: products,
          });
        }
      }
    } else {
      if (extraproduct) {
        // changing quantity for extra products
        products[indx - 1].quantity = Number(value);
        this.setState({
          extraproducts: products,
        });
      } else {
        products[indx - 1][0].quantity = Number(value);
        this.setState({
          replaceproducts: products,
        });
      }
    }
  }
  handleSelect(elem, name) {
    if (elem) {
      if (name === "city" && ((this.state.city && this.state.city[0] !== elem[0]) || !this.state.city)) {
        this.setState({
          district: null,
        });
        this.props.Getcommunes("?wilaya=" + elem[0]);
      }
      if (name === "district") {
        this.props.GetDelivery(elem.id, this.state.express_delivery);
      }
    }
    this.setState({
      [name]: elem,
    });
  }
  AddFieldProduct(pos) {
    Event("ADD_EXCHANGE", "USE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    let products = this.state.replaceproducts;
    products[pos].push({ product_id: "", quantity: 0 });
    this.setState({
      replaceproducts: products,
    });
  }
  AddExtraFieldProduct() {
    Event("ADD_EXCHANGE", "USE_ADD_ANOTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    let products = this.state.extraproducts;
    products.push({ product_id: "", quantity: 0 });
    this.setState({
      extraproducts: products,
    });
  }
  RemoveFieldProduct(pos) {
    Event("ADD_EXCHANGE", "USE_REMOVE_EXCHANGE_WITH_OTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    let products = this.state.replaceproducts;
    products[pos].splice(0, 1);
    this.setState({
      replaceproducts: products,
    });
  }
  RemoveExtraFieldProduct(pos) {
    Event("ADD_EXCHANGE", "USE_REMOVE_ADD_OTHER_PRODUCT_BUTTON", "CLICK_EVENT");
    let nb = this.state.extraproducts.length;
    let products = this.state.extraproducts;
    if (nb > 0) {
      products.splice(pos, 1);
      this.setState({
        extraproducts: products,
      });
    }
  }
  setProductDefective(product, index) {
    let products = this.state.products;
    products[index].defective = !products[index].defective;
    Event("ADD_EXCHANGE", "TOGGLE_PRODUCT_DEFECTIVE", `SET_TO_${products[index].defective ? "DEFECTIVE" : "NOT_DEFECTIVE"}`);
    this.setState({
      products: products,
    });
  }
  handleSubmit(e) {
    const { dtStoreinfo } = this.props;
    e.preventDefault();
    Event("ADD_EXCHANGE", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
    let keepGoing = true;
    let order = {},
      error = {};
    if (this.state.city && this.state.city[0]) {
      order.wilaya = this.state.city[0];
    } else {
      error.ercity = Translate("error", "eo1");
      keepGoing = false;
    }
    if (this.state.district && this.state.district.id) {
      order.commune = this.state.district.id;
    } else {
      error.ercommune = Translate("error", "eo2");
      keepGoing = false;
    }
    if (this.state.adresse) {
      order.destination_text = this.state.adresse;
    } else {
      error.eradress = Translate("error", "eo7");
      keepGoing = false;
    }
    if (this.state.customerphonenumber) {
      order.customer_phone = this.state.customerphonenumber;
    } else {
      error.erphone = Translate("error", "eo5");
      keepGoing = false;
    }
    if (this.state.customername) {
      order.customer_name = this.state.customername;
    } else {
      error.ername = Translate("error", "eo4");
      keepGoing = false;
    }

    if (this.state.orderprice >= 0) {
      order.product_price = this.state.orderprice;
    }
    // Check of new product's quentities
    let rempProducts = this.state.replaceproducts;
    this.state.replaceproducts.forEach((el, index) => {
      this.state.replaceproducts[index].forEach((p, pos) => {
        if (p.quantity <= 0) {
          rempProducts[index][pos].error = {
            errquantity: Translate("error", "e13"),
          };
          keepGoing = false;
        } else {
          rempProducts[index][pos].error = {};
        }
      });
    });

    if (keepGoing) {
      this.submit = true;

      if (dtStoreinfo.stock_managed) {
        let replacementProducts = this.state.replaceproducts.filter((el) => el.length > 0).map((el) => ({ ...el[0] }));
        // If a product is replaced with the same one, duplicate it in the request body
        this.state.products.forEach((prod, index) => {
          // If it's not replaced with a different one, add it to replacements
          if (prod.is_exchange && this.state.replaceproducts[index].length === 0) replacementProducts.push({ ...prod, is_exchange: false });
        });
        order.products = this.state.products
          .filter((el) => el.is_exchange)
          .concat(replacementProducts)
          .concat(this.state.extraproducts);
        // return
        let nbrExchanged = this.state.products.filter((el) => el.quantity > 0).length + replacementProducts.length;

        if (nbrExchanged === 0) {
          alert(Translate("error", "e12"));
          return;
        }
      } else {
        order.products = this.state.orderproducts.map((el) => ({
          ...el,
          is_exchange: true,
        }));
      }
      order.old_order = this.state.id;
      order.is_exchange = true;
      order.express = this.state.express_delivery === "true";
      order.note_to_driver = this.state.notetodriver;

      if (this.state.permmisionadd) {
        Event("ADD_EXCHANGE", "SUBMIT_INFOS", "INFOS_VALID");
        this.props.AddExchange(order, !dtStoreinfo.stock_managed);
      }
    } else {
      Event("ADD_EXCHANGE", "ERROR_INFORMATION_ENTER", `[${Object.keys(error).join(",")}]`);
      this.setState({
        error: error,
        replaceproducts: rempProducts,
      });
    }
  }
  render() {
    const { ExchangeAdded, ProductFinded, dataAddExchange, dataWilaya, DataCommune, GetingWilaya, GetigCommune, dtStoreinfo, dataWilayaStore, dataCommuneStore, GetingWilayastore } = this.props;

    if (ExchangeAdded === "2" && this.submit.toString() === "true") {
      this.props.GetOrders("");
      this.submit = false;

      this.props.SendAlert("41", Translate("alert", "exchadded"), "a01");
      return <Redirect to="/orders" />;
    }
    let Alertfail = "";
    if (ExchangeAdded === "3" && this.submit) {
      if (dataAddExchange && dataAddExchange.response && dataAddExchange.response.data) {
        Alertfail = Translate("error", dataAddExchange.response.data.detail);
      }

      this.submit = false;
      this.setState({
        error: {},
      });
      this.props.SendAlert("50", Translate("alert", "exchfail"), "a02");
    }
    if (this.isExternCity && this.props.GetingWilayastore === "1" && Array.isArray(dataWilayaStore)) {
      let wilaya = dataWilayaStore.find((elem) => elem[1] === this.isExternCity);
      this.handleSelect(wilaya, "city");
      this.isExternCity = null;
    }
    if (this.isExternCommune && this.props.GetigCommune === "1" && Array.isArray(dataCommuneStore)) {
      let commune = dataCommuneStore.find((elem) => elem.id === this.isExternCommune);
      this.handleSelect(commune, "district");
      this.isExternCommune = null;
    }
    if (!this.state.permmisionadd && !this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "addneworder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    if (!this.state.permmisionedit && this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "editorder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    return (
      <ContainerPage page_title={this.state.titlepage}>
        {ExchangeAdded === "1" && <Loader />}
        <div className="ClSidTh StBrdTb StBgbrds widthAdd">
          <div className="FlWd TxtCn StRedClr">{Alertfail}</div>
          <form method="post" onSubmit={this.handleSubmit}>
            <ContainerPage subContainer page_title={""} small_title={true}>
              {Array.isArray(this.state.orderproducts) &&
                this.state.orderproducts.map((product, key) => {
                  return (
                    <div key={key}>
                      <div className="FlWd">
                        <div style={{ flexGrow: "1" }} className="InFlx AlgnItm flxDrc">
                          <div style={{ flexGrow: "0.02" }} className="FlHg InFlx AlgnItm flxDrc">
                            <h2 className="StOpcVal"># {key + 1}</h2>
                          </div>
                          {product.picture && <div style={{ flexGrow: "0.18" }} className="FlHg InFlx AlgnItm"></div>}
                          <div style={{ flexGrow: "0.8" }} className="FlHg InFlx flxDrc">
                            <div className={"FlHg FlWd Stclmnf " + lang === "ar" ? "StFlxStr" : ""}>
                              <div className="InFlx flxDrc">
                                <h2 className="DlMg"> {product.logistical_description}</h2>
                              </div>
                              <div className="InFlx flxDrc">
                                <p className=" DlMg StOpcVal">
                                  {Translate("exchanges", "deliveredqty")} : {product.quantity}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {dtStoreinfo.stock_managed && (
                          <div>
                            <div className="FlWd InFlx AlgnItm flxDrc">
                              <div className="Hlwd">
                                <GroupeForm id={"quantity_" + (key + 1)} name={"quantity_" + (key + 1)} placeholder={Translate("exchanges", "exchangequantity")} text={Translate("exchanges", "exchangequantity")} type={"number"} min={0} max={product.quantity} defaultValue={0} workfun={this.handleChangeProduct} />
                              </div>
                              <div className="Hlwd FlHg Mrg2 RlPs">
                                <div className="FlWd FlHg InFlx AlgnItm Stclmnf">
                                  <div className="FlWd InFlx flxDrc SmMarS">
                                    <p className="StSizLn DlMg">{Translate("exchanges", "productstat")}</p>
                                  </div>
                                  <div className="InFlx AlgnItm flxDrc">
                                    <div style={{ flexGrow: "0.2" }} className="FlHg InFlx flxDrc">
                                      <ToggleSwitch toggleHandler={() => this.setProductDefective(product, key)} />
                                    </div>

                                    <div style={{ flexGrow: "0.8" }} className="FlHg InFlx flxDrc">
                                      <div className={"FlHg FlWd Stclmnf " + lang === "ar" ? "StFlxStr" : ""}>
                                        <div className="InFlx flxDrc">
                                          <b> {Translate("exchanges", "productdefective")}</b>
                                        </div>

                                        <p className="DlMg StOpcVal">{Translate("exchanges", "productdefectiveseparat")} </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            {this.state.replaceproducts[key].length > 0 &&
                              this.state.replaceproducts[key].map((el, pos) => (
                                <div className="FlWd InFlx Stclmnf">
                                  <div className="FlWd InFlx flxDrc">
                                    <div className="Hlwd">
                                      <SelectFormSearch id={"productname_" + (key + 1)} name={"productname_" + (key + 1)} index={"logistical_description"} option={excludeProduct(this.state.productnames, product)} text={Translate("product", "productname")} workfun={this.handleReplaceProduct} placeholder={ProductFinded === "2" ? Translate("product", "productname") : Translate("alert", "load")} value={this.state.replaceproducts[key][pos].logistical_description} />
                                    </div>
                                    <div className="Hlwd Mrg2 RlPs">
                                      <div className="Flwd InFlx flxDrc AlgnItm">
                                        <GroupeForm id={"quantity_" + (key + 1)} name={"quantity_" + (key + 1)} placeholder={Translate("orders", "quantity")} text={Translate("orders", "quantity")} type={"number"} value={this.state.replaceproducts[key][pos].quantity} min={0} workfun={this.handleReplaceProduct} error={this.state.replaceproducts[key][pos].error ? this.state.replaceproducts[key][pos].error.errquantity : ""} style={{ width: "80%" }} />
                                        <div
                                          className="CrsPoi InFlx JstfCnt"
                                          style={{
                                            alignItems: "flex-end",
                                            margin: "5% 0% 0% 2%",
                                          }}
                                          onClick={() => this.RemoveFieldProduct(key)}
                                        >
                                          {CloseSvg}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            <div className="FlWd InFlx flxDrc">
                              <span>{this.state.error.erproduct}</span>
                              {this.state.notfound ? (
                                <div className="FlWd StdirLn">
                                  <span className="CrsPoi">{" " + Translate("product", "prdntfond")}</span>
                                  <Link to="/products/add" className="CrsPoi StBle">
                                    {" " + Translate("product", "addnewproduct")}
                                  </Link>
                                </div>
                              ) : (
                                ""
                              )}
                              {this.state.replaceproducts[key].length === 0 && this.state.products[key].quantity > 0 && (
                                <div className="FlWd StdirLn">
                                  <span className="StBle CrsPoi" onClick={() => this.AddFieldProduct(key)}>
                                    <div className={"StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"}>
                                      <p>{" " + Translate("exchanges", "exchangewithanother")}</p> {ExchangeSvg}
                                    </div>
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </ContainerPage>
            <ContainerPage subContainer>
              {this.state.extraproducts.length === 0 && (
                <div className="FlWd StdirLn">
                  <span className="StBle CrsPoi" onClick={() => this.AddExtraFieldProduct()}>
                    <div className={"StMrtg2 StHgVls InFlx AlgnItm StBlFil StSmallSvg"}>
                      <p>{" " + Translate("exchanges", "sellanotherproduct")}</p> {ExchangeSvg}
                    </div>
                  </span>
                </div>
              )}
              <div>
                {this.state.extraproducts.length > 0 && (
                  <div className="FlWd InFlx flxDrc">
                    {" "}
                    <h4>{Translate("exchanges", "newproducts")}</h4>
                  </div>
                )}
                {this.state.extraproducts.length > 0 &&
                  this.state.extraproducts.map((el, idx) => (
                    <div className="FlWd InFlx Stclmnf">
                      <div className="FlWd InFlx flxDrc">
                        <div className="Hlwd">
                          <SelectFormSearch id={"extraproductname_" + (idx + 1)} name={"extraproductname_" + (idx + 1)} index={"logistical_description"} option={this.state.productnames} text={Translate("product", "productname")} workfun={this.handleReplaceProduct} placeholder={ProductFinded === "2" ? Translate("product", "productname") : Translate("alert", "load")} value={this.state.extraproducts[idx].logistical_description} />
                        </div>
                        <div className="Hlwd Mrg2 RlPs">
                          <div className="Flwd InFlx flxDrc AlgnItm">
                            <GroupeForm id={"extraquantity_" + (idx + 1)} name={"extraquantity_" + (idx + 1)} placeholder={Translate("orders", "quantity")} text={Translate("orders", "quantity")} type={"number"} value={this.state.extraproducts[idx].quantity} min={0} workfun={this.handleReplaceProduct} style={{ width: "80%" }} />
                            <div
                              className="CrsPoi InFlx JstfCnt"
                              style={{
                                alignItems: "flex-end",
                                margin: "5% 0% 0% 2%",
                              }}
                              onClick={() => this.RemoveExtraFieldProduct(idx)}
                            >
                              {CloseSvg}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="FlWd InFlx flxDrc">
                        <span>{this.state.error.erproduct}</span>
                        {this.state.notfound ? (
                          <div className="FlWd StdirLn">
                            <span className="CrsPoi">{" " + Translate("product", "prdntfond")}</span>
                            <Link to="/products/add" className="CrsPoi StBle">
                              {" " + Translate("product", "addnewproduct")}
                            </Link>
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </ContainerPage>
            <ContainerPage subContainer page_title={Translate("orders", "clientinfo")} small_title={true}>
              <div className="FlWd InFlx flxDrc">
                <div className="Hlwd">
                  <GroupeForm id={"customername"} name={"customername"} placeholder={Translate("orders", "customername")} text={Translate("orders", "customername")} type={"text"} value={this.state.customername} workfun={this.handleChange} error={this.state.error.ername} />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm id={"customerphonenumber"} name={"customerphonenumber"} placeholder={Translate("orders", "customerphonenumber")} text={Translate("orders", "customerphonenumber")} type={"tel"} pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$" value={this.state.customerphonenumber} workfun={this.handleChange} error={this.state.error.erphone} />
                </div>
              </div>
            </ContainerPage>
            <ContainerPage subContainer withOvrl={true} page_title={Translate("orders", "deliverydetails")} small_title={true}>
              <div className="FlWd InFlx flxDrc" style={{ height: "100%" }}>
                <div className="Hlwd">
                  <GroupeForm id={"adresse"} name={"adresse"} placeholder={Translate("orders", "adresse")} text={Translate("orders", "adresse")} type={"text"} value={this.state.adresse} workfun={this.handleChange} error={this.state.error.eradress} />
                  <div className="FlWd InFlx flxDrc" style={{ margin: "28px 0 28px 0" }}>
                    <div className="Hlwd">
                      <Select label={Translate("orders", "city")} placholder={Translate("orders", "citySearch")} search={true} Options={dataWilayaStore} fieldShow={1} name="city" loading={GetingWilayastore === "0"} value={this.state.city} onChange={this.handleSelect} maxSize="200px" />
                    </div>
                    <div className="Hlwd Mrg2">
                      <Select label={Translate("orders", "district")} placholder={Translate("orders", "districtSearch")} search={true} Options={dataCommuneStore} fieldShow={"name"} name="district" loading={GetigCommune === "0"} value={this.state.district} onChange={this.handleSelect} maxSize="200px" />
                    </div>
                  </div>
                  <GroupeForm id={"orderprice"} name={"orderprice"} placeholder={Translate("exchanges", "clientamount")} text={Translate("exchanges", "clientamount")} type={"text"} value={this.state.orderprice} workfun={this.handleChange} error={this.state.error.erprice} />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm id={"notetodriver"} name={"notetodriver"} placeholder={Translate("orders", "notetodriver")} text={Translate("orders", "notetodriver")} type={"text"} value={this.state.notetodriver} workfun={this.handleChange} textarea={true} />
                  {this.state.city && this.state.city[0] === 16 ? (
                    <div className="SmMarS2">
                      <SelectForm
                        id={"expressdelivery"}
                        name={"express_delivery"}
                        option={[
                          { text: Translate("orders", "no"), val: false },
                          { text: Translate("orders", "yes"), val: true },
                        ]}
                        value={this.state.express_delivery}
                        text={Translate("orders", "expressdelivery")}
                        workfun={this.handleChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </ContainerPage>
            <span
              className="DsBlc FlWd"
              style={{
                height: "1px",
                backgroundColor: "var(--fntClr)",
                opacity: ".2",
              }}
            ></span>
            <div className="FlWd TxtCn StRedClr">{this.state.city && !this.state.city.id && this.state.city.name && Translate("error", "Order out of our service")}</div>
            <div className="StMarMx stbold">
              {/* <div className="PsinLan SmMarS">
                <span>{Translate("orders", "orderprice") + " :"}</span>
                <span className="Mrg2">{this.state.orderprice + "  Da"}</span>
              </div> */}
              <div className="PsinLan SmMarS">
                <span>{Translate("orders", "deliveryprice")}</span>
                <span> ({Translate("exchanges", "extracharge")}) </span>
                <span>:</span>
                <span className="Mrg2"> {lang == "ar" ? this.state.delivryprice + "   " + this.props.dtStoreinfo.country.ar_currency : this.state.delivryprice + "   " + this.props.dtStoreinfo.country.lt_currency}</span>
              </div>
              <div className="PsinLan SmMarS">
                <span>{Translate("exchanges", "clientamount") + " :"}</span>
                <span className="Mrg2">{lang == "ar" ? this.state.totalprice + "   " + this.props.dtStoreinfo.country.ar_currency : this.state.totalprice + "   " + this.props.dtStoreinfo.country.lt_currency}</span>
              </div>
            </div>

            <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
              <Button BtnText={this.state.titlebtn} />
            </div>
          </form>
        </div>
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href.replace(window.location.hash, "").replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
      vars[key] = value !== undefined ? value : "";
    });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const { GetingWilaya, dataWilaya, GetigCommune, DataCommune, GetingDelivery, dataDelivery, dataCountry, GetingPays, GetingWilayastore, dataWilayaStore, GetingCommunestore, dataCommuneStore } = state.common;
  const { ProductFinded, data } = state.product;
  const { OrderGeting, OrdersList } = state.orders;
  const { ExchangeAdded, dataAddExchange } = state.exchanges;

  const { DataSearch, Searching } = state.search;
  const { dtStoreinfo, dataProfile } = state.user;
  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    ExchangeAdded,
    dataAddExchange,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    DataSearch,
    Searching,
    dtStoreinfo,
    dataProfile,
    dataCountry,
    GetingPays,
    GetingWilayastore,
    dataWilayaStore,
    GetingCommunestore,
    dataCommuneStore,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddExchange: exchangeActions.AddExchange,
  GetOrders: ordersActions.GetOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
  GetWilayasStore: commonActions.GetWilayasStore,
  GetCommuneStore: commonActions.GetCommuneStore,
};

const connectedExchangePage = connect(mapState, actionCreators)(ExchangePage);
export { connectedExchangePage as ExchangePage };
