import React from 'react';
import '../_Common/VarCss/RootHeadLog.css'
export default function HeadLog(props){
    return(
        <div className="">
            <div className="">
                <img src="/assets/img/maystro-blue.png" 
                alt="Maystro Delivery logo"
                style={{maxWidth: "65vw"}}
                />
            </div>
            <div>
                <div className="StMar">
                    <h2 className="DlMg">{props.titleHead}</h2>
                </div>
                <div className="StMar">
                    <p className="DlMg">{props.descHead}</p>
                </div>
            </div>
        </div>
    )
}