import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { shopActions } from "../../../../actions";
import { DataShop } from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import Button from "../../../_Common/Button";
import { shopPublishedSvg } from "../../_Common/IconSvg";
import TableLoader from "../../_Common/TableLoader";
import { Event } from "../../_Common";

interface Props {
  dataShop: DataShop;
  shopLoading: boolean;
  GetShopInfo: () => void;
  history: any;
}

const ShopPublishedSuccess: React.FC<Props> = ({
  dataShop,
  shopLoading,
  GetShopInfo,
  history,
}) => {
  useEffect(() => {
    GetShopInfo();
  }, []);

  useEffect(() => {
    if (!dataShop || !dataShop.published) history.push("/settings/shop/store");
  }, [dataShop]);

  return shopLoading ? (
    <div className="RlPs" style={{ paddingTop: "60px" }}>
      <TableLoader />
    </div>
  ) : (
    <div className="ShopPublishedSuccess">
      {shopPublishedSvg}
      <h1>{Translate("shop", "congratulation")}</h1>
      <h2>{Translate("shop", "shopPublishedMessage")}</h2>
      <Link to="/stock/products">
        <Button
          BtnText={Translate("shop", "startAddProduct")}
          onClick={() => {
            Event("SHOP", "SHOP_START_ADDING_PRODUCTS", "CLICK_EVENT");
          }}
        />
      </Link>
    </div>
  );
};

function mapState(state: any) {
  const { shopLoading, dataShop } = state.shop;
  return { shopLoading, dataShop };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
};

export default connect(mapState, actionCreators)(ShopPublishedSuccess);
