import { ordersConstants } from '../constants';

export function orders(state , action) {
  switch (action.type) {
    case ordersConstants.ADD_ORDER_REQUEST:
      return {
        OrderAdded:"1",
        dataAddOrder: action.payload
      };
    case ordersConstants.ADD_ORDER_SUCCESS:
      return {
        OrderAdded:"2",
        dataAddOrder: action.payload
      };
    case ordersConstants.ADD_ORDER_FAILURE:
      return {
        OrderAdded:"3",
        dataAddOrder: action.payload
      };
    case ordersConstants.GET_ORDER_REQUEST:
      return {
        OrderGeting:"1",
        OrdersList: action.payload
      };
    case ordersConstants.GET_ORDER_SUCCESS:
      return {
        OrderGeting:"2",
        OrdersList: action.payload
      };
    case ordersConstants.GET_ORDER_FAILURE:
      return {
        OrderGeting:"3",
        OrdersList: action.payload
      };
    case ordersConstants.GET_ORDER_HISTORY_REQUEST:
      return {
        ...state,
        GettingOrderHist:"1",
        OrderHist: action.payload
      };
    case ordersConstants.GET_ORDER_HISTORY_SUCCESS:
      return {
        ...state,
        GettingOrderHist:"2",
        OrderHist: action.payload
      };
    case ordersConstants.GET_ORDER_HISTORY_FAILURE:
      return {
        ...state,
        GettingOrderHist:"3",
        OrderHist: action.payload
      };
    case ordersConstants.UPDATE_ORDER_REQUEST:
      return {
        OrderUpdating:"1",
        DataUpdateOrder: action.payload
      };
    case ordersConstants.UPDATE_ORDER_SUCCESS:
      return {
        OrderUpdating:"2",
        DataUpdateOrder: action.payload
      };
    case ordersConstants.UPDATE_ORDER_FAILURE:
      return {
        OrderUpdating:"3",
        DataUpdateOrder: action.payload
      };
    case ordersConstants.UPDATE_STATUS_ORDER_REQUEST:
      return {
        ...state,
        OrderUpdSta:"0",
        DataUpdSta: action.payload
      };
    case ordersConstants.UPDATE_STATUS_ORDER_SUCCESS:
      return {
        ...state,
        OrderUpdSta:"1",
        DataUpdSta: action.payload
      };
    case ordersConstants.UPDATE_STATUS_ORDER_FAILURE:
      return {
        ...state,
        OrderUpdSta:"2",
        DataUpdSta: action.payload
      };
    case ordersConstants.IMPORT_ORDER_REQUEST:
      return {
        ImportingOrd:"0",
        DataImport: action.payload
      };
    case ordersConstants.IMPORT_ORDER_SUCCESS:
      return {
        ImportingOrd:"1",
        DataImport: action.payload
      };
    case ordersConstants.IMPORT_ORDER_FAILURE:
      return {
        ImportingOrd:"2",
        DataImport: action.payload
      };
    case "CLEAR":
      return {}
    default:
        return {...state}
    }
}

export function unfOrders(state, action) {
  switch (action.type) {
    case ordersConstants.GET_UNF_ORDER_REQUEST:
      return {
        GetinunfOrd:"1",
        dataInfOrd: action.payload
      };
    case ordersConstants.GET_UNF_ORDER_SUCCESS:
      return {
        GetinunfOrd:"2",
        dataInfOrd: action.payload
      };
    case ordersConstants.GET_UNF_ORDER_FAILURE:
      return {
        GetinunfOrd:"3",
        dataInfOrd: action.payload
      };
    case ordersConstants.UPDATE_UNF_ORDER_REQUEST:
      return {
        UpdinunfOrd:"1",
        dataUpInfOrd: action.payload
      };
    case ordersConstants.UPDATE_UNF_ORDER_SUCCESS:
      return {
        UpdinunfOrd:"2",
        dataUpInfOrd: action.payload
      };
    case ordersConstants.UPDATE_UNF_ORDER_FAILURE:
      return {
        UpdinunfOrd:"3",
        dataUpInfOrd: action.payload
      };
    case "CLEAR_UNF_ORD":
      return {}
    default:
        return {...state}
    }
}
export function confOrders(state, action) {
  switch (action.type) {
    case ordersConstants.GET_CONF_SUMMARY_REQUEST:
      return {
        ...state,
        GettingSummary:"0",
        SummaryData: action.payload
      };
    case ordersConstants.GET_CONF_SUMMARY_SUCCESS:
      return {
        ...state,
        GettingSummary:"1",
        SummaryData: action.payload
      };
    case ordersConstants.GET_CONF_SUMMARY_FAILURE:
      return {
        ...state,
        GettingSummary:"2",
        SummaryData: action.payload
      };
    case ordersConstants.GET_CONF_ORDER_REQUEST:
      return {
        ...state,
        GettingConfirmOrders:"0",
        ConfOrdersList: action.payload
      };
    case ordersConstants.GET_CONF_ORDER_SUCCESS:
      return {
        ...state,
        GettingConfirmOrders:"1",
        ConfOrdersList: action.payload
      };
    case ordersConstants.GET_CONF_ORDER_FAILURE:
      return {
        ...state,
        GettingConfirmOrders:"2",
        ConfOrdersList: action.payload
      };
    case ordersConstants.UPDATE_CONF_ORDER_REQUEST:
      return {
        ...state,
        UpdinunfOrd:"1",
        dataUpInfOrd: action.payload
      };
    case ordersConstants.UPDATE_CONF_ORDER_SUCCESS:
      return {
        ...state,
        UpdinunfOrd:"2",
        dataUpInfOrd: action.payload
      };
    case ordersConstants.UPDATE_CONF_ORDER_FAILURE:
      return {
        ...state,
        UpdinunfOrd:"3",
        dataUpInfOrd: action.payload
      };
    case "CLEAR_CONF_ORD":
      return {}
    default:
        return {...state}
    }
}