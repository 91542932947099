import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useQueryParam, NumberParam, StringParam } from "use-query-params";
import DatePicker from "../../_Common/DatePicker";
import ContainerPage from "../_Common/ContainerPage";
import { Event } from "../_Common";
import {
  Translate,
  FormatCurrency,
  FormatDate,
} from "../../../utils/lang/translate";
import "./ShopPage.scss";
import { DataShop, ShopStatistic } from "../../../types";
import Button from "../../_Common/Button";
import TableLoader from "../_Common/TableLoader";
import WebsiteVisitors from "./WebsiteVisitors";
import VisitorsSource from "./VisitorsSource";
import SalesPerformance from "./SalesPerformance";
import { shopActions } from "../../../actions";

const VisitorsDataSample: ShopStatistic = {
  websiteVisitors: [
    {
      name: "visitors",
      value: 60,
      rate: 2.3,
    },
    {
      name: "TimeOnPage",
      value: 65,
      rate: 23,
    },
    {
      name: "averagePageVisited",
      value: 4,
      rate: -20,
    },
  ],
  salesPerformance: [
    {
      name: "salesCount",
      value: 32,
      rate: 2.3,
    },
    {
      name: "unrealizedRevenue",
      value: 102000,
      rate: 23,
    },
    {
      name: "conversionRate",
      value: 13,
      rate: -20,
    },
  ],
  visitorsSources: [
    {
      wilaya: "Alger",
      value: 100,
    },
    {
      wilaya: "Oran",
      value: 75,
    },
    {
      wilaya: "Setif",
      value: 63,
    },
    {
      wilaya: "Constantine",
      value: 80,
    },
    {
      wilaya: "Tlemcen",
      value: 80,
    },
  ],
  visitorsChart: {
    data: [75, 24, 31, 50, 66, 39, 44],
    labels: [
      "10-03-2022",
      "11-03-2022",
      "12-03-2022",
      "13-03-2022",
      "14-03-2022",
      "15-03-2022",
      "16-03-2022",
    ],
  },
};

const visitorsChart = [75, 24, 31, 50, 66, 39, 44];

interface Props {
  dtStoreinfo: any;
  location: any;
  GetShopInfo: () => void;
  dataShop: DataShop;
  shopLoading: boolean;
  history: any;
}

const ShopPage: React.FC<Props> = (props) => {
  const { dtStoreinfo, location, GetShopInfo, dataShop, shopLoading, history } =
    props;
  const {
    salesPerformance,
    visitorsSources,
    websiteVisitors,
    visitorsChart: { data, labels },
  } = VisitorsDataSample;

  const [began, setBegan] = useQueryParam("began", StringParam);
  const [end, setEnd] = useQueryParam("end", StringParam);
  const [isSearchFilterTriggered, TriggerSearchFilter] = useState(false);
  const [currentSearchFilter, setCurrentSearchFilter] = useState<any>("");
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    document.title = "Maystro Delivery - " + Translate("titles", "shopPage");
    GetShopInfo();
  }, []);

  useEffect(() => {
    if (!shopLoading && dataShop && !dataShop.published)
      history.push("/settings/shop/");
    setIsLoading(false);
  }, [dataShop]);

  useEffect(() => {
    if (isSearchFilterTriggered && currentSearchFilter !== location.search) {
      setCurrentSearchFilter(location.search);
      TriggerSearchFilter(false);
    }
  }, [isSearchFilterTriggered, props]);

  useEffect(() => {
    // console.log(began, end);
  }, [began, end]);

  const clearDate = () => {
    setBegan(undefined);
    setEnd(undefined);
    TriggerSearchFilter(true);
  };

  return (
    <ContainerPage
      page_title={Translate("titles", "shop")}
      secondTitle={
        <Button
          clName="bundle-button"
          BtnText={Translate("shop", "bundleButton")}
          onClick={() => {
            Event("SHOP", "PRODUCT_BUNDLE", "CLICK_EVENT");
            history.push("/shop/bundle");
          }}
        />
      }
      data_top={
        <DatePicker
          forceUp={() => {
            TriggerSearchFilter(true);
          }}
          from={began}
          to={end}
          changeFrom={setBegan}
          changeTo={setEnd}
          initDate={!shopLoading}
          shopRange
        />
      }
    >
      {isLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <div className="ShopDashboard">
          <div>
            <WebsiteVisitors
              websiteVisitors={websiteVisitors}
              data={data}
              labels={labels}
            />
            <SalesPerformance salesPerformance={salesPerformance} />
          </div>
          <VisitorsSource visitorsSources={visitorsSources} />
        </div>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { gtStoreInf, dtStoreinfo, dataProfile } = state.user;
  const { dataShop, shopLoading } = state.shop;
  return {
    dataProfile,
    gtStoreInf,
    dtStoreinfo,
    dataShop,
    shopLoading,
  };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
};

export default connect(mapState, actionCreators)(ShopPage);
