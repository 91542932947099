import axios from "axios";
let URL_API = "https://maystro-shop-nyg42cuu7q-ew.a.run.app/api/";

if (process.env.REACT_APP_STATE === "prod") {
  URL_API = "https://b.maystro-delivery.com/api/";
}
if (process.env.REACT_APP_STATE === "costumized") {
  console.log("you are using a costumized api address!");
  URL_API = process.env.DJANGO_BACKEND;
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "shop-dev") {
    console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://maystro-shop-nyg42cuu7q-ew.a.run.app/api/";
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "dev") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://django-dev-nyg42cuu7q-ew.a.run.app/api/";
  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "dev2") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://maystro-backend-nyg42cuu7q-ew.a.run.app/api/";
  console.log("URL API IS:", URL_API);
}

if (process.env.REACT_APP_STATE === "staging") {
  console.error(
    "you are using the dev server! be sure no one else is using it for testing!"
  );
  URL_API = "https://backend-staging-utvw6oxohq-ew.a.run.app/api/";
  console.log("URL API IS:", URL_API);
}
if (process.env.REACT_APP_STATE === "dev-loc") {
  console.error("you are using the local server!");
  URL_API = "http://localhost:8000/api/";
  console.log("URL API IS:", URL_API);
}

export const RequestService = {
  GetRequest,
  PostRequest,
  PatchRequest,
  DeleteRequest,
  PutRequest,
};

function GetRequest(url, headers) {
  return axios.get(`${URL_API}${url}`, {
    headers: headers,
  });
}

function PostRequest(url, data, headers, onUploadProgress) {
  return axios.post(`${URL_API}${url}`, data, {
    headers: headers,
    onUploadProgress,
  });
}
function PatchRequest(url, data, headers, onUploadProgress) {
  return axios.patch(`${URL_API}${url}`, data, {
    headers: headers,
    onUploadProgress,
  });
}
function DeleteRequest(url, headers) {
  return axios.delete(`${URL_API}${url}`, {
    headers: headers,
  });
}
function PutRequest(url, data, headers) {
  return axios.put(`${URL_API}${url}`, data, {
    headers: headers,
  });
}
