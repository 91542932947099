import React, { useMemo } from "react";
import ReactTooltip from "react-tooltip";
import { v4 as uuid } from "uuid";

export default function GroupeBtn(props) {
  const COMPONENT_ID = useMemo(uuid, []);
  let wth = "calc(32px * " + props.data_btn.length + " )";
  const handleSubElementClick = (e, elem, key) => {
    const { right, bottom } = e.currentTarget.getBoundingClientRect();
    props.subElementClickHandler(
      COMPONENT_ID + key,
      right,
      bottom,
      e.currentTarget.clientWidth,
      elem.subElements
    );
  };

  return (
    <div className="StBrdRdSc MrAot" style={{ width: wth }}>
      {Array.isArray(props.data_btn) &&
        props.data_btn.map((elem, key) => {
          return (
            <div
              key={COMPONENT_ID + key}
              className={"Inlflx stBdp CrsPoi " + elem.type}
              data-tip
              data-for={`${elem.tooltip}-tooltip-${key}`}
              onClick={
                Array.isArray(elem.subElements) && elem.svgbtn
                  ? (e) => {
                      handleSubElementClick(e, elem, key);
                    }
                  : elem.action
              }
            >
              {elem.svgbtn}
              {elem.tooltip && (
                <ReactTooltip id={`${elem.tooltip}-tooltip-${key}`}>
                  {elem.tooltip}
                </ReactTooltip>
              )}
            </div>
          );
        })}
    </div>
  );
}
