import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate, FormatCurrency, FormatDate } from "../../../utils/lang/translate";
import StatusElement from "../_Common/StatusElement";
import Table from "../_Common/Table";
import BtnLg from "../_Common/BtnLg";
import GroupeBtn from "../_Common/GroupeBtn";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import { DeletSvg, DoneSvg, FlechDwnSvg, FilterSvg, RefreshSvg, CloseSvg, FileDownSvg, ExcelSvg, PlayListAddSvg } from "../_Common/IconSvg";
import InlinedElement from "../_Common/InlinedElement";
import CheckBoxForm from "../../_Common/CheckBoxForm";
import { ordersActions, alertActions } from "../../../actions";
import TableLoader from "../_Common/TableLoader";
import Loader from "../../_Common/Loader";
import DatePicker from "../../_Common/DatePicker";
import TabShow from "../_Common/TabShow";
import PropTypes from "prop-types";
import DropedList from "../_Common/DropedList";
import { EmptyScreen } from "../_Common/EmptyScreen";
import SmallText from "../_Common/SmallText";
import PaginationBar from "../_Common/PaginationBar";
import { addUrlProps, UrlQueryParamTypes } from "react-url-query";
import { initGAPg, Event } from "../_Common";
import { UNFULLFILLED_TABLE_HEADER } from "../../constants";
const urlPropsQueryConfig = {
  status: { type: UrlQueryParamTypes.string },
  began: { type: UrlQueryParamTypes.string },
  end: { type: UrlQueryParamTypes.string },
  page: { type: UrlQueryParamTypes.number },
  search: { type: UrlQueryParamTypes.string },
};

class UnfulfilledOrderPage extends Component {
  constructor(props) {
    super(props);
    const { GetinunfOrd, dataInfOrd } = this.props;
    this.state = {
      orders: GetinunfOrd === "2" ? dataInfOrd.ressuc.results : [],
      nextpage: GetinunfOrd === "2" ? dataInfOrd.ressuc.next : null,
      prevpage: GetinunfOrd === "2" ? dataInfOrd.ressuc.previous : null,
      count: GetinunfOrd === "2" ? dataInfOrd.ressuc.count : 0,
      status: this.props.status,
      statusfilter: false,
      datefilter: false,
      alertdelet: null,
    };
    this.crntfilter = this.props.GetinunfOrd === "2" ? this.props.GetinunfOrd.filter : "";
    this.refrs = {
      Status_Container: React.createRef(),
    };
    this.evnfunc = {
      Status_Container: (e) => {
        if (this.refrs.Status_Container && !this.refrs.Status_Container.current.contains(e.target)) {
          this.ControlFilter("Status_Container");
        }
      },
    };
    this.CallProps = false;
    this.resFilter = false;
    this.statusRef = React.createRef();
    this.dateRef = React.createRef();
    this.FilterRef = React.createRef();
    this.CallPropsS = this.CallPropsS.bind(this);
    this.ShowFilter = this.ShowFilter.bind(this);
    this.resetFilter = this.resetFilter.bind(this);
    this.handleClickOutsideFilter = this.handleClickOutsideFilter.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.controlExport = this.controlExport.bind(this);
  }
  static defaultProps = {
    page: 1,
    search: "",
  };
  static propTypes = {
    status: PropTypes.string,
    began: PropTypes.string,
    end: PropTypes.string,
    page: PropTypes.number,
    search: PropTypes.string,
    /********************** */
    onChangeBegan: PropTypes.func,
    onChangeEnd: PropTypes.func,
    onChangePage: PropTypes.func,
    onChangeStatus: PropTypes.func,
    onChangeSearch: PropTypes.func,
  };
  componentWillReceiveProps(nextProps) {
    const { GetinunfOrd, dataInfOrd, UpdinunfOrd, search, page } = nextProps;
    if (GetinunfOrd === "2") {
      document.getElementById("Container-root").scrollTo(0, 0);
      this.setState({
        orders: dataInfOrd.ressuc.results,
        nextpage: dataInfOrd.ressuc.next,
        prevpage: dataInfOrd.ressuc.previous,
        count: dataInfOrd.ressuc.count,
      });
    }
    if (GetinunfOrd === "3") {
      if (dataInfOrd && dataInfOrd.data && dataInfOrd.data.detail === "Page invalide.") {
      }
    }
    if (UpdinunfOrd === "2") {
      this.exctuteFilterUrl(this.getFilterToState(), true);
    }
    if (page !== this.props.page || this.resFilter || this.CallProps) {
      this.resFilter = false;
      this.CallProps = true;
      this.exctuteFilterUrl(this.getFilterToState(nextProps));
    }
    if (this.props.search !== search && ((search && search.length >= 2) || (this.props.search && !search))) {
      this.exctuteFilterUrl(this.getFilterToState(nextProps));
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivery - " + Translate("titles", "unfulfilledorder");
    initGAPg();
    let nextFilter = this.getFilterToState();
    if (this.props.GetinunfOrd !== "2") {
      this.exctuteFilterUrl(nextFilter, true);
    }
  }
  /*********  */

  getFilterToState(props) {
    let curntprops = props ? props : this.props;
    let Filter = curntprops.location.search;
    if (!curntprops.status) {
      Filter = Filter ? Filter + "&status=0" : "?status=0";
    }
    return Filter;
  }
  exctuteFilterUrl(nextFilter, forceUpdate) {
    let prevFilter = this.crntfilter;
    if (prevFilter !== nextFilter || forceUpdate) {
      this.crntfilter = nextFilter;
      this.props.GetUnfOrders(nextFilter);
    }
  }
  manageQueryStatus(statu) {
    this.CallProps = true;
    this.props.onChangeStatus(statu);
  }
  ControlFilter(idTraget) {
    let clsList = document.getElementById(idTraget);
    if (clsList.classList.value.indexOf("hidElem3") !== -1) {
      clsList.classList.remove("hidElem3");
      clsList.style.maxHeight = "400px";
      document.addEventListener("mousedown", this.evnfunc[idTraget]);
    } else {
      clsList.style.maxHeight = "0px";
      clsList.classList.add("hidElem3");
      document.removeEventListener("mousedown", this.evnfunc[idTraget]);
    }
  }
  ShowFilter() {
    Event("UNFULL_ORDERS", "CLICK_FILTER", "CLICK_EVENT");
    let clsList = document.getElementById("Filter_container");
    if (clsList.classList.value.indexOf("hidElem2") !== -1) {
      clsList.classList.remove("hidElem2");
      document.addEventListener("mousedown", this.handleClickOutsideFilter);
    } else {
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideFilter);
    }
  }
  handleClickOutsideFilter(event) {
    if (this.FilterRef && this.FilterRef.current && !this.FilterRef.current.contains(event.target)) {
      this.ShowFilter();
    }
  }
  resetFilter() {
    const { onChangeBegan, onChangeEnd, onChangeStatus } = this.props;
    onChangeBegan(undefined);
    onChangeEnd(undefined);
    onChangeStatus(undefined);
    this.resFilter = true;
  }
  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  RemoveOrder(id) {
    let idremove = this.state.alertdelet;
    if (idremove) {
      this.props.UpdateUnfOrder({ status: 2 }, idremove);
      Event("UNFULL_ORDERS", "CONFIRM_REMOVE_ORDER", "CLICK_EVENT");
      this.setState({
        alertdelet: null,
      });
    } else {
      Event("UNFULL_ORDERS", "USE_REMOVE_ORDER", "CLICK_EVENT");
      this.setState({
        alertdelet: id,
      });
    }
  }
  CallPropsS() {
    this.CallProps = true;
  }
  clearDate() {
    this.props.onChangeBegan(undefined);
    this.props.onChangeEnd(undefined);
    this.CallProps = true;
  }
  clearStatus() {
    this.props.onChangeStatus(undefined);
    this.CallProps = true;
  }
  addOrder(path) {
    Event("UNFULL_ORDERS", "GO_TO_ADD_ORDER", "CLICK_EVENT");
    this.props.history.push(path);
  }
  RefreshUnfOredr() {
    Event("UNFULL_ORDERS", "USE_REFRESH_BUTON", "CLICK_EVENT");
    this.exctuteFilterUrl(this.getFilterToState(), true);
  }
  handleSearch(e) {
    const { value } = e.target;
    this.props.onChangeSearch(value);
  }
  CallExportOrder(fileEx) {
    if (this.props.Exporting !== "0") {
      Event("ORDERS", "EXPORT_ORDERS", `EXORT_AS_${fileEx.toUpperCase()}`);
      this.props.SendAlert("31", Translate("alert", "exportreqstsent"), "a04");
      this.props.ExportOrder(this.props.location.search.replaceAll(".", ",").replace("prduord", "products"), fileEx, "IS_TEMP_ORDER");
    }
  }
  controlExport() {
    Event("ORDERS", "DROP_EXPORT_LIST", "CLICK_EVENT");
    let clsList = document.getElementById("List_Export");
    if (clsList.classList.value.indexOf("hidElem2") === -1) {
      clsList.style.height = "0px";
      clsList.classList.add("hidElem2");
      document.removeEventListener("mousedown", this.handleClickOutsideExpRef);
    } else {
      clsList.classList.remove("hidElem2");
      clsList.style.height = "165px";
      document.addEventListener("mousedown", this.handleClickOutsideExpRef);
    }
  }
  render() {
    const { GetinunfOrd, UpdinunfOrd, page } = this.props;
    let bodytab = [];
    if (GetinunfOrd === "2") {
      this.state.orders.forEach((elem) => {
        const { id, order_name, products, display_id, customer_name, customer_phone, ordered_at, product_price, status, wilaya, commune } = elem;
        if (status !== 1) {
          let ordered_at2 = <div style={{ minWidth: "95px" }}>{FormatDate(ordered_at)}</div>;
          let btns = "";
          switch (status) {
            case 0:
              btns = (
                <GroupeBtn
                  data_btn={[
                    {
                      type: "BDgInpc",
                      action: () => this.addOrder("/order/add/" + display_id + "?source=unfOrder"),
                      svgbtn: DoneSvg,
                      tooltip: Translate("tooltips", "approveorder"),
                    },
                    {
                      type: "BDgInpc",
                      action: () => this.RemoveOrder(id),
                      svgbtn: DeletSvg,
                      tooltip: Translate("profile", "delete"),
                    },
                  ]}
                />
              );
              break;
            case 2:
              btns = <StatusElement>50</StatusElement>;
              break;
            default:
              btns = "";
              break;
          }
          let productsf = "";
          if (Array.isArray(products) && products.length > 0) {
            if (products.length > 1) {
              let PrdctList = (
                <div onClick={() => this.Gotodetail(display_id)}>
                  {products.map((elem, key) => {
                    return (
                      <div key={key} className="Inlflx TxTrnf Mrtpvl">
                        <strong className="StPaddingVal">{" (" + elem.quantity + ") "}</strong>
                        <SmallText>{elem.logistical_description}</SmallText>
                      </div>
                    );
                  })}
                </div>
              );
              productsf = (
                <DropedList
                  props={{ ...this.props }}
                  title={
                    <div className="InFlx AlgnItm RlPs">
                      <span>{products.length}</span>
                      <span className="StPaddingVal">{Translate("titles", "product")}</span>
                      <span className="DsPlCnt">{FlechDwnSvg}</span>
                    </div>
                  }
                  dataDrop={PrdctList}
                />
              );
            } else {
              productsf = (
                <div className="Inlflx TxTrnf">
                  <strong className="StPaddingVal">{" (" + products[0].quantity + ") "}</strong>
                  <SmallText>{products[0].logistical_description}</SmallText>
                </div>
              );
            }
          } else {
            productsf = order_name;
          }
          bodytab.push([display_id, productsf, wilaya + " ( " + commune + " ) ", customer_name, customer_phone, ordered_at2, <div>{this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(product_price) : product_price + "  " + "TND"}</div>, btns]);
        }
      });
    }
    return (
      <ContainerPage
        page_title={Translate("titles", "unfulfilledorder")}
        data_top={
          <div className="d-large">
            <DatePicker forceUp={this.CallPropsS} from={this.props.began} to={this.props.end} changeFrom={this.props.onChangeBegan} changeTo={this.props.onChangeEnd} chnageDate={this.props.onChangeDate} />
          </div>
        }
      >
        {GetinunfOrd === "2" && this.state.count === 0 && !(this.props.location.search && this.props.location.search.replace("?page=" + this.props.page, "") !== "") ? (
          <EmptyScreen titleGlb={Translate("orders", "noorder")} TextShow={Translate("orders", "youhavntcrUnf")} />
        ) : (
          <div>
            {UpdinunfOrd === "1" && <Loader />}
            <div className="InFlx flxDrc StmpB3 StPs zindX2 BdgGc" style={{ height: "48px", padding: "7px 0", top: "-20px" }}>
              <div role="filter-data" className="FlHg InFlx StwdMaxC flxDrc">
                <div className="FlHg ClSidTh StBrdRdS StMrtg2" ref={this.FilterRef}>
                  <div className="CrsPoi FlHg" onClick={this.ShowFilter}>
                    <InlinedElement secnd={<div className="IcnSiz">{FilterSvg}</div>} />
                  </div>
                  <div id="Filter_container" className="StAbs Zindxsm StLanNlin StBxSh1 StwdMaxC stTranEs StTpval3 HdOvrfl ClSidTh StPdsml StBgbrds hidElem2" style={{ width: "350px", maxWidth: "200px" }}>
                    <div id="Sub_Container_filter">
                      <div style={{ marginTop: "8px" }}>
                        <div className="RlPs">
                          <InlinedElement
                            secnd={<strong>{Translate("orders", "filter")}</strong>}
                            leftElm={
                              <div onClick={this.ShowFilter} className="InFlx CrsPoi IcnSizSm">
                                {CloseSvg}
                              </div>
                            }
                            style={{ width: "100%", padding: "0px" }}
                          />
                        </div>
                        {this.props.location.search && (this.props.location.search.replace("?page=" + this.props.page, "") !== "" || this.props.location.search.replace("&page=" + this.props.page, "")) !== "" ? (
                          <span className="CrsPoi StBle DsBlc StdirLn" onClick={this.resetFilter}>
                            {Translate("orders", "resetfilter")}
                          </span>
                        ) : (
                          ""
                        )}
                      </div>
                      <div ref={this.refrs.Status_Container} className="BdgClN StBrdRdS StPdsml SmMarS1">
                        <div className="StHeivl RlPs CrsPoi" onClick={() => this.ControlFilter("Status_Container")}>
                          <InlinedElement secnd={<strong>{Translate("orders", "orderstatus")}</strong>} leftElm={<div className="InFlx">{FlechDwnSvg}</div>} style={{ width: "100%", padding: "0px" }} />
                        </div>
                        <div id="Status_Container" className="hidElem3 stTranEs">
                          <div>
                            <CheckBoxForm id={"S2"} name={"S2"} text={Translate("statusorder", "show50")} workfun={() => this.manageQueryStatus(this.props.status === "2" ? undefined : "2")} check={this.props.status === "2"} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="FlHg StBrdRdS ClSidTh">
                  <InlinedElement
                    style={{ padding: "0" }}
                    secnd={
                      <div>
                        <input type="text" autoComplete="off" onChange={this.handleSearch} name="filterbyid" style={{ width: "300px", maxWidth: "35vw" }} className="StdirLn FntfMin StPdsml BdgTrans DlBrd StSizLn Fntcl" value={this.props.search} placeholder={Translate("orders", "recherchunf")} />
                      </div>
                    }
                  />
                </div>
              </div>
              <div className="StAutMr" ref={this.ExpRef}>
                <div className="Inlflx AlgnItm StDrc StFlxStr FlWd" style={{ height: "48px" }}>
                  <GroupeBtnSp
                    style={{ margin: "0", zoom: "1.2" }}
                    data_btn={[
                      {
                        type: "BDgInpc",
                        action: this.controlExport,
                        svgbtn: PlayListAddSvg,
                      },
                      {
                        type: "BDgInpc",
                        action: this.RefreshUnfOredr.bind(this),
                        svgbtn: RefreshSvg,
                        tooltip: Translate("tooltips", "refresh"),
                      },
                    ]}
                  />
                </div>
                <div className="RlPs InFlx StDrc StFlxStr" style={{ zIndex: "2" }}>
                  <div id="List_Export" className="hidElem2 StwdMaxC HdOvrfl ClSidTh StBgbrds stTranEs StBxSh1" style={{ height: "0px" }}>
                    <div className="CrsPoi" style={{ height: "55px" }} onClick={() => this.CallExportOrder("csv")}>
                      <InlinedElement
                        style={{ flexDirection: "row" }}
                        first={
                          <div className="IcnSiz" style={{ zoom: "1.2" }}>
                            {FileDownSvg}
                          </div>
                        }
                        secnd={<div>{Translate("orders", "exportordcsv")}</div>}
                      />
                    </div>
                    <div className="CrsPoi" style={{ height: "55px" }} onClick={() => this.CallExportOrder("xlsx")}>
                      <InlinedElement
                        style={{ flexDirection: "row" }}
                        first={
                          <div className="IcnSiz" style={{ zoom: "1.2" }}>
                            {ExcelSvg}
                          </div>
                        }
                        secnd={<div>{Translate("orders", "exportordxsl2007")}</div>}
                      />
                    </div>
                    <div className="CrsPoi" style={{ height: "55px" }} onClick={() => this.CallExportOrder("xls")}>
                      <InlinedElement
                        style={{ flexDirection: "row" }}
                        first={
                          <div className="IcnSiz" style={{ zoom: "1.2" }}>
                            {ExcelSvg}
                          </div>
                        }
                        secnd={<div>{Translate("orders", "exportordxsl97")}</div>}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-sm">
              <DatePicker forceUp={this.CallPropsS} from={this.props.began} to={this.props.end} changeFrom={this.props.onChangeBegan} changeTo={this.props.onChangeEnd} chnageDate={this.props.onChangeDate} />
            </div>
            <div className="InFlx flxDrc StmpB3 StOpcVal StFlxWrp2 FlwMax AlgnItm">
              {this.props.status && this.props.status !== "0" && (
                <Fragment>
                  <div className="stBdp StSmlS StwdMaxC">{Translate("orders", "orderstatus")}</div>
                  <div className="stBdp CrsPoi SthgMaxC" onClick={() => this.clearStatus()}>
                    <InlinedElement
                      style={{
                        border: "2px solid #3498DB",
                        padding: "3px 6px",
                        backgroundColor: "#3498DB35",
                        borderRadius: "5px",
                      }}
                      secnd={
                        <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                          {CloseSvg}
                        </div>
                      }
                      first={<div className="StSmlS StwdMaxC">{Translate("statusorder", this.props.status)}</div>}
                    />
                  </div>
                </Fragment>
              )}
              {this.props.began && this.props.end && (
                <Fragment>
                  <div className="stBdp StSmlS StwdMaxC">{Translate("datepicker", "daterange")}</div>
                  <div className="stBdp CrsPoi SthgMaxC" onClick={() => this.clearDate()}>
                    <InlinedElement
                      style={{
                        border: "2px solid #3498DB",
                        padding: "3px 6px",
                        backgroundColor: "#3498DB35",
                        borderRadius: "5px",
                      }}
                      secnd={
                        <div className="IcnSiz CrsPoi" style={{ zoom: ".48" }}>
                          {CloseSvg}
                        </div>
                      }
                      first={<div className="StSmlS StwdMaxC">{FormatDate(this.props.began, true) + " => " + FormatDate(this.props.end, true)}</div>}
                    />
                  </div>
                </Fragment>
              )}
            </div>
            <div>
              {GetinunfOrd === "1" ? (
                <TableLoader />
              ) : this.state.count === 0 && this.props.location.search && this.props.location.search.replace("?page=" + this.props.page, "") !== "" ? (
                <div className="RlPs">
                  <EmptyScreen titleGlb={Translate("orders", "noorder")} TextShow={Translate("orders", "filterempty")} TxtBtn={Translate("orders", "resetfilter")} FuncCall={() => this.resetFilter()} />
                </div>
              ) : (
                <div>
                  <Table thead_elem={UNFULLFILLED_TABLE_HEADER} tbody_elem={bodytab} goTo={this.Gotodetail} />
                  <div role="pagination" className="lytWdp SmMarS1">
                    <div className="InFlx flxDrc FlWd RlPs AlgnItm">
                      <span className="d-large">{Translate("orders", "showing") + " " + (page * 20 - 19) + " - " + (page * 20 < this.state.count ? page * 20 : this.state.count) + " " + Translate("orders", "of") + " " + this.state.count}</span>
                      <PaginationBar className="StAutMr" NbPage={Math.trunc(this.state.count / 20) + 1} currentPage={parseInt(page)} ChangePage={this.props.onChangePage} blockNext={this.state.nextpage} blockPrev={this.state.prevpage} />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.alertdelet && (
          <TabShow tab_title={Translate("profile", "confirmaction")} wdth="550px" NotCls={true}>
            <div className="MrAot" style={{ width: "94%" }}>
              <p className="StdirLn FlWd DltTpMar">{Translate("orders", "confrmdelete")}</p>
              <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.showOpt("alertdelet")}>
                  <BtnLg text={Translate("profile", "cancel")} classSet={""} />
                </div>
                <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.RemoveOrder()}>
                  <BtnLg text={Translate("orders", "cancelorder")} classSet={"TxDng"} />
                </div>
              </div>
            </div>
          </TabShow>
        )}
      </ContainerPage>
    );
  }
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.page !== this.props.match.params.page) {
      this.exctuteFilterUrl(this.getFilterToState());
    }
  }
  $_GET(param) {
    var vars = {};
    window.location.href.replace(window.location.hash, "").replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
      vars[key] = value !== undefined ? value : "";
    });

    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const { GetinunfOrd, dataInfOrd, UpdinunfOrd, dataUpInfOrd } = state.unfOrders;
  const { Exporting } = state.orders;
  const { dtStoreinfo } = state.user;
  return { GetinunfOrd, dataInfOrd, UpdinunfOrd, dataUpInfOrd, Exporting, dtStoreinfo };
}

const actionCreators = {
  GetUnfOrders: ordersActions.GetUnfOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  ExportOrder: ordersActions.ExportOrder,
  SendAlert: alertActions.SendAlert,
};

const connectedUnfulfilledOrderPage = connect(mapState, actionCreators)(UnfulfilledOrderPage);
const QueryProps = addUrlProps({ urlPropsQueryConfig })(connectedUnfulfilledOrderPage);
export { QueryProps as UnfulfilledOrderPage };
