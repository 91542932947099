import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { alertActions, userActions } from "../../../actions";
import { shopActions } from "../../../actions";
import { Bundle, DataShop, UpdateDataShopSettings } from "../../../types";
import { Translate } from "../../../utils/lang/translate";
import Button from "../../_Common/Button";
import { initGAPg } from "../_Common";
import ConfirmationAlert from "../../_Common/ConfirmationAlert";
import ContainerPage from "../_Common/ContainerPage";
import DropedList from "../_Common/DropedList";
import GroupeBtn from "../_Common/GroupeBtn";
import { DeletSvg, EditSvg, FlechDwnSvg } from "../_Common/IconSvg";
import SmallText from "../_Common/SmallText";
import Table from "../_Common/Table";
import TableLoader from "../_Common/TableLoader";
import { Event } from "../_Common";
import { EmptyScreen } from "../_Common/EmptyScreen";

interface Props {
  history: any;
  GetShopInfo: () => void;
  dataShop: DataShop;
  shopLoading: boolean;
  DeleteBundle: (
    bundleId: string,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const BundlePage: React.FC<Props> = (props) => {
  const {
    GetShopInfo,
    dataShop,
    shopLoading,
    DeleteBundle,
    SendAlert,
    history,
  } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [idDelete, setIdDelete] = useState<string | null>(null);

  useEffect(() => {
    initGAPg();
    GetShopInfo();
  }, []);

  useEffect(() => {
    if (!shopLoading && dataShop) {
      if (!dataShop.published) history.push("/settings/shop/store");
      setIsLoading(false);
    }
  }, [dataShop]);

  const handleDelete = (bundleId: string | null) => {
    if (dataShop && bundleId) {
      DeleteBundle(bundleId, dataShop, () => {
        SendAlert("41", Translate("shop", "changesSaved"));
        setIdDelete(null);
      });
    }
  };

  const products = dataShop?.products;
  const shop_bundles = dataShop?.shop_bundles;
  const productsWithoutBundle = useMemo(
    () =>
      products?.filter(
        (prod) =>
          !shop_bundles?.some((bundle) =>
            bundle.product_bundle?.some(
              (bundleProd) => bundleProd.product.id === prod.id
            )
          )
      ),
    [dataShop]
  );

  const bodyTab = dataShop?.shop_bundles?.map((bundle) => {
    const displayedId = bundle.display_id;

    const products = (
      <div className="InFlx Stclmnf">
        {bundle.product_bundle.map((prod, key, arr) => {
          return (
            <div key={key} className="Inlflx TxTrnf Mrtpvl">
              <span className="StPaddingVal">{` (${prod.quantity}) `}</span>
              <SmallText>
                {prod.product.title ? prod.product.title : ""}
              </SmallText>
            </div>
          );
        })}
      </div>
    );
    const productNames = (
      <DropedList
        props={{ ...props }}
        title={
          <div className="InFlx AlgnItm RlPs CrsPoi">
            <span>{bundle.product_bundle.length}</span>
            <span className="StPaddingVal">
              {Translate("titles", "product")}
            </span>
            <span className="DsPlCnt">{FlechDwnSvg}</span>
          </div>
        }
        dataDrop={products}
      />
    );

    let actionButtons: any[] = [];

    actionButtons.push({
      type: "BDgInpc",
      action: () => {
        Event("SHOP", "EDIT_BUNDLE_LINK", "CLICK_EVENT");
        history.push(`/shop/bundle/${bundle.display_id}`);
      },
      svgbtn: EditSvg,
      tooltip: Translate("shop", "edit"),
    });
    actionButtons.push({
      type: "BDgInpc",
      action: () => {
        Event("SHOP", "DELETE_BUNDLE_BUTTON", "CLICK_EVENT");
        setIdDelete(bundle.id);
      },
      svgbtn: DeletSvg,
      tooltip: Translate("profile", "delete"),
    });

    const actions = <GroupeBtn data_btn={actionButtons} />;

    return [
      displayedId,
      productNames,
      bundle.price,
      bundle.reduced_price,
      actions,
    ];
  });

  return (
    <ContainerPage
      page_title={
        <div>
          {/* <Link className="StOpcVal" to="/shop">
            {Translate("titles", "shop")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div> */}
          <span className="CrsPoi">{Translate("titles", "bundles")}</span>
        </div>
      }
      secondTitle={
        <Button
          clName="bundle-button"
          BtnText={Translate("shop", "addBundleButton")}
          onClick={() => {
            if (productsWithoutBundle?.length >= 2) {
              Event("SHOP", "ADD_BUNDLE_LINK", "CLICK_EVENT");
              history.push("/shop/bundle/add");
            }
          }}
          style={
            productsWithoutBundle?.length < 2
              ? { PointerEvent: "none", cursor: "not-allowed", opacity: 0.6 }
              : {}
          }
          title={
            productsWithoutBundle?.length < 2
              ? Translate("shop", "notEnoughShopProductText")
              : ""
          }
        />
      }
    >
      {isLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : bodyTab.length > 0 ? (
        <div className="BundlePage">
          <Table
            thead_elem={[
              Translate("shop", "idTab"),
              Translate("shop", "productsTab"),
              Translate("shop", "priceTab"),
              Translate("shop", "reducedPriceTab"),
              Translate("shop", "actionsTab"),
            ]}
            tbody_elem={bodyTab}
          />
          {idDelete && (
            <ConfirmationAlert
              title={Translate("shop", "deleteBundle")}
              text={Translate("shop", "deleteBundleConfirmation")}
              onClose={() => {
                Event("SHOP", "DELETE_BUNDLE_NO", "CLICK_EVENT");
                setIdDelete(null);
              }}
              onConfirm={() => {
                Event("SHOP", "DELETE_BUNDLE_YES", "CLICK_EVENT");
                handleDelete(idDelete);
              }}
              loading={shopLoading}
            />
          )}
        </div>
      ) : productsWithoutBundle?.length >= 2 ? (
        <EmptyScreen
          titleGlb={Translate("shop", "noBundle")}
          TextShow={Translate("shop", "noBundleText")}
          actionBtns={[
            {
              text: Translate("shop", "addNewBundle"),
              to: "/shop/bundle/add",
            },
          ]}
        />
      ) : (
        <EmptyScreen
          titleGlb={Translate("shop", "notEnoughShopProduct")}
          TextShow={Translate("shop", "notEnoughShopProductText")}
          actionBtns={[
            {
              text: Translate("shop", "addShopProducts"),
              to: "/stock/products",
            },
          ]}
        />
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { dataShop, shopLoading } = state.shop;
  return {
    dataShop,
    shopLoading,
  };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  DeleteBundle: shopActions.DeleteBundle,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(BundlePage);
