export const shopConstants = {
  GET_SHOP_SUCCESS: "GET_SHOP_SUCCESS",
  GET_SHOP_FAILURE: "GET_SHOP_FAILURE",
  GET_SHOP_REQUEST: "GET_SHOP_REQUEST",

  UPDATE_SHOP_SUCCESS: "UPDATE_SHOP_SUCCESS",
  UPDATE_SHOP_FAILURE: "UPDATE_SHOP_FAILURE",
  UPDATE_SHOP_REQUEST: "UPDATE_SHOP_REQUEST",

  ADD_PRODUCT_SHOP_PHOTO_SUCCESS: "ADD_PRODUCT_SHOP_PHOTO_SUCCESS",
  ADD_PRODUCT_SHOP_PHOTO_FAILURE: "ADD_PRODUCT_SHOP_PHOTO_FAILURE",
  ADD_PRODUCT_SHOP_PHOTO_REQUEST: "ADD_PRODUCT_SHOP_PHOTO_REQUEST",

  DELETE_PRODUCT_SHOP_PHOTO_SUCCESS: "DELETE_PRODUCT_SHOP_PHOTO_SUCCESS",
  DELETE_PRODUCT_SHOP_PHOTO_FAILURE: "DELETE_PRODUCT_SHOP_PHOTO_FAILURE",
  DELETE_PRODUCT_SHOP_PHOTO_REQUEST: "DELETE_PRODUCT_SHOP_PHOTO_REQUEST",
};

export const SHOP_DOMAIN_URL = "shop.maystro-delivery.com";
