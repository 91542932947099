export const is_exclusive_test_store = (store) =>
  store.use_new_status && store.use_new_status.toString() === "true";

export const CONFIRMATION_HELPERS = {
  mapStatusToCode: (status) => {
    switch (status.toUpperCase()) {
      case "PENDING":
        return 10;
      case "CALLBACK":
        return 20;
      case "POSTPONED":
        return 30;
      case "CONFIRMED":
        return 40;
      case "CANCELLED":
        return 100;
      default:
        return status;
    }
  },
  getStatusIconColor: (status) => {
    switch (status) {
      case 10:
        return "status_11";
      case 20:
        return "status_31";
      case 30:
        return "status_42";
      case 40:
        return "status_41";
      case 100:
        return "status_50";
      default:
        return "status_31";
    }
  },
};

export const ORDER_HELPERS = {
  mapStatusToCode: (status) => {
    switch (status.toUpperCase()) {
      case "PENDING":
        return 10;
      case "CALLBACK":
        return 20;
      case "POSTPONED":
        return 30;
      case "CONFIRMED":
        return 40;
      case "CANCELLED":
        return 100;
      default:
        return status;
    }
  },
  getStatusIconColor: (status) => {
    switch (status) {
      case 10:
        return "ElmSvgWt";
      case 20:
        return "StBlFil";
      case 30:
        return "ElmSvgPs";
      case 40:
        return "ElmSvgDl";
      case 100:
        return "ElmSvgCn";
      default:
        return "ElmSvgWt";
    }
  },
};
