import { commonConstants } from "../constants";

export function common(state, action) {
  switch (action.type) {
    case commonConstants.GET_WILAYAS_REQUEST:
      return {
        ...state,
        GetingWilaya: "0",
        dataWilaya: [],
      };
    case commonConstants.GET_WILAYAS_SUCCESS:
      return {
        ...state,
        GetingWilaya: "1",
        dataWilaya: action.payload,
      };
    case commonConstants.GET_WILAYAS_FAILURE:
      return {
        ...state,
        GetingWilaya: "2",
        dataWilaya: action.payload,
      };
    case commonConstants.GET_COMMUNE_REQUEST:
      return {
        ...state,
        GetigCommune: "0",
        DataCommune: action.payload,
      };
    case commonConstants.GET_COMMUNE_SUCCESS:
      return {
        ...state,
        GetigCommune: "1",
        DataCommune: action.payload,
      };
    case commonConstants.GET_COMMUNE_FAILURE:
      return {
        ...state,
        GetigCommune: "2",
        DataCommune: action.payload,
      };
    case commonConstants.GET_WAREHOUSE_REQUEST:
      return {
        ...state,
        GetingWare: "1",
        Waredata: action.payload,
      };
    case commonConstants.GET_WAREHOUSE_SUCCESS:
      return {
        ...state,
        GetingWare: "2",
        Waredata: action.payload,
      };
    case commonConstants.GET_WAREHOUSE_FAILURE:
      return {
        ...state,
        GetingWare: "3",
        Waredata: action.payload,
      };
    case commonConstants.GET_VEHICULETYPES_REQUEST:
      return {
        ...state,
        GettingTypes: "1",
        VehiculesData: action.payload,
      };
    case commonConstants.GET_VEHICULETYPES_SUCCESS:
      return {
        ...state,
        GettingTypes: "2",
        VehiculesData: action.payload,
      };
    case commonConstants.GET_VEHICULETYPES_FAILURE:
      return {
        ...state,
        GettingTypes: "3",
        VehiculesData: action.payload,
      };
    case commonConstants.GET_DELIVERY_REQUEST:
      return {
        ...state,
        GetingDelivery: "1",
        dataDelivery: action.payload,
      };
    case commonConstants.GET_DELIVERY_SUCCESS:
      return {
        ...state,
        GetingDelivery: "2",
        dataDelivery: action.payload,
      };
    case commonConstants.GET_DELIVERY_FAILURE:
      return {
        ...state,
        GetingDelivery: "3",
        dataDelivery: action.payload,
      };
    case commonConstants.GET_COUNTRY_REQUEST:
      return {
        ...state,
        GetingPays: "0",
        dataCountry: [],
      };
    case commonConstants.GET_COUNTRY_SUCCESS:
      return {
        ...state,
        GetingPays: "1",
        dataCountry: action.payload,
      };
    case commonConstants.GET_COUNTRY_FAILURE:
      return {
        ...state,
        GetingPays: "2",
        dataCountry: action.payload,
      };
    case commonConstants.GET_WILAYA_STORE_REQUEST:
      return {
        ...state,
        GetingWilayastore: "0",
        dataWilayaStore: [],
      };
    case commonConstants.GET_WILAYA_STORE_SUCCESS:
      return {
        ...state,
        GetingWilayastore: "1",
        dataWilayaStore: action.payload,
      };
    case commonConstants.GET_WILAYA_STORE_FAILURE:
      return {
        ...state,
        GetingWilayastore: "2",
        dataWilayaStore: action.payload,
      };
    case commonConstants.GET_COMMUNE_STORE_REQUEST:
      return {
        ...state,
        GetingCommunestore: "0",
        dataCommuneStore: [],
      };
    case commonConstants.GET_COMMUNE_STORE_SUCCESS:
      return {
        ...state,
        GetingCommunestore: "1",
        dataCommuneStore: action.payload,
      };
    case commonConstants.GET_COMMUNE_STORE_FAILURE:
      return {
        ...state,
        GetingCommunestore: "2",
        dataCommuneStore: action.payload,
      };
    case commonConstants.GET_INFO_REQUEST:
      return {
        ...state,
        GetingInfomaystro: "0",
        dataMaystro: [],
      };
    case commonConstants.GET_INFO_SUCCESS:
      return {
        ...state,
        GetingInfomaystro: "1",
        dataMaystro: action.payload,
      };
    case commonConstants.GET_INFO_FAILURE:
      return {
        ...state,
        GetingInfomaystro: "2",
        dataMaystro: action.payload,
      };
    case "CLEAR_COMMON":
      return {};
    default:
      return { ...state };
  }
}
