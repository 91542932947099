import axios from "axios";
import Cookies from "js-cookie";

const token = Cookies.get("auth");
const URL_API = process.env.REACT_APP_STATE !== "prod" ? "https://django-dev-nyg42cuu7q-ew.a.run.app/api/" : "https://backend.maystro-delivery.com/api/";

export const getStoreInfo = async () => {
  let response = [];
  let error = [];
  let hasBeenFetched = false;
  try {
    response = await axios.get(`${URL_API}stores/profile/`, {
      headers: { Authorization: `token ${Cookies.get("auth")}` },
    });
    hasBeenFetched = true;
  } catch (e) {
    error = e;
  }
  return { response, error, hasBeenFetched };
};
