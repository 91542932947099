import React, { useEffect, useState } from "react";
import Button from "../../../_Common/Button";
import { Translate } from "../../../../utils/lang/translate";
import { DataShop, UpdateDataShopSettings } from "../../../../types";
import { SHOP_DOMAIN_URL } from "../../../../constants";
import TableLoader from "../../_Common/TableLoader";
import { Event } from "../../_Common";
import ConfirmationAlert from "../../../_Common/ConfirmationAlert";

interface Props {
  dataShop: DataShop;
  isLoading: boolean;
  history: any;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
}

const FinishSettingShop: React.FC<Props> = ({
  dataShop,
  isLoading,
  history,
  UpdateDataShop,
}) => {
  const [showPublicationAlert, setShowPublicationAlert] = useState(false);

  useEffect(() => {
    if (dataShop.published) history.push("/settings/shop/success");
  }, [dataShop]);

  return isLoading || !dataShop ? (
    <div className="RlPs" style={{ paddingTop: "60px" }}>
      <TableLoader />
    </div>
  ) : (
    <div className="FinishSettingShop">
      {showPublicationAlert && (
        <ConfirmationAlert
          title={Translate("shop", "publishWebsite ")}
          content={
            <p className="stfSize">{Translate("shop", "publishingMessage")}</p>
          }
          onConfirm={() => {
            Event("SHOP", "SHOP_SUBMIT_YES", "CLICK_EVENT");
            UpdateDataShop(dataShop, { published: true });
          }}
          onClose={() => {
            Event("SHOP", "SHOP_SUBMIT_NO", "CLICK_EVENT");
            setShowPublicationAlert(false);
          }}
          customConfirmText={Translate("shop", "confirm")}
          customCloseText={Translate("shop", "cancel")}
        />
      )}
      <div className="main">
        <img src={dataShop.logo} alt="Store logo" />
        <div>
          <SubElement title={Translate("shop", "name")} value={dataShop.name} />
          <SubElement
            title={Translate("shop", "websiteTitleFinish")}
            value={dataShop.website_title}
          />
          {dataShop.store_categories?.length > 0 && (
            <SubElement
              title={Translate("shop", "categoryFinish")}
              value={dataShop.store_categories.join(", ")}
            />
          )}
          <SubElement
            title={Translate("shop", "theme")}
            value={dataShop.theme_template}
          />
          <SubElement
            title={Translate("shop", "domainFinish")}
            value={
              dataShop.domain
                ? dataShop.domain
                : `${dataShop.subdomain_slug}.${SHOP_DOMAIN_URL}`
            }
          />
          {dataShop.pixel_key && (
            <SubElement
              title={Translate("shop", "facebookPixel")}
              value={dataShop.pixel_key}
            />
          )}
        </div>
      </div>
      <Button
        BtnText={Translate("shop", "publish")}
        onClick={() => {
          Event("SHOP", "SHOP_SUBMIT_BUTTON", "CLICK_EVENT");
          setShowPublicationAlert(true);
        }}
        clName="publish"
      />
    </div>
  );
};

interface SubElementProps {
  title: string;
  value: string;
}

const SubElement: React.FC<SubElementProps> = ({ title, value }) => {
  return (
    <div className="SubElement">
      <p className="title">{title}</p>
      <p className="value">{value}</p>
    </div>
  );
};

export default FinishSettingShop;
