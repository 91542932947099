import React from "react";
import PropTypes from "prop-types";
import TabShow from "../_Common/TabShow";
import { Translate } from "../../../utils/lang/translate";
import { CloseSvg } from "../_Common/IconSvg";
import BtnLg from "../_Common/BtnLg";

function NewWilayasModal(props) {
  return (
    <TabShow NotCls style={{ minWidth: "80vw" }}>
      <div
        className="FlWd Inlflx AlgnItm StDrc spcBtwn"
        style={{ marginBottom: "3rem" }}
      >
        <div className="StdirLn">
          <h3 className="DlMg">{Translate("titles", "newwilayastitle")}</h3>
        </div>

        <div
          className="CrsPoi"
          onClick={props.onClose}
        >
          {CloseSvg}
        </div>
      </div>
      <div className="SmMarS FlWd Inlflx AlgnItm JstfCnt">
        <img src={props.banner || "/assets/img/new_wilayas.png"} alt="" />
      </div>
      <div
        className="SmMarS FlWd Inlflx AlgnItm JstfCnt CrsPoi"
        onClick={props.onClose}
      >
        <BtnLg
          style={{
            minHeight: "40px",
            minWidth: "10vw",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          text={Translate("titles", "done")}
        />
      </div>
    </TabShow>
  );
}

NewWilayasModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  banner: PropTypes.string,
};

export default NewWilayasModal;
