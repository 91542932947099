import React from 'react'
export default function ElmentStatus(props){
    return(
        <div className="ClSidTh StBrdRd pdnVldas FlHg StBrdTb InFlx AlgnItm FlWd sTbxSz StBgbrds">
            <div className="RlPs Inlflx FlWd spctwB FlHg AlgnItm">
                <div className="FlHg InFlx Stclmnf spctwB">
                    <span className="DsBlc StSmlS StOpcVal">{props.dataText}</span>
                    <strong style={{marginTop:"5px"}} className="DsBlc SetBigSiz">{props.dataNumber}</strong>
                </div>
                <div className="InFlx">
                    <div style={{padding:"1px"}} className={"StClSvgD StBrdRd status_"+props.status}>{props.SvgStatus}</div>
                </div>
            </div>
        </div>
    )
}