import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Translate } from "../../../../utils/lang/translate";
import GroupeBtnSp from "../../_Common/GroupeBtnSp";
import { DeletSvg, EditSvg, FlechSvg, CallSvg } from "../../_Common/IconSvg";
import InlinedElement from "../../_Common/InlinedElement";
import Button from "../../../_Common/Button";
import GroupeForm from "../../../_Common/GroupeForm";
import { userActions, alertActions } from "../../../../actions";
import Loader from "../../../_Common/Loader";
import { ViewteamLoder } from "./TeamLoader";
import { Link } from "react-router-dom";
import { initGAPg, Event } from "../../_Common";
class ViewTeam extends Component {
  constructor(props) {
    super(props);
    this.state = {
      phone_invit: "",
      idTeam: this.props.match.params.idteam,
    };
    this.handleChange = this.handleChange.bind(this);
    this.invitMember = this.invitMember.bind(this);
  }
  componentDidMount() {
    initGAPg();
    this.props.ListMemberTeam(this.state.idTeam);
  }
  componentWillReceiveProps(nextProps) {
    const { DeletingTeam, InvitingMember } = nextProps;
    if (this.props.DeletingTeam === "0" && DeletingTeam === "1") {
      this.props.history.push("/settings/team/home");
    }
    if (InvitingMember === "1" && this.props.InvitingMember === "0") {
      this.props.SendAlert("41", Translate("alert", "invsend"));
      this.props.ListMemberTeam(this.state.idTeam);
    }
    if (InvitingMember === "2" && this.props.InvitingMember === "0") {
      this.props.SendAlert("50", Translate("alert", "failinv"));
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }
  invitMember(e) {
    e.preventDefault();
    let inv = { team: this.state.idTeam, phone: this.state.phone_invit };
    this.props.InvitMember(inv);
  }
  GotToEdit(path) {
    Event("TEAM_DETAIL", "USE_EDIT_TEAM_BUTTON", "CLICK_EVENT");
    this.props.history.push(path);
  }
  DeletTeam(idTeam) {
    Event("TEAM_DETAIL", "DELET_TEAM_MEMBER", "CLICK_EVENT");
    this.props.DeleteTeam(idTeam);
  }
  /*DeletMember(idMember){
        Event("TEAM_DETAIL", "USE_DELET_MEMBER_BUTTON","CLICK_EVENT")
    }*/
  render() {
    const { InvitingMember, GetingDTeam, dataDListTeam, DeletingTeam } = this.props;
    return GetingDTeam === "0" ? (
      <ViewteamLoder />
    ) : (
      GetingDTeam === "1" && dataDListTeam && (
        <Fragment>
          {DeletingTeam === "0" && <Loader />}
          <div className="StAbs StLanNl DltTpMar2">
            <GroupeBtnSp
              data_btn={[
                { type: "BdgBlcl", action: () => this.GotToEdit("/settings/team/add/" + this.state.idTeam), svgbtn: EditSvg },
                { type: "TxDng", action: () => this.DeletTeam(this.state.idTeam), svgbtn: DeletSvg },
              ]}
            />
          </div>
          <div className="stbd2">
            <div className="InFlx flxDrc StDrcIn">
              <div className="StWdDst StPaddingVal CrsPoi RtVl">
                <Link to="/settings/team/home">{FlechSvg}</Link>
              </div>
              <div style={{ textTransform: "capitalize" }}>
                <h3 className="DlMg">{dataDListTeam.team.name}</h3>
              </div>
            </div>
          </div>
          <div className="InFlx flxDrc responsFlx Mrtpvl StFlxWrp2 RlPs">
            <div className="StWdpwrp StBrdRd SthgMaxC" style={{ zIndex: "5" }}>
              <div className="stbd2 TxtCn">
                <h3 className="DlMg">{Translate("settings", "teammembers")}</h3>
              </div>
              <div className="RlPs StDrcIn">
                {Array.isArray(dataDListTeam.members)
                  ? dataDListTeam.members.map((member, key) => {
                      return (
                        <div className="RlPs SmMarS1" key={key}>
                          <InlinedElement
                            first={
                              <div className="StWdTh1">
                                <img className="StWdTh1" alt="team member avatar" src={member.picture ? member.picture : "/assets/img/default_user.png"} />
                              </div>
                            }
                            secnd={
                              <div>
                                <h4 className="DlMg StdirLn TxTrnf">{member.full_name}</h4>
                                <span className="StdirLn DsBlc StOpcVal">{member.phone}</span>
                              </div>
                            }
                            style={{ width: "100%", padding: "0" }}
                          />
                        </div>
                      );
                    })
                  : ""}
                {Array.isArray(dataDListTeam.invitation)
                  ? dataDListTeam.invitation.map((invit, key) => {
                      return (
                        <div className="RlPs SmMarS1" key={key}>
                          <InlinedElement
                            first={
                              <div className="StWdTh1">
                                <img alt="team member profile" className="StWdTh1" src="/assets/img/user.png" />
                              </div>
                            }
                            secnd={
                              <div>
                                <h4 className="DlMg StdirLn TxTrnf">{invit.phone}</h4>
                                <span className="StdirLn DsBlc Bgstatus_11">{Translate("statusorder", "11")}</span>
                              </div>
                            }
                            style={{ width: "100%", padding: "0" }}
                          />
                        </div>
                      );
                    })
                  : ""}
              </div>
            </div>
            <div className="StAbs FlWd FlHg vsep" style={{ zIndex: "0" }}>
              <span className="DsBlc RlPs MrAot StOpcVal" style={{ width: "3px", height: "100%", backgroundColor: "var(--fntClr)" }}></span>
            </div>
            <div className="StWdpwrp StBrdRd SthgMaxC RlPs" style={{ zIndex: "5" }}>
              {InvitingMember === "0" && <Loader />}
              <div className="stbd2 TxtCn">
                <h3 className="DlMg">{Translate("settings", "invitemembers")}</h3>
              </div>
              <div className="MrAot" style={{ width: "90%" }}>
                <div className="InFlx FlWd TxtCn SmMarS2">
                  <div className="TxtCn RlPs CrsPoi StVlwd ActelmV" style={{ width: "100%" }}>
                    {CallSvg}
                    <div>{Translate("auths", "phone")}</div>
                  </div>
                </div>
                <form onSubmit={this.invitMember}>
                  <GroupeForm id={"phone_invit"} name={"phone_invit"} placeholder={Translate("auths", "phone")} type={"tel"} pattern={"^(((+216)?[1-9]d{7})|((0?|(00|+)?213)[56789]d{8}))$"} value={this.state.phone_invit} workfun={this.handleChange} />
                  <div className="MrAot StMarMx" style={{ width: "200px" }}>
                    <Button BtnText={Translate("settings", "invitemembers")} />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Fragment>
      )
    );
  }
}
function mapState(state) {
  const { GetingTeam, dataListTeam, GetingDTeam, dataDListTeam, DeletingTeam } = state.team;
  const { InvitingMember, dataInvitingMember } = state.memberTeam;
  return { GetingTeam, dataListTeam, InvitingMember, dataInvitingMember, GetingDTeam, dataDListTeam, DeletingTeam };
}

const actionCreators = {
  ListMemberTeam: userActions.ListMemberTeam,
  InvitMember: userActions.InvitMember,
  DeleteTeam: userActions.DeleteTeam,
  SendAlert: alertActions.SendAlert,
};

const connectedViewTeam = connect(mapState, actionCreators)(ViewTeam);
export { connectedViewTeam as ViewTeam };
