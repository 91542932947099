import React from "react";
import { DataShop } from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import {
  CheckSvg2,
  FacebookSvg,
  ShopSvg,
  ThemeSvg,
  WebSvg,
} from "../../_Common/IconSvg";
import NavigationBubble from "./NavigationBubble";
import { Event } from "../../_Common";

interface Props {
  dataShop: DataShop;
  history: any;
  location: any;
}

const NavigationComputer: React.FC<Props> = ({
  dataShop,
  history,
  location,
}) => {
  return (
    <>
      <div className="navigation responsive-computer">
        <NavigationBubble
          svg={ShopSvg}
          title={Translate("titles", "shopInfo")}
          isActive={location.pathname === "/settings/shop/store"}
          isDone={dataShop.settings_stage >= 1 && !dataShop.published}
          handleClick={() => {
            Event("SHOP", "BUBBLE_STORE", "CLICK_EVENT");
            history.push("/settings/shop/store");
          }}
          scale={1.3}
        />
        <div
          className={`line ${
            dataShop.settings_stage >= 1 && !dataShop.published ? "active" : ""
          }`}
        />
        <NavigationBubble
          svg={ThemeSvg}
          title={Translate("titles", "themeTemplate")}
          isActive={location.pathname === "/settings/shop/theme"}
          isDone={dataShop.settings_stage >= 2 && !dataShop.published}
          isDisabled={dataShop.settings_stage < 1}
          scale={0.9}
          handleClick={() => {
            Event("SHOP", "BUBBLE_THEME", "CLICK_EVENT");
            history.push("/settings/shop/theme");
          }}
        />
        <div
          className={`line ${
            dataShop.settings_stage >= 2 && !dataShop.published ? "active" : ""
          }`}
        />
        <NavigationBubble
          svg={WebSvg}
          title={Translate("titles", "domainSetup")}
          isActive={location.pathname === "/settings/shop/domain"}
          isDone={dataShop.settings_stage >= 3 && !dataShop.published}
          isDisabled={dataShop.settings_stage < 2}
          scale={0.95}
          handleClick={() => {
            Event("SHOP", "BUBBLE_DOMAIN", "CLICK_EVENT");
            history.push("/settings/shop/domain");
          }}
        />
        <div
          className={`line ${
            dataShop.settings_stage >= 3 && !dataShop.published ? "active" : ""
          }`}
        />
        <NavigationBubble
          svg={FacebookSvg}
          title={Translate("titles", "facebookPixel")}
          isActive={location.pathname === "/settings/shop/facebook"}
          isDone={dataShop.settings_stage >= 4 && !dataShop.published}
          isDisabled={dataShop.settings_stage < 3}
          scale={2}
          handleClick={() => {
            Event("SHOP", "BUBBLE_FACEBOOK", "CLICK_EVENT");
            history.push("/settings/shop/facebook");
          }}
        />
        {!dataShop.published && (
          <>
            <div
              className={`line ${
                dataShop.settings_stage >= 4 && !dataShop.published
                  ? "active"
                  : ""
              }`}
            />
            <NavigationBubble
              svg={CheckSvg2}
              title={Translate("titles", "finish")}
              isActive={location.pathname === "/settings/shop/finish"}
              isDisabled={dataShop.settings_stage < 4}
              scale={0.9}
              handleClick={() => {
                Event("SHOP", "BUBBLE_FINISH", "CLICK_EVENT");
                history.push("/settings/shop/finish");
              }}
            />
          </>
        )}
      </div>
      <div className="vertical-line responsive-computer" />
    </>
  );
};

export default NavigationComputer;
