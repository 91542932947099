import React, { useRef } from "react";
import { Editor } from "@tinymce/tinymce-react";
import { Translate } from "../../../utils/lang/translate";
import Cookies from "js-cookie";

interface Props {
  value: string;
  setValue: (content: string) => void;
  title?: string;
  id?: string;
  optional?: boolean;
}

const RichTextEditor = ({ value, setValue, title, id, optional }: Props) => {
  const editorRef = useRef<any>(null);

  const handleChange = () => {
    if (editorRef.current) {
      setValue(editorRef.current.getContent());
    }
  };

  const theme = Cookies.get("theme") == "0" ? "light" : "dark";

  return (
    <div className={`RichTextEditor ${theme}`}>
      <label htmlFor={id ? id : ""}>
        {title}
        {optional && <span> ({Translate("shop", "optional")})</span>}
      </label>
      <Editor
        onInit={(evt, editor) => (editorRef.current = editor)}
        initialValue={value}
        onChange={handleChange}
        id={id ? id : ""}
        init={{
          height: 300,
          menubar: false,
          plugins: [
            "a11ychecker",
            "advlist",
            "advcode",
            "advtable",
            "autolink",
            "checklist",
            "export",
            "lists",
            "link",
            "image",
            "charmap",
            "preview",
            "anchor",
            "searchreplace",
            "visualblocks",
            "powerpaste",
            "fullscreen",
            "formatpainter",
            "insertdatetime",
            "media",
            "table",
            "help",
            "wordcount",
          ],
          toolbar:
            "undo redo | casechange blocks | bold italic backcolor | " +
            "alignleft aligncenter alignright alignjustify | " +
            "bullist numlist checklist outdent indent | link image code | removeformat | a11ycheck code table help",
          content_style:
            "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
          skin: theme === "light" ? "oxide" : "oxide-dark",
          content_css: theme,
        }}
      />
    </div>
  );
};

export default RichTextEditor;
