import React, { Component } from "react";
import { connect } from "react-redux";
import { Route, Switch, Redirect } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { initGAPg, Event } from "./_Common";
import { SideBar } from "./SideBar";
import { NavBar } from "./NavBar";
import {
  ProductsPage,
  UnlinkProduct,
  ProductDetailPage,
  ReceiveProduct,
  TakenProduct,
  DetailsReceive,
  DetailsExit,
} from "./ProductsPage";
import { SettingsPage } from "./SettingsPage";
import { PaymentsPage } from "./PayementsPage";
import { DashboardPage } from "./DashboardPage";
import { IssuesPage } from "./IssuesPage";
import { NotificationPage } from "./Notifications";
import {
  OrdersDetailPage as ConfirmationOrderDetailPage,
  ConfirmationPage,
} from "./ConfirmationPage";
import { ShopPage, ShopProductForm, BundleForm, BundlePage } from "./ShopPage";
import { userActions, alertActions, notificationsActions } from "../../actions";
import Loader from "../_Common/Loader";
import { UnfulfilledOrderPage } from "./UnfulfilledOrderPage";
import { SearchPage } from "./SearchPage";
import {
  AddOrderPage,
  OrdersDetailPage,
  OrdersPage,
  ExchangePage,
  RefundPage,
  ImportOrderPage,
} from "./OrdersPage";
import { AddPickupPage, PickupDetailPage, PickupsPage } from "./PickupPage";
import {
  AddStockPickupPage,
  StockPickupDetailPage,
  StockPickupsPage,
} from "./StockPickupPage";
import { AlertPage } from "./AlertPage";
import { PDFgenrator } from "./_Common";
import { Translate } from "../../utils/lang/translate";
import { ListenerFile } from "./ListenerEvent";
import { CloseSvg } from "./_Common/IconSvg";
import { BackupPage } from "./BackupPage/BackupPage";
import NewFeaturesModal from "./_Custom/NewFeaturesModal";
import { NEW_FEATURES, PROMOTION } from "../../utils/new-features";
import NewWilayasModal from "./_Custom/NewWilayasModal";
import PromotionModal from "./_Custom/PromotionModal";
import { date_difference } from "../../utils/functions";
import { is_winner_store } from "../../utils/canaries";
import CongratulationModal from "./_Custom/CongratulationModal";
import NewsStatusesWorkFlow from "./_Custom/workflows/NewsStatusesWorkFlow";
import { is_exclusive_test_store } from "../helpers/functions";
import SecurityUpdateModal from "./_Custom/SecurityUpdateModal";
import { Footer } from "./Footer/Footer";
import MainPage from "./RubricPages/MainPage";
import { ContactUs } from "./_Custom/ContactUs";
import { checkUserHasShopPermissionById } from "../../utils/helpers";
import Cookies from "js-cookie";
const lang = Cookies.get("lang");

class IndexStore extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showNewWilayasAnnoun: false,
      showNewWilayasPopup: false,
      showNewFeatAnnoun: true,
      showNewFeaturePopup: false,
      showPromotionAnnoun: true,
      showPromotionPopup: false,
      // showCongratsPopup: this.props.dtStoreinfo && is_winner_store(this.props.dtStoreinfo.id) ? true : false,
      showCongratsPopup: false,
      showSecurityUpdatePopup: false,
    };

    if (this.props.GetingUser !== "0" || this.props.GetingUser !== "2") {
      this.props.GetProfileUser();
    }
  }
  componentDidMount() {
    var inputs = document.getElementsByTagName("input");
    for (var i = 0; i < inputs.length; i++)
      inputs[i].removeAttribute("autocomplete");

    initGAPg();

    this.props.GetAnnouncement();

    let distance = date_difference(new Date(PROMOTION.enddate), new Date());

    if (this.state.showPromotionAnnoun) {
      if (distance.difference > 0) {
        let chron = setInterval(() => {
          let remaining = date_difference(
            new Date(PROMOTION.enddate),
            new Date()
          );
          if (remaining.difference <= 0) {
            clearInterval(chron);
          } else
            this.setState({
              remaining: `${remaining.days} :${remaining.hours}: ${remaining.minutes}: ${remaining.seconds}`,
            });
        }, 1000);
      } else {
        if (distance.days > -7) {
          this.setState({ remaining: null });
        } else {
          this.setState({ showPromotionAnnoun: false });
        }
      }
    }
    // this.setState({showSecurityUpdatePopup: localStorage.getItem("sec_chk")?false:true})
  }

  clickNewWilayasHandler() {
    this.setState({ showNewWilayasPopup: true });
    Event("ANNOUCEMENT", "OPEN_NEW_WILAYAS_POPUP", "CLICK_EVENT");
  }
  clickNeFeaturesHandler() {
    this.setState({ showNewFeaturePopup: true });
    Event("ANNOUCEMENT", "OPEN_NEW_FEATURES_POPUP", "CLICK_EVENT");
  }
  clickNewPromotionHandler() {
    this.setState({ showPromotionPopup: true });
    Event("ANNOUCEMENT", "OPEN_NEW_PROMOTION_POPUP", "CLICK_EVENT");
  }
  closeNewWilayasHandler() {
    this.setState({ showNewWilayasPopup: false });
    Event("ANNOUCEMENT", "CLOSE_NEW_WILAYAS_POPUP", "CLICK_EVENT");
  }
  closeNeFeaturesHandler() {
    this.setState({ showNewFeaturePopup: false });
    Event("ANNOUCEMENT", "CLOSE_NEW_FEATURES_POPUP", "CLICK_EVENT");
  }
  closeMessageAlert() {
    this.setState({ showNewFeatAnnoun: false });
  }
  closeNewPromotionHandler() {
    this.setState({ showPromotionPopup: false });
    Event("ANNOUCEMENT", "CLOSE_NEW_PROMOTION_POPUP", "CLICK_EVENT");
  }
  closeCongratsHandler() {
    this.setState({ showCongratsPopup: false });
    Event("ANNOUCEMENT", "CLOSE_CONGRATS_POPUP", "CLICK_EVENT");
  }
  closeSecurityUpdateHandler() {
    this.setState({ showSecurityUpdatePopup: false });
    Event(
      "SECURITY_UPDATE",
      "CLOSE_SECCHECK_POPUP",
      `${this.props.dtStoreinfo.name}`
    );
  }

  render() {
    const { dtStoreinfo, dataProfile, GetingUser, gtStoreInf } = this.props;
    if (GetingUser === "2") {
      this.props.SendAlert("50", Translate("alert", "failLdus"));
    }
    let features = [];
    if (dtStoreinfo && is_exclusive_test_store(dtStoreinfo))
      features.push(
        !dtStoreinfo.stock_managed ? NEW_FEATURES[3] : NEW_FEATURES[2]
      );

    return (
      <div className="FxPs FlWd FlHg BdgGc">
        {this.state.showNewWilayasAnnoun && (
          <div
            id="tab_wilayas_inform"
            className="InFlx flxDrc Tstatus_31 JstfCnt TxtCn CrsPoi"
            style={{ padding: "10px 5px" }}
            onClick={() => this.clickNewWilayasHandler()}
          >
            <div
              dangerouslySetInnerHTML={{
                __html: Translate("statusorder", "textadd2"),
              }}
            />
          </div>
        )}
        {this.state.showNewWilayasPopup && (
          <NewWilayasModal onClose={() => this.closeNewWilayasHandler()} />
        )}

        {/* New features */}
        {/* {this.state.showNewFeatAnnoun && dtStoreinfo && ((dtStoreinfo.is_canary && dtStoreinfo.is_canary.toString()==="true") ||  dtStoreinfo.stock_managed) && */}

        {this.props.Gettingmessage == 2 &&
          (this.props.dataAnnouncement?.country === dtStoreinfo?.country?.id ? (
            <div>
              {features.length > 0 && this.state.showNewFeatAnnoun && (
                <div
                  className="InFlx flxDrc Tstatus_31"
                  style={{ padding: "10px 0px" }}
                >
                  <div
                    className="InFlx flxDrc JstfCnt"
                    style={{ width: "98%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <b
                        className="status_41"
                        style={{
                          padding: "5px",
                          borderRadius: "10%",
                          height: "25px",
                          marginRight: "10px",
                        }}
                      >
                        {Translate("titles", "new")}
                      </b>

                      {lang == "en" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.en_content,
                          }}
                        ></span>
                      ) : lang == "fr" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.fr_content,
                          }}
                        ></span>
                      ) : lang == "ar" ? (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.ar_content,
                          }}
                        ></span>
                      ) : (
                        <span
                          dangerouslySetInnerHTML={{
                            __html: this.props.dataAnnouncement?.en_content,
                          }}
                        ></span>
                      )}
                    </div>
                  </div>
                  <div
                    className="InFlx AlgnItm JstfCnt CrsPoi"
                    style={{ width: "2%", zoom: 0.7 }}
                    onClick={() => this.closeMessageAlert()}
                  >
                    {CloseSvg}
                  </div>
                </div>
              )}{" "}
            </div>
          ) : (
            ""
          ))}

        {this.state.showNewFeaturePopup && (
          <NewFeaturesModal
            features={features}
            children={
              <NewsStatusesWorkFlow starter={!dtStoreinfo.stock_managed} />
            }
            onClose={() => this.closeNeFeaturesHandler()}
          />
        )}
        {/* Promotion */}
        {this.state.showPromotionAnnoun && (
          <div
            className="InFlx flxDrc Tstatus_50"
            style={{ padding: "10px 10px" }}
          >
            <div className="FlWd InFlx flxDrc JstfCnt">
              {this.state.remaining !== null && (
                <div>
                  <b
                    className="status_11"
                    style={{ padding: "5px", borderRadius: "10%" }}
                  >
                    {Translate("titles", "flash")}
                  </b>
                  <span>
                    {" "}
                    {Translate("announcements", "promotion-inttext")}
                  </span>
                  <b className="sTmrGlf"> {this.state.remaining} </b>
                  <b
                    className="CrsPoi stUndrln"
                    onClick={() => this.clickNewPromotionHandler()}
                  >
                    {" "}
                    {Translate("announcements", "seedetails")}{" "}
                  </b>
                </div>
              )}
              {this.state.remaining === null && (
                <div>
                  <b
                    className="status_51"
                    style={{ padding: "5px", borderRadius: "10%" }}
                  >
                    {Translate("announcements", "expired")}
                  </b>
                  <span> {Translate("announcements", "promotionended")}</span>
                  <b
                    className="CrsPoi stUndrln"
                    onClick={() => this.clickNewPromotionHandler()}
                  >
                    {" "}
                    {Translate("announcements", "seedetails")}{" "}
                  </b>
                </div>
              )}
            </div>
          </div>
        )}
        {this.state.showPromotionPopup && (
          <PromotionModal onClose={() => this.closeNewPromotionHandler()} />
        )}
        {/* Winners Congratulations */}
        {this.state.showCongratsPopup && (
          <CongratulationModal
            onClose={() => this.closeCongratsHandler()}
            winnerName={dtStoreinfo.name}
            congratsContent={
              <p className="StOpcVal">
                {Translate("announcements", "congratstext")}
              </p>
            }
          />
        )}
        {/* Security Updates popup */}
        {GetingUser === "1" &&
          dtStoreinfo &&
          dataProfile &&
          dataProfile.is_store &&
          this.state.showSecurityUpdatePopup && (
            <SecurityUpdateModal
              onClose={() => this.closeSecurityUpdateHandler()}
              email={dataProfile.email}
              phone={dataProfile.phone}
              user={dataProfile}
            />
          )}
        <div
          className="InFlx flxDrc FlHg"
          style={{ height: "calc(100% - 41px)" }}
        >
          <SideBar pathname={window.location.pathname} />
          <div className="FlHg StWdsc">
            <NavBar {...this.props} />
            {GetingUser === "0" || gtStoreInf === "0" ? (
              <div
                id="Container-root"
                className="StSlyT RlPs StTrns lytWdp OvfAtoY HdOvFlwX"
              >
                <Loader />
              </div>
            ) : (
              GetingUser === "1" &&
              dataProfile &&
              dtStoreinfo && (
                <Switch>
                  <Route path="/dashboard" component={DashboardPage} exact />
                  <Route
                    path="/order/add/:idorder"
                    component={AddOrderPage}
                    exact
                  />
                  <Route
                    path="/order/detail/:idorder"
                    component={OrdersDetailPage}
                    exact
                  />
                  <Route
                    path="/order/exchange/:idorder"
                    component={ExchangePage}
                    exact
                  />
                  <Route
                    path="/order/reimbursement/:idorder"
                    component={RefundPage}
                    exact
                  />
                  <Route
                    path="/order/import"
                    component={ImportOrderPage}
                    exact
                  />
                  <Route path="/orders" component={OrdersPage} exact />
                  {dataProfile.intgraed &&
                    (dataProfile.is_store || dataProfile.view_order) && (
                      <Route
                        path="/unfulfilled/order"
                        component={UnfulfilledOrderPage}
                        exact
                      />
                    )}
                  {dtStoreinfo.stock_managed && (
                    <Route
                      path="/products/detail/:idprod"
                      component={ProductDetailPage}
                      exact
                    />
                  )}
                  <Route path="/stock/products" component={ProductsPage} />
                  <Route
                    path="/unlinked/products"
                    component={UnlinkProduct}
                    exact
                  />
                  <Route path="/payments" component={PaymentsPage} />
                  {(dataProfile.is_store || dataProfile.add_pickup) &&
                    !dtStoreinfo.stock_managed && (
                      <Route
                        path="/pickups/add/:idpickup"
                        component={AddPickupPage}
                        exact
                      />
                    )}
                  {(dataProfile.is_store || dataProfile.add_pickup) &&
                    dtStoreinfo.stock_managed && (
                      <Route
                        path="/pickups/add/:idpickup"
                        component={AddStockPickupPage}
                        exact
                      />
                    )}
                  <Route
                    path="/stock/receiveProduct"
                    component={ReceiveProduct}
                    exact
                  />
                  <Route
                    path="/stock/takenProduct"
                    component={TakenProduct}
                    exact
                  />
                  <Route
                    path="/stock/detail/:id"
                    component={DetailsReceive}
                    exact
                  />
                  <Route
                    path="/stock/detailTaken/:id"
                    component={DetailsExit}
                    exact
                  />
                  {(dataProfile.is_store || dataProfile.view_pickup) &&
                    !dtStoreinfo.stock_managed && (
                      <Route
                        path="/pickups/detail/:idpickup"
                        component={PickupDetailPage}
                        exact
                      />
                    )}
                  {(dataProfile.is_store || dataProfile.view_pickup) &&
                    dtStoreinfo.stock_managed && (
                      <Route
                        path="/pickups/detail/:idpickup"
                        component={StockPickupDetailPage}
                        exact
                      />
                    )}
                  {(dataProfile.is_store || dataProfile.view_pickup) &&
                    !dtStoreinfo.stock_managed && (
                      <Route path="/pickups" component={PickupsPage} exact />
                    )}
                  {(dataProfile.is_store || dataProfile.view_pickup) &&
                    dtStoreinfo.stock_managed && (
                      <Route
                        path="/pickups"
                        component={StockPickupsPage}
                        exact
                      />
                    )}
                  {/* {(dtStoreinfo.is_gold ) && <Route path="/confirmation/import" component={ImportConfirmOrderPage} />} */}
                  {dtStoreinfo.is_gold && (
                    <Route
                      path="/confirmation/detail/:idorder"
                      component={ConfirmationOrderDetailPage}
                      exact
                    />
                  )}
                  {dtStoreinfo.is_gold && (
                    <Route
                      path="/confirmation"
                      component={ConfirmationPage}
                      exact
                    />
                  )}
                  {dtStoreinfo?.id &&
                    checkUserHasShopPermissionById(dtStoreinfo.id) && (
                      <Route path="/shop" component={BundlePage} exact />
                    )}
                  {dtStoreinfo?.id &&
                    checkUserHasShopPermissionById(dtStoreinfo.id) && (
                      <Route
                        path="/shop/bundle/add"
                        component={BundleForm}
                        exact
                      />
                    )}
                  {dtStoreinfo?.id &&
                    checkUserHasShopPermissionById(dtStoreinfo.id) && (
                      <Route
                        path="/shop/bundle/:bundleDisplayId"
                        component={BundleForm}
                        exact
                      />
                    )}
                  {dtStoreinfo?.id &&
                    checkUserHasShopPermissionById(dtStoreinfo.id) && (
                      <Route path="/shop/bundle" component={BundlePage} exact />
                    )}
                  {dtStoreinfo?.id &&
                    checkUserHasShopPermissionById(dtStoreinfo.id) && (
                      <Route
                        path="/shop/product/:idprod"
                        component={ShopProductForm}
                        exact
                      />
                    )}
                  <Route path="/settings" component={SettingsPage} />
                  <Route path="/backup" component={BackupPage} exact />
                  <Route path="/issues" component={IssuesPage} exact />
                  <Route
                    path="/notifications"
                    component={NotificationPage}
                    exact
                  />
                  <Route path="/search" component={SearchPage} exact />
                  <Route
                    path="/print/facture/:operation"
                    component={PDFgenrator}
                    exact
                  />
                  <Route path="/contactus" component={ContactUs} exact />
                  <Route path="/rubric" component={MainPage} exact />
                  <Redirect from="*" to="/dashboard" />
                </Switch>
              )
            )}
            <Footer />
            <AlertPage />
            <ListenerFile />
          </div>
        </div>
      </div>
    );
  }
}
function mapState(state) {
  const { GetingUser, gtStoreInf, dtStoreinfo, dataProfile } = state.user;
  const { Gettingmessage, dataAnnouncement } = state.alertReducer;

  return {
    GetingUser,
    gtStoreInf,
    dtStoreinfo,
    dataProfile,
    Gettingmessage,
    dataAnnouncement,
  };
}

const actionCreators = {
  GetProfileUser: userActions.GetProfileUser,
  SendAlert: alertActions.SendAlert,
  GetAnnouncement: notificationsActions.GetAnnouncement,
};

const connectedIndexStore = connect(mapState, actionCreators)(IndexStore);
export { connectedIndexStore as IndexStore };
