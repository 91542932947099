import React, { useEffect, useState } from "react";
import { Link, Prompt } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
import useExitPrompt from "../../../utils/useExitPrompt";
import Button from "../../_Common/Button";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import ContainerPage from "../_Common/ContainerPage";
import {
  ProdBundle,
  DataShop,
  ProductShop,
  UpdateDataShopSettings,
  Bundle,
} from "../../../types";
import { userActions, alertActions } from "../../../actions";
import { connect } from "react-redux";
import { initGAPg } from "../_Common";
import GroupeForm from "../../_Common/GroupeForm";
import { DeletSvg, LoadingSmallSvg } from "../_Common/IconSvg";
import TableLoader from "../_Common/TableLoader";
import { shopActions } from "../../../actions";
import { Event } from "../_Common";

const initialProductBundle: ProdBundle = {
  id: "",
  product: {
    id: "",
    title: "",
    display_id: "",
  },
  display_id: "",
  quantity: 1,
};

interface Props {
  history: any;
  match: any;
  GetShopInfo: () => void;
  dataShop: DataShop;
  shopLoading: boolean;
  CreateBundle: (
    newBundle: Bundle,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  ModifyBundle: (
    bundleToModify: Bundle,
    dataShop: DataShop,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const BundleForm: React.FC<Props> = ({
  GetShopInfo,
  dataShop,
  shopLoading,
  history,
  match,
  CreateBundle,
  ModifyBundle,
  SendAlert,
}) => {
  const { bundleDisplayId } = match.params;
  const [isLoading, setIsLoading] = useState(true);
  const [isChange, setIsChange] = useExitPrompt(false);
  const [productsShop, setProductsShop] = useState<ProductShop[]>([]);
  const [selectedProducts, setSelectedProducts] = useState<ProdBundle[]>([
    initialProductBundle,
    initialProductBundle,
  ]);
  const [ids, setIds] = useState({ display_id: "", id: "" });
  const [areFound, setAreFound] = useState<boolean[]>([false, false]);
  const [price, setPrice] = useState(400);
  const [reducedPrice, setReducedPrice] = useState<number | null>(null);
  const [isModify, setIsModify] = useState(false);

  useEffect(() => {
    document.title = "Maystro Delivery - " + Translate("titles", "addBundle");
    initGAPg();
    GetShopInfo();
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (
      !shopLoading &&
      dataShop &&
      Array.isArray(dataShop.products) &&
      Array.isArray(dataShop.shop_bundles)
    ) {
      if (!dataShop.published) history.push("/settings/shop/store");
      const { products, shop_bundles } = dataShop;

      const theBundle = shop_bundles.find(
        (bundle) => bundle.display_id.toString() === bundleDisplayId
      );

      if (!theBundle) {
        const filteredProducts = products.filter(
          (prod) =>
            !shop_bundles.some((bundle) =>
              bundle.product_bundle.some(
                (bundleProd) => bundleProd.product.id === prod.id
              )
            )
        );
        setProductsShop(filteredProducts);
      } else {
        const filteredProducts = products.filter(
          (prod) =>
            // should return true to select the product
            !shop_bundles.some(
              (bundle) =>
                // should all return false to select the product
                bundle.display_id !== bundleDisplayId && // product is found in theBundle
                bundle.product_bundle.some(
                  (bundleProd) => bundleProd.product.id === prod.id
                ) // product is found in another bundle
            )
        );
        setProductsShop(filteredProducts);
        setSelectedProducts([...theBundle.product_bundle]);
        setPrice(theBundle.price);
        setReducedPrice(theBundle.reduced_price);
        setIds({
          display_id: theBundle.display_id,
          id: theBundle.id,
        });
        setAreFound(theBundle.product_bundle.map((prod) => true));
        setIsModify(true);
      }

      setIsLoading(false);
    }
  }, [dataShop]);

  const AddFieldProduct = () => {
    setSelectedProducts([...selectedProducts, initialProductBundle]);
    setAreFound([...areFound, false]);
    setIsChange(true);
  };

  const RemoveFieldProduct = (key: number) => {
    setSelectedProducts(selectedProducts.filter((prod, k) => k !== key));
    setAreFound(areFound.filter((isFound, k) => k !== key));
    setIsChange(true);
  };

  const handleChangeProduct = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { name, value } = e.target as HTMLInputElement;
    const tabn = name.split("_");
    const index = parseInt(tabn[1]);
    if (tabn[0] === "title") {
      const selectedProd = productsShop.find((prod) => prod.title === value);
      if (selectedProd) {
        const updatedSelectedProducts = selectedProducts.map((prod, key) => {
          if (key !== index) return prod;
          const newProd: ProdBundle = {
            ...prod,
            product: {
              id: selectedProd.id,
              display_id: selectedProd.display_id,
              title: selectedProd.title,
            },
          };
          return newProd;
        });
        setSelectedProducts(updatedSelectedProducts);
        setAreFound(
          areFound.map((isFound, key) => (key !== index ? isFound : true))
        );
      } else {
        const updatedSelectedProducts = selectedProducts.map((prod, key) => {
          if (key !== index) return prod;
          const newProd: ProdBundle = {
            ...initialProductBundle,
            product: { ...initialProductBundle.product, title: value },
          };
          return newProd;
        });
        setSelectedProducts(updatedSelectedProducts);
        setAreFound(
          areFound.map((isFound, key) => (key !== index ? isFound : false))
        );
      }
    } else {
      const updatedSelectedProducts = selectedProducts.map((prod, key) => {
        if (key !== index) return prod;
        return {
          ...prod,
          quantity: parseInt(value),
        };
      });
      setSelectedProducts(updatedSelectedProducts);
    }
    setIsChange(true);
  };

  const handlePriceChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = e.target as HTMLInputElement;
    setPrice(parseInt(value));
    setIsChange(true);
  };

  const handleReducedPriceChange = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    const { value } = e.target as HTMLInputElement;
    setReducedPrice(parseInt(value));
    setIsChange(true);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const bundleToSubmit: Bundle = {
      product_bundle: selectedProducts,
      price: price,
      reduced_price: reducedPrice,
      ...ids,
    };

    if (!isModify) {
      Event("SHOP", "CREATE_BUNDLE", "CLICK_EVENT");
      CreateBundle(bundleToSubmit, dataShop, () => {
        setIsChange(false);
        history.push("/shop/bundle");
      });
    } else {
      Event("SHOP", "EDIT_BUNDLE", "CLICK_EVENT");
      ModifyBundle(bundleToSubmit, dataShop, () => {
        setIsChange(false);
        SendAlert("41", Translate("shop", "changesSaved"));
      });
    }
  };

  return (
    <ContainerPage
      page_title={
        <div>
          {/* <Link className="StOpcVal" to="/shop">
            {Translate("titles", "shop")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div> */}
          <Link className="StOpcVal" to="/shop/bundle">
            {Translate("titles", "bundles")}
          </Link>
          <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
          <span className="CrsPoi">
            {!isModify
              ? Translate("shop", "addBundle")
              : Translate("shop", "editBundle")}
          </span>
        </div>
      }
    >
      {isLoading ? (
        <div className="RlPs" style={{ paddingTop: "60px" }}>
          <TableLoader />
        </div>
      ) : (
        <div className="ProductBundle">
          <Prompt when={isChange} message={Translate("alert", "prompt")} />
          <div className="container ClSidTh StBrdTb StBgbrds widthAdd">
            <form method="post" onSubmit={handleSubmit} autoComplete="off">
              <ContainerPage
                subContainer
                page_title={Translate("shop", "bundleProducts")}
                withOvrl
              >
                {Array.isArray(selectedProducts) &&
                  selectedProducts.map((selectedProduct, key) => {
                    return (
                      <div
                        className="FlWd InFlx responsFlx flxDrc BundleProduct"
                        key={key}
                      >
                        <div className="Hlwd">
                          <SelectFormSearch
                            id={"title_" + key}
                            name={"title_" + key}
                            index={"title"}
                            option={productsShop.filter(
                              (prod) =>
                                prod.published &&
                                !selectedProducts.some((selectedProd) => {
                                  const prodIsSelectedInThisObject =
                                    selectedProduct.product.id ===
                                    selectedProd.product.id;

                                  const prodIsSelectedInAnotherObject =
                                    prod.id === selectedProd.product.id;

                                  if (prodIsSelectedInThisObject) return false;
                                  if (prodIsSelectedInAnotherObject)
                                    return true;
                                  return false;
                                })
                            )}
                            text={Translate("shop", "selectedProductText")}
                            workfun={handleChangeProduct}
                            placeholder={Translate(
                              "shop",
                              "selectedProductPlaceholder"
                            )}
                            value={selectedProduct.product.title}
                            required
                          />
                        </div>
                        <div className="Hlwd Mrg2 RlPs">
                          <div className="Flwd InFlx flxDrc AlgnItm">
                            <GroupeForm
                              id={"quantity_" + key}
                              name={"quantity_" + key}
                              placeholder={Translate("orders", "quantity")}
                              text={Translate("orders", "quantity")}
                              type={"number"}
                              value={selectedProduct.quantity}
                              min={1}
                              workfun={handleChangeProduct}
                              style={{ width: "100%" }}
                              required
                            />
                            {selectedProducts.length > 2 && (
                              <div
                                className="CrsPoi InFlx JstfCnt"
                                style={{
                                  alignItems: "flex-end",
                                  margin: "5% 0% 0% 2%",
                                }}
                                onClick={() => RemoveFieldProduct(key)}
                              >
                                {DeletSvg}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                {selectedProducts.length < 3 && (
                  <div className="FlWd StdirLn">
                    <span className="StBle CrsPoi" onClick={AddFieldProduct}>
                      +{" " + Translate("orders", "addanotherproduct")}
                    </span>
                  </div>
                )}
              </ContainerPage>
              <ContainerPage
                subContainer
                page_title={Translate("shop", "price")}
                withOvrl
              >
                <div className="FlWd InFlx responsFlx flxDrc">
                  <div className="Hlwd">
                    <GroupeForm
                      id={`price`}
                      name="price"
                      type="number"
                      text={Translate("shop", "price")}
                      placeholder={Translate("shop", "price")}
                      min={400}
                      value={price}
                      workfun={handlePriceChange}
                      required
                    />
                  </div>
                  <div className="Hlwd Mrg2 RlPs">
                    <GroupeForm
                      id="reduced_price"
                      name="reduced_price"
                      type="number"
                      text={Translate("shop", "reducedPrice")}
                      placeholder={Translate("shop", "reducedPrice")}
                      min={400}
                      value={reducedPrice}
                      workfun={handleReducedPriceChange}
                      optional
                    />
                  </div>
                </div>
              </ContainerPage>
              <div className="MrAot StMarMx responseDiv w-min-content">
                <Button
                  style={{
                    width: "max-content",
                    padding: "10px 20px",
                    minWidth: "190px",
                  }}
                  BtnText={
                    shopLoading
                      ? LoadingSmallSvg
                      : !isModify
                      ? Translate("shop", "createBundle")
                      : Translate("shop", "editBundle")
                  }
                  disabled={
                    !areFound.every((isFound) => isFound) ||
                    !selectedProducts.every((prod) => prod.quantity > 0) ||
                    !price ||
                    price < 400 ||
                    !isChange ||
                    shopLoading ||
                    (reducedPrice && price <= reducedPrice)
                  }
                />
              </div>
            </form>
          </div>
        </div>
      )}
    </ContainerPage>
  );
};

function mapState(state: any) {
  const { dataShop, shopLoading } = state.shop;
  return {
    dataShop,
    shopLoading,
  };
}

const actionCreators = {
  GetShopInfo: shopActions.GetShopInfo,
  CreateBundle: shopActions.CreateBundle,
  ModifyBundle: shopActions.ModifyBundle,
  SendAlert: alertActions.SendAlert,
};

export default connect(mapState, actionCreators)(BundleForm);
