import React from "react";
import { Link } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
export default function ElementSide(props) {
  return (
    <Link to={props.to}>
      <div
        className="FlWd StHgt RlPs SmMarS"
        onClick={() => {
          if (props.clickEvent) props.clickEvent();
        }}
      >
        {!props.reduce && (
          <span
            className={
              props.active
                ? "StAbs FlHg BdgBlcl StLanNlin BrdLft StTpNl WdSmP StTrns"
                : ""
            }
          ></span>
        )}
        <div
          className={
            props.active
              ? "BdgBlcl RglWdM FlHg StBrdRdS StTrns"
              : "RglWdM FlHg StBrdRdS StTrns"
          }
        >
          <div
            className={
              "InFlx AlgnItm flxDrc FlHg FlWd " +
              (props.reduce ? "JstfCnt" : "")
            }
          >
            <div className="">
              <div className="InFlx">{props.icon}</div>
            </div>
            {!props.reduce && (
              <div className="FlHg InvStMrtg InFlx FlWd">
                <div className="TbDp RlPs FlHg">
                  <div className="TbCdp RlPs TxtCn VrAlg">
                    <span className="StSmlS">{props.text}</span>
                  </div>
                </div>
                {props.new && (
                  <div className="marInStart InFlx AlgnItm">
                    <div
                      style={{
                        backgroundColor: "#2BC194",
                        padding: "4px 10px",
                        borderRadius: "500px",
                      }}
                    >
                      {Translate("shop", "new")}
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </Link>
  );
}
