import React, { Component } from "react";
import { Translate, FormatDate, FormatCurrency } from "../../../utils/lang/translate";
import { officeConstants } from "../../../constants";
import jsPDF from "jspdf";
import { PDFDownloadLink, Page, Text, View, Document, StyleSheet } from "@react-pdf/renderer";
import "jspdf-autotable";
import JsBarcode from "jsbarcode";
import Cookies from "js-cookie";
import { commonActions } from "../../../actions";
import { connect } from "react-redux";
function textToBarCode(text, display = true, rotation = 0) {
  var canvas = document.createElement("canvas");
  JsBarcode(canvas, text, { format: "codabar", displayValue: display });
  canvas.style.transform = "rotate(270deg)";
  return canvas.toDataURL("image/png");
}
const styles = StyleSheet.create({
  page: {
    flexDirection: "row",
    backgroundColor: "#E4E4E4",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});
const Doc = (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <Text>Section #1</Text>
      </View>
    </Page>
  </Document>
);
class PDFgenrator extends Component {
  constructor(props) {
    super(props);
    this.state = {
      urlImg: "",
      ImgHead: "",
    };
    this.downloadLinkRef = React.createRef();
    function getDataUrl(img) {
      // Create canvas
      const canvas = document.createElement("canvas");
      const ctx = canvas.getContext("2d");
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);
      return canvas.toDataURL("image/png");
    }

    const Bndthis = this;
    const img = new Image();
    img.src = "/maystro.png";
    img.addEventListener("load", function (event) {
      let dtulr = getDataUrl(event.currentTarget);
      const img = new Image();
      img.src = "/assets/img/maystro-blue.png";
      img.addEventListener("load", function (event) {
        Bndthis.setState({
          urlImg: dtulr,
          ImgHead: getDataUrl(event.currentTarget),
          loaded: true,
        });
        // Bndthis.state = {
        //   urlImg: dtulr,
        //   ImgHead: getDataUrl(event.currentTarget),
        //   loaded:true
        // };
        if (window.porpsdata) {
          Bndthis.printDocument();
        } else if (window.bonwindow) {
          Bndthis.printBon();
        } else if (window.pickups) {
          Bndthis.printBonRamassage(window.type === "premium" ? "premium" : "starter");
        } else {
          window.close();
          // Bndthis.printHTML()
        }
      });
    });
  }
  componentDidMount() {
    //A4 size 210/
    this.props.GetInfoMaystro(this.props?.dtStoreinfo?.country?.id);
  }
  printBon() {
    if (this.props?.dtStoreinfo?.country?.id === 1) {
      const dtStoreinfo = window.propsended;
      const lang = Cookies.get("lang");
      var order = window.bonwindow;
      var ismany = window.more;
      if (Array.isArray(ismany)) {
        var orders = window.bonwindow;
        let title = "bordereaux__" + FormatDate(new Date());
        var doc = new jsPDF({ compress: true });
        doc.setProperties({
          title: title,
          author: "SARL Maystro Services",
        });
        var startindex = 7;
        var Ystart = 0;
        var Ylevel = 5;
        ismany.map((idorder, key) => {
          let height = 4;
          order = orders.find((order) => order.display_id === idorder);
          doc.addFont("/Amiri-Regular.ttf", "Amiri", "normal");
          doc.addFont("/Amiri-Bold.ttf", "Amiri bold", "bold");
          doc.setFontSize(13.5);
          doc.addImage(this.state.ImgHead, "jpeg", 85, startindex, 40, 20, undefined, "FAST");
          doc.setFont("Amiri bold", "bold");
          doc.setTextColor(65, 65, 65);
          startindex += 24;
          Ylevel = startindex;
          height += 24;
          this.AlignText(doc, lang === "ar" ? order.display_id + " : رقم الطلبية" : "ID: " + order.display_id, 10, startindex, lang !== "ar" ? 0 : 1);
          startindex += 8;
          height += 8;
          this.AlignText(doc, lang === "ar" ? " : " + Translate("titles", "product") : Translate("titles", "product") + " : ", 10, startindex, lang !== "ar" ? 0 : 1);
          let widthText = doc.getTextWidth(Translate("titles", "product"));
          doc.setFont("Amiri", "normal");
          var product = order.products;
          for (var i = 0; i < product.length; i++) {
            doc.setFontSize(13.5);
            this.AlignText(doc, product[i].quantity + " ", 14 + widthText, startindex + i * 9, lang !== "ar" ? 0 : 1);
            // this.AlignText(
            //   doc,
            //   product[i].logistical_description + " ",
            //   19 + widthText,
            //   startindex + i * 9,
            //   lang !== "ar" ? 0 : 1
            // );
            if (doc.getTextWidth(product[i].logistical_description) > 220) doc.setFontSize(8);
            var splitTitle = doc.splitTextToSize(product[i].logistical_description + " ", 110);
            this.AlignText(doc, splitTitle, lang !== "ar" ? 19 + widthText : 60, startindex + i * 9, 0);
          }
          /**************************/
          doc.setFontSize(32);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, order.warehouse || "", lang !== "ar" ? 180 : 10, Ylevel - 7, 0);
          var barCode = textToBarCode(order.display_id, false, 90);
          // doc.addImage(barCode, "jpeg", (lang !== "ar" ? 180:10),Ylevel<100?5:Ylevel-20 , 70, 25,null,null,270);
          doc.addImage(barCode, "jpeg", lang !== "ar" ? 180 : 10, Ylevel < 100 ? 5 : Ylevel - 20, 70, 25, undefined, "FAST", 270);
          // doc.addImage(barCode, "jpeg", (lang !== "ar" ? 100:10)+wearHouseWidth+5, startindex-8, 70, 25,null,null,270);

          /*****************************/
          doc.setFont("Amiri bold", "bold");
          doc.setFontSize(13.5);
          Ystart = startindex + 3 + i * 9;
          height += i * 9;
          widthText = doc.getTextWidth(Translate("orders", "totalprice"));
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "totalprice") : Translate("orders", "totalprice") + " : ", 10, Ystart, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri", "normal");
          this.AlignText(doc, this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(order.price + order.product_price) : order.price + order.product_price + "  " + "TND", 14 + widthText, Ystart, lang !== "ar" ? 0 : 1);
          Ystart += 10;
          height += 10;
          doc.setFont("Amiri bold", "bold");
          /************************* */
          doc.setFontSize(16);
          doc.setTextColor(52, 152, 219);
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "clientinfo") : Translate("orders", "clientinfo") + " : ", 10, Ystart, lang !== "ar" ? 0 : 1);
          this.AlignText(doc, lang === "ar" ? " : " + Translate("settings", "storeinfo") : Translate("settings", "storeinfo") + " : ", 100, Ystart, lang !== "ar" ? 0 : 1);

          doc.setFontSize(13.5);
          doc.setTextColor(65, 65, 65);
          /************************** */
          this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "storename") : Translate("auths", "storename") + " : ", 105, Ystart + 10, lang !== "ar" ? 0 : 1);
          this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "phone") : Translate("auths", "phone") + " : ", 105, Ystart + 20, lang !== "ar" ? 0 : 1);
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customername") : Translate("orders", "customername") + " : ", 15, Ystart + 10, lang !== "ar" ? 0 : 1);
          ////////////////////////////////
          doc.setFont("Amiri", "normal");
          /////////////////////////////////////
          if (doc.getTextWidth(dtStoreinfo.name) > 90) {
            let shift = doc.getTextWidth(Translate("auths", "storename"));
            doc.setFontSize(8);
            this.AlignText(doc, dtStoreinfo.name.substr(0, 30), 115 + shift, Ystart + 10, lang !== "ar" ? 0 : 1);

            doc.setFontSize(13.5);
          } else {
            this.AlignText(doc, dtStoreinfo.name, 115 + doc.getTextWidth(Translate("auths", "storename")), Ystart + 10, lang !== "ar" ? 0 : 1);
          }
          this.AlignText(doc, dtStoreinfo.phone, 115 + doc.getTextWidth(Translate("auths", "phone")), Ystart + 20, lang !== "ar" ? 0 : 1);

          //////////////////////////////////////////
          this.AlignText(doc, order.customer_name, 23 + doc.getTextWidth(Translate("orders", "customername")), Ystart + 10, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customerphonenumber") : Translate("orders", "customerphonenumber") + " : ", 15, Ystart + 20, lang !== "ar" ? 0 : 1);
          ////////////////////////////////////////
          doc.setFont("Amiri", "normal");
          this.AlignText(doc, order.customer_phone, 25 + doc.getTextWidth(Translate("orders", "customerphonenumber")), Ystart + 20, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "district") : Translate("orders", "district") + " : ", 15, Ystart + 30, lang !== "ar" ? 0 : 1);
          ///////////////////////////////////////////////////////
          doc.setFont("Amiri", "normal");
          this.AlignText(doc, order.commune_name, 23 + doc.getTextWidth(Translate("orders", "district")), Ystart + 30, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "city") : Translate("orders", "city") + " : ", 15, Ystart + 40, lang !== "ar" ? 0 : 1);
          //////////////////////////////
          doc.setFont("Amiri", "normal");
          this.AlignText(doc, order.wilaya, 23 + doc.getTextWidth(Translate("orders", "city")), Ystart + 40, lang !== "ar" ? 0 : 1);
          if (Ystart > 150) {
            key + 1 !== ismany.length && doc.addPage();
            startindex = 7;
          } else {
            doc.setLineWidth(0.1);
            doc.setLineDash([1, 1]);
            doc.setDrawColor(0, 0, 0);
            doc.line(0, Ystart + 50, 210, Ystart + 50);
            startindex = Ystart + 60;
          }
        });
        doc.save(title + ".pdf");
        window.location = doc.output("bloburl");
      } else if (!Array.isArray(order)) {
        let title = "bon_de_expédition" + order.display_id + "_" + FormatDate(new Date());
        var doc = new jsPDF({ compress: true });
        doc.setProperties({
          title: title,
          author: "SARL Maystro Services",
        });
        doc.addFont("/Amiri-Regular.ttf", "Amiri", "normal");
        doc.addFont("/Amiri-Bold.ttf", "Amiri bold", "bold");
        doc.setFontSize(13.5);
        doc.addImage(this.state.ImgHead, "jpeg", 85, 7, 40, 20, undefined, "FAST");
        doc.setFont("Amiri bold", "bold");
        doc.setTextColor(65, 65, 65);
        this.AlignText(doc, lang === "ar" ? order.display_id + " : رقم الطلبية" : "ID: " + order.display_id, 10, 33, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("titles", "product") : Translate("titles", "product") + " : ", 10, 42, lang !== "ar" ? 0 : 1);
        let widthText = doc.getTextWidth(Translate("titles", "product"));
        doc.setFont("Amiri", "normal");
        var product = order.products;
        for (var i = 0; i < product.length; i++) {
          this.AlignText(doc, product[i].quantity + " ", 14 + widthText, 42 + i * 9, lang !== "ar" ? 0 : 1);
          if (doc.getTextWidth(product[i].logistical_description) > 220) doc.setFontSize(8);
          var splitTitle = doc.splitTextToSize(product[i].logistical_description + " ", 110);
          this.AlignText(doc, splitTitle, lang !== "ar" ? 19 + widthText : 60, startindex + i * 9, 0);
        }

        doc.setFont("Amiri bold", "bold");
        let Ystart = 45 + i * 9;
        widthText = doc.getTextWidth(Translate("orders", "totalprice"));
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "totalprice") : Translate("orders", "totalprice") + " : ", 10, Ystart, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(order.price + order.product_price) : order.price + order.product_price + "  " + "TND", 14 + widthText, Ystart, lang !== "ar" ? 0 : 1);
        Ystart += 10;
        doc.setFont("Amiri bold", "bold");
        /************************* */
        doc.setFontSize(16);
        doc.setTextColor(52, 152, 219);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "clientinfo") : Translate("orders", "clientinfo") + " : ", 10, Ystart, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("settings", "storeinfo") : Translate("settings", "storeinfo") + " : ", 100, Ystart, lang !== "ar" ? 0 : 1);

        doc.setFontSize(13.5);
        doc.setTextColor(65, 65, 65);
        /************************** */
        this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "storename") : Translate("auths", "storename") + " : ", 105, Ystart + 10, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "phone") : Translate("auths", "phone") + " : ", 105, Ystart + 20, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customername") : Translate("orders", "customername") + " : ", 15, Ystart + 10, lang !== "ar" ? 0 : 1);
        ////////////////////////////////
        doc.setFont("Amiri", "normal");
        /////////////////////////////////////

        this.AlignText(doc, dtStoreinfo.name, 115 + doc.getTextWidth(Translate("auths", "storename")), Ystart + 10, lang !== "ar" ? 0 : 1, 297);
        this.AlignText(doc, dtStoreinfo.phone, 115 + doc.getTextWidth(Translate("auths", "phone")), Ystart + 20, lang !== "ar" ? 0 : 1, 297);

        //////////////////////////////////////////
        this.AlignText(doc, order.customer_name, 23 + doc.getTextWidth(Translate("orders", "customername")), Ystart + 10, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri bold", "bold");
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customerphonenumber") : Translate("orders", "customerphonenumber") + " : ", 15, Ystart + 20, lang !== "ar" ? 0 : 1);
        ////////////////////////////////////////
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, order.customer_phone, 25 + doc.getTextWidth(Translate("orders", "customerphonenumber")), Ystart + 20, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri bold", "bold");
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "district") : Translate("orders", "district") + " : ", 15, Ystart + 30, lang !== "ar" ? 0 : 1);
        ///////////////////////////////////////////////////////
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, order.commune_name, 23 + doc.getTextWidth(Translate("orders", "district")), Ystart + 30, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri bold", "bold");
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "city") : Translate("orders", "city") + " : ", 15, Ystart + 40, lang !== "ar" ? 0 : 1);
        //////////////////////////////
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, order.wilaya, 23 + doc.getTextWidth(Translate("orders", "city")), Ystart + 40, lang !== "ar" ? 0 : 1);
        doc.save(title + ".pdf");
        window.location = doc.output("bloburl");
      }
    } else {
      const dtStoreinfo = window.propsended;
      const lang = Cookies.get("lang");
      var order = window.bonwindow;
      var ismany = window.more;
      if (Array.isArray(ismany)) {
        var orders = window.bonwindow;
        let title = "bordereaux__" + FormatDate(new Date());
        var doc = new jsPDF({ compress: true });
        doc.setProperties({
          title: title,
          author: "SARL Maystro Services",
        });
        var startindex = 10;
        var Ystart = 0;
        var Ylevel = 5;
        ismany.map((idorder, key) => {
          let height = 4;
          order = orders.find((order) => order.display_id === idorder);
          doc.addFont("/Amiri-Regular.ttf", "Amiri", "normal");
          doc.addFont("/Amiri-Bold.ttf", "Amiri bold", "bold");
          doc.setFontSize(13.5);
          var barCode = textToBarCode(order.display_id, true, 90);
          // doc.addImage(barCode, "jpeg", (lang !== "ar" ? 180:10),Ylevel<100?5:Ylevel-20 , 70, 25,null,null,270);
          doc.addImage(barCode, "jpeg", 10, 10, 60, 20, undefined, "FAST", 0);
          startindex = 90;
          Ystart = 10;

          doc.setFontSize(13.5);
          doc.setTextColor(65, 65, 65);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "storename") : Translate("product", "expid") + " : ", 115, Ystart + 10, lang !== "ar" ? 0 : 1);
          this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "phone") : Translate("auths", "phone") + " : ", 115, Ystart + 17, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri", "normal");
          /////////////////////////////////////
          if (doc.getTextWidth(dtStoreinfo.name) > 90) {
            let shift = doc.getTextWidth(Translate("auths", "storename"));
            doc.setFontSize(8);
            this.AlignText(doc, dtStoreinfo.name.substr(0, 30), 130 + shift, Ystart + 10, lang !== "ar" ? 0 : 1);

            doc.setFontSize(13.5);
          } else {
            this.AlignText(doc, dtStoreinfo.name, 130 + doc.getTextWidth(Translate("auths", "storename")), Ystart + 10, lang !== "ar" ? 0 : 1);
          }
          this.AlignText(doc, dtStoreinfo.phone, 125 + doc.getTextWidth(Translate("auths", "phone")), Ystart + 17, lang !== "ar" ? 0 : 1);

          /************************* */

          doc.setLineWidth(0.2);
          doc.rect(50, Ystart + 33, 120, 10);
          /************************* */
          doc.setFont("Amiri bold", "bold");
          doc.setFontSize(22);
          this.AlignText(doc, lang === "ar" ? `Bordereau N°${order.display_id}` : `Bordereau N°${order.display_id}`, 70, Ystart + 40, lang !== "ar" ? 0 : 1);

          /************************* */
          doc.setFont("Amiri", "normal");
          doc.setFontSize(10);
          doc.setTextColor(65, 65, 65);
          /************************** */
          this.AlignText(doc, lang === "ar" ? " : " + "Date" : "Date" + " : ", 15, Ystart + 70, lang !== "ar" ? 0 : 1);
          this.AlignText(doc, FormatDate(new Date()), 20 + doc.getTextWidth("Date"), Ystart + 70, lang !== "ar" ? 0 : 1);

          /************************* */
          doc.setFont("Amiri bold", "bold");
          doc.setFontSize(12);
          doc.setTextColor(65, 65, 65);
          doc.setLineWidth(0.2);
          doc.rect(77, Ystart + 54, 120, 22);
          /************************* */

          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customername") : Translate("orders", "customername") + " : ", 80, Ystart + 60, lang !== "ar" ? 0 : 1);
          ////////////////////////////////

          //////////////////////////////////////////
          this.AlignText(doc, order.customer_name, 88 + doc.getTextWidth(Translate("orders", "customername")), Ystart + 60, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customerphonenumber") : Translate("orders", "customerphonenumber") + " : ", 80, Ystart + 66, lang !== "ar" ? 0 : 1);
          ////////////////////////////////////////
          doc.setFont("Amiri", "normal");
          this.AlignText(doc, order.customer_phone, 90 + doc.getTextWidth(Translate("orders", "customerphonenumber")), Ystart + 66, lang !== "ar" ? 0 : 1);
          doc.setFont("Amiri bold", "bold");
          this.AlignText(doc, lang === "ar" ? " : " + Translate("payement", "adress") : Translate("payement", "adress") + " : ", 80, Ystart + 72, lang !== "ar" ? 0 : 1);
          ///////////////////////////////////////////////////////
          doc.setFont("Amiri", "normal");
          this.AlignText(doc, order.destination_text + "; " + order.commune_name + ", " + order.wilaya, 88 + doc.getTextWidth(Translate("payement", "adress")), Ystart + 72, lang !== "ar" ? 0 : 1);

          var col = ["Désignation", "Quantité"];

          var rows = [];

          //const test = order.products.forEach((element) => {
          //  var str = [element.logistical_description, element.quantity];

          //  rows.push(str);
          // });
          var str = [order.product_name, "1"];

          rows.push(str);

          doc.autoTable(col, rows, {
            startY: Ystart + 100,
            tableWidth: "auto",
            columnWidth: "wrap",
            styles: {
              valign: "middle",

              overflow: "linebreak",
              tableWidth: "auto",

              fillColor: [78, 53, 73],
              lineWidth: 0.3,
            },
            headerStyles: {
              fillColor: [255, 255, 255],
              textColor: [78, 53, 73],
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              textColor: [78, 53, 73],
            },
            margin: { right: 30 },
            columnStyles: {
              0: {
                columnWidth: 65,
              },
              1: {
                columnWidth: 45,
              },
            },
          });

          var col2 = ["Prix Unitaire", "Prix total "];
          var rows2 = [];

          var str2 = [order.product_price / order.products.length, order.price + order.product_price];

          rows2.push(str2);

          doc.autoTable(col2, rows2, {
            startY: Ystart + 100,
            tableWidth: "auto",
            columnWidth: "wrap",
            margin: { left: 100 },
            styles: {
              valign: "middle",

              overflow: "linebreak",
              tableWidth: "auto",

              fillColor: [78, 53, 73],
              lineWidth: 0.3,
            },
            headerStyles: {
              fillColor: [255, 255, 255],
              textColor: [78, 53, 73],
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              textColor: [78, 53, 73],
            },
            columnStyles: {
              0: {
                columnWidth: 40,
              },
              1: {
                columnWidth: 40,
              },
            },
          });
          var coltva = ["Montant HT", "TVA 19%", "Total en TTC"];
          var rowscoltva = [];
          var strtva = [order.product_price + order.price - parseInt(((order.price + order.product_price) * 19) / 100), parseInt(((order.price + order.product_price) * 19) / 100), order.price + order.product_price];

          rowscoltva.push(strtva);

          doc.autoTable(coltva, rowscoltva, {
            startY: Ystart + 130,
            tableWidth: "auto",
            columnWidth: "wrap",
            styles: {
              valign: "middle",

              overflow: "linebreak",
              tableWidth: "auto",

              fillColor: [78, 53, 73],
              lineWidth: 0.3,
            },
            headerStyles: {
              fillColor: [255, 255, 255],
              textColor: [78, 53, 73],
            },
            bodyStyles: {
              fillColor: [255, 255, 255],
              textColor: [78, 53, 73],
            },
            columnStyles: {
              0: {
                columnWidth: 35,
              },
              1: {
                columnWidth: 35,
              },
              2: {
                columnWidth: 35,
              },
            },
          });
          startindex += 54;
          Ylevel = startindex;
          height += 24;

          startindex += 8;
          height += 8;

          var product = order.products;
          for (var i = 0; i < product.length; i++) {}
          /**************************/

          Ystart = startindex + 3 + i * 9;
          height += i * 9;

          Ystart += 10;
          height += 10;

          if (Ystart > 150) {
            key + 1 !== ismany.length && doc.addPage();
            startindex = 100;
          } else {
            startindex = Ystart + 70;
          }
        });
        doc.save(title + ".pdf");
        window.location = doc.output("bloburl");
      } else if (!Array.isArray(order)) {
        let title = "bon_de_expédition" + order.display_id + "_" + FormatDate(new Date());
        var doc = new jsPDF({ compress: true });
        doc.setProperties({
          title: title,
          author: "SARL Maystro Services",
        });
        doc.addFont("/Amiri-Regular.ttf", "Amiri", "normal");
        doc.addFont("/Amiri-Bold.ttf", "Amiri bold", "bold");
        doc.setFontSize(13.5);
        doc.addImage(this.state.ImgHead, "jpeg", 85, 7, 40, 20, undefined, "FAST");
        doc.setFont("Amiri bold", "bold");
        doc.setTextColor(65, 65, 65);
        this.AlignText(doc, lang === "ar" ? order.display_id + " : رقم الطلبية" : "ID: " + order.display_id, 10, 33, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("titles", "product") : Translate("titles", "product") + " : ", 10, 42, lang !== "ar" ? 0 : 1);
        let widthText = doc.getTextWidth(Translate("titles", "product"));
        doc.setFont("Amiri", "normal");
        var product = order.products;
        for (var i = 0; i < product.length; i++) {
          this.AlignText(doc, product[i].quantity + " ", 14 + widthText, 42 + i * 9, lang !== "ar" ? 0 : 1);
          if (doc.getTextWidth(product[i].logistical_description) > 220) doc.setFontSize(8);
          var splitTitle = doc.splitTextToSize(product[i].logistical_description + " ", 110);
          this.AlignText(doc, splitTitle, lang !== "ar" ? 19 + widthText : 60, startindex + i * 9, 0);
        }

        doc.setFont("Amiri bold", "bold");
        let Ystart = 45 + i * 9;
        widthText = doc.getTextWidth(Translate("orders", "totalprice"));
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "totalprice") : Translate("orders", "totalprice") + " : ", 10, Ystart, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(order.price + order.product_price) : order.price + order.product_price + "  " + "TND", 14 + widthText, Ystart, lang !== "ar" ? 0 : 1);
        Ystart += 10;
        doc.setFont("Amiri bold", "bold");
        /************************* */
        doc.setFontSize(16);
        doc.setTextColor(52, 152, 219);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "clientinfo") : Translate("orders", "clientinfo") + " : ", 10, Ystart, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("settings", "storeinfo") : Translate("settings", "storeinfo") + " : ", 100, Ystart, lang !== "ar" ? 0 : 1);

        doc.setFontSize(13.5);
        doc.setTextColor(65, 65, 65);
        /************************** */
        this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "storename") : Translate("auths", "storename") + " : ", 105, Ystart + 10, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("auths", "phone") : Translate("auths", "phone") + " : ", 105, Ystart + 20, lang !== "ar" ? 0 : 1);
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customername") : Translate("orders", "customername") + " : ", 15, Ystart + 10, lang !== "ar" ? 0 : 1);
        ////////////////////////////////
        doc.setFont("Amiri", "normal");
        /////////////////////////////////////

        this.AlignText(doc, dtStoreinfo.name, 115 + doc.getTextWidth(Translate("auths", "storename")), Ystart + 10, lang !== "ar" ? 0 : 1, 297);
        this.AlignText(doc, dtStoreinfo.phone, 115 + doc.getTextWidth(Translate("auths", "phone")), Ystart + 20, lang !== "ar" ? 0 : 1, 297);

        //////////////////////////////////////////
        this.AlignText(doc, order.customer_name, 23 + doc.getTextWidth(Translate("orders", "customername")), Ystart + 10, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri bold", "bold");
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "customerphonenumber") : Translate("orders", "customerphonenumber") + " : ", 15, Ystart + 20, lang !== "ar" ? 0 : 1);
        ////////////////////////////////////////
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, order.customer_phone, 25 + doc.getTextWidth(Translate("orders", "customerphonenumber")), Ystart + 20, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri bold", "bold");
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "district") : Translate("orders", "district") + " : ", 15, Ystart + 30, lang !== "ar" ? 0 : 1);
        ///////////////////////////////////////////////////////
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, order.commune_name, 23 + doc.getTextWidth(Translate("orders", "district")), Ystart + 30, lang !== "ar" ? 0 : 1);
        doc.setFont("Amiri bold", "bold");
        this.AlignText(doc, lang === "ar" ? " : " + Translate("orders", "city") : Translate("orders", "city") + " : ", 15, Ystart + 40, lang !== "ar" ? 0 : 1);
        //////////////////////////////
        doc.setFont("Amiri", "normal");
        this.AlignText(doc, order.wilaya, 23 + doc.getTextWidth(Translate("orders", "city")), Ystart + 40, lang !== "ar" ? 0 : 1);
        doc.save(title + ".pdf");
        window.location = doc.output("bloburl");
      }
    }
  }

  printBonRamassage(type = "starter") {
    const dtStoreinfo = window.store;
    const lang = Cookies.get("lang");
    if (type === "starter") {
      var orders = window.orders;
    } else if (type === "premium") {
      var products = window.products;
    }
    var pickup = window.pickup;
    const thisBind = this;

    let title = "bon de  ramassage__" + FormatDate(new Date());
    var doc = new jsPDF({ compress: true });
    doc.setProperties({
      title: title,
      author: "SARL Maystro Services",
    });
    let tableColumn;
    if (type === "starter") {
      tableColumn = lang !== "ar" ? [[Translate("orders", "orderid").toUpperCase(), Translate("pickups", "collectcheck").toUpperCase()]] : [[Translate("pickups", "collectcheck").toUpperCase(), Translate("orders", "orderid").toUpperCase()]];
    } else {
      tableColumn = lang !== "ar" ? [[Translate("product", "product").toUpperCase(), Translate("product", "qt").toUpperCase(), Translate("pickups", "collectcheck").toUpperCase()]] : [[Translate("pickups", "collectcheck").toUpperCase(), Translate("product", "qt").toUpperCase(), Translate("product", "product").toUpperCase()]];
    }
    let tableRows;
    if (type === "starter") {
      tableRows = orders.map((elem, key) => {
        return lang !== "ar" ? [elem.display_id, ""] : ["", elem.display_id];
      });
    } else {
      tableRows = products.map((elem, key) => {
        return lang !== "ar" ? [elem.logistical_description, elem.quantity_demanded, ""] : ["", elem.quantity_demanded, elem.logistical_description];
      });
    }
    let Yindex = 38;
    // set font
    doc.addFont("/Amiri-Regular.ttf", "Amiri", "normal");
    doc.addFont("/Amiri-Bold.ttf", "Amiri bold", "bold");

    doc.setLineWidth(2);
    doc.setFont("Amiri bold", "bold");
    doc.setFontSize(13);
    this.AlignText(doc, lang !== "ar" ? Translate("pickups", "pickupid") + ":" : ":" + Translate("pickups", "pickupid"), 10, Yindex, lang === "ar" ? 1 : 0);
    this.AlignText(doc, pickup.display_id + "", doc.getTextWidth(Translate("pickups", "pickupid")) + 15, Yindex, lang === "ar" ? 1 : 0);
    if (window.orders) {
      this.AlignText(doc, lang !== "ar" ? Translate("pickups", "orderscount") + ":" : ":" + Translate("pickups", "orderscount"), 110, Yindex, lang === "ar" ? 1 : 0);
      this.AlignText(doc, orders.length + "", doc.getTextWidth(Translate("pickups", "orderscount")) + 15 + 110, Yindex, lang === "ar" ? 1 : 0);
    }

    Yindex += 7;

    this.AlignText(doc, Translate("pickups", "details"), 10, Yindex, lang === "ar" ? 1 : 0);

    // set header maystro
    doc.setFont("Amiri bold", "bold");
    doc.setTextColor(65, 65, 65);
    doc.setFontSize(11);
    doc.addImage(this.state.ImgHead, "jpeg", 85, 12, 40, 20, undefined, "FAST");
    // this.AlignText(
    //   doc,
    //   doc.splitTextToSize(officeConstants.COMPANY_NAME, 85),
    //   18,
    //   Yindex+7,
    //   lang === "ar" ? 1 : 0
    // );
    doc.text(18, Yindex + 7, doc.splitTextToSize(officeConstants.COMPANY_NAME, 85));
    doc.setFontSize(10);
    doc.setFont("Amiri");
    // this.AlignText(
    //   doc,
    //   officeConstants.COMPANY_ADRESS,
    //   18,
    //   Yindex+11,
    //   lang === "ar" ? 1 : 0
    // );
    doc.text(18, Yindex + 11, this.props?.dataMaystro?.address);
    // this.AlignText(
    //   doc,
    //   officeConstants.COMPANY_DISTRICT_WILAYA,
    //   18,
    //   Yindex+15,
    //   lang === "ar" ? 1 : 0
    // );

    // this.AlignText(
    //   doc,
    //   officeConstants.COMPANY_MAIL,
    //   18,
    //   Yindex+19,
    //   lang === "ar" ? 1 : 0
    // );
    doc.text(18, Yindex + 19, this.props?.dataMaystro?.Email);
    // this.AlignText(
    //   doc,
    //   "Tel :"+officeConstants.COMPANY_PHONE,
    //   18,
    //   Yindex+24,
    //   lang === "ar" ? 1 : 0
    // );
    doc.text(18, Yindex + 24, "Tel :" + this.props?.dataMaystro?.["Phone number"]);
    // set header magasin
    doc.setFont("Amiri bold", "bold");
    doc.setFontSize(11);
    doc.text(95, Yindex + 5, Translate("settings", "storeinfo"));
    doc.setFontSize(10);
    doc.setFont("Amiri");
    doc.text(95, Yindex + 10, dtStoreinfo.name || dtStoreinfo.store_name);
    doc.text(95, Yindex + 15, "Tel : " + dtStoreinfo.phone || dtStoreinfo.store_phone);
    // set header date
    doc.setFont("Amiri bold", "bold");
    doc.setFontSize(11);
    doc.text(155, Yindex + 5, Translate("pickups", "driver"));
    // doc.text(155, Yindex+5, Translate("profile", "date"));
    doc.setFontSize(10);
    // doc.text(155, Yindex+10,FormatDate(new Date()));
    doc.text(155, Yindex + 10, ((pickup.agent && pickup.agent.first_name) || "") + ((pickup.agent && pickup.agent.last_name) || ""));
    doc.text(155, Yindex + 15, "Tel : " + ((pickup.agent && pickup.agent.phone) || ""));
    doc.setLineWidth(2);
    doc.setFont("Amiri bold", "bold");
    doc.setFontSize(12);
    Yindex += 30;

    if (window.orders) {
      this.AlignText(doc, Translate("titles", "order"), 15, Yindex, lang === "ar" ? 1 : 0);
    } else {
      this.AlignText(doc, Translate("titles", "product"), 15, Yindex, lang === "ar" ? 1 : 0);
    }

    doc.page = 1;
    jsPDF.autoTableSetDefaults({
      styles: {
        fontSize: 11,
        font: "Amiri",
      },
      columnStyles: {
        fontSize: 11,
        font: "Amiri",
        [lang === "ar" ? "1" : "0"]: { cellWidth: 100 },
      },
      headerStyles: {
        font: "Amiri bold",
      },
    });
    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      startY: Yindex + 5,
      addPageContent: function (data) {
        thisBind.writeFooter(doc, "page ", doc.page);
        if (lang === "ar") {
          thisBind.writeHeader(doc, (dtStoreinfo.name || dtStoreinfo.store_name) + " : " + Translate("pickups", "pickupreceip"));
        } else {
          thisBind.writeHeader(doc, Translate("pickups", "pickupreceip") + " : " + (dtStoreinfo.name || dtStoreinfo.store_name));
        }
        doc.page++;
      },
    });
    let lastY = doc.previousAutoTable.finalY;
    if (lastY >= 270) {
      doc.addPage();
      lastY = 10;
    }

    this.AlignText(doc, Translate("pickups", "storesignature"), 20, lastY + 30, lang === "ar" ? 1 : 0);

    this.AlignText(doc, Translate("pickups", "driversignature"), 130, lastY + 30, lang === "ar" ? 1 : 0);
    doc.line(10, lastY + 15, 200, lastY + 15);

    // doc.save(title+'.pdf')
    window.location = doc.output("bloburl");
  }

  AlignText(doc, text, x, y, align, wdt) {
    if (align === 0) {
      doc.text(x, y, text);
    } else {
      let xval = doc.getTextWidth(text);
      doc.text((wdt ? wdt : 210) - xval - x, y, text);
    }
  }
  writeFooter(doc, text, page) {
    doc.addImage(this.state.urlImg, "jpeg", 7, 283, 6, 6);
    doc.line(15, 285, 200, 285);
    doc.setFontSize(10);
    doc.text(100, 290, text + page);
  }
  writeHeader(doc, text) {
    doc.setFontSize(10);
    doc.setFont("Amiri bold", "bold");
    if (Cookies.get("lang") === "ar") {
      this.AlignText(doc, text, 15, 8, 1);
      this.AlignText(doc, " ـ " + FormatDate(new Date()) + ": تاريخ " + ".", 15, 8, 0);
    } else {
      this.AlignText(doc, text, 15, 8, 0);
      this.AlignText(doc, "Date : " + FormatDate(new Date()), 15, 8, 1);
    }
    doc.line(10, 10, 200, 10);
  }
  printHTML() {
    const styles = StyleSheet.create({
      page: {
        flexDirection: "row",
        backgroundColor: "#E4E4E4",
      },
      section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
    });
    const Template = () => (
      <Document>
        <Page size="A4" style={styles.page}>
          <View style={styles.section}>
            <Text>Section #1</Text>
          </View>
        </Page>
      </Document>
    );
    return Template;
    // pdf(template).toBlob().then(
    //  blob=>{
    //    alert('Done')
    //  }
    // );
  }
  printDocument() {
    const thisBind = this;
    const lang = Cookies.get("lang");
    const { GetingFacture, Facturedata, dtStoreinfo } = window.porpsdata;
    if (GetingFacture === "1") {
      const tableColumn = lang === "ar" ? [[Translate("payement", "price").toUpperCase(), Translate("product", "productname").toUpperCase(), "Id"]] : [["Id", Translate("product", "productname").toUpperCase(), Translate("payement", "price").toUpperCase()]];
      let tableRows = Facturedata.facture_delivered_product.map((elem, key) => {
        return this.props?.dtStoreinfo?.country?.id === 1 ? (lang === "ar" ? [FormatCurrency.format(elem.amount), elem.description, key] : [key, elem.description, FormatCurrency.format(elem.amount)]) : lang === "ar" ? [elem.amount + "  " + "د.ت", elem.description, key] : [key, elem.description, elem.amount + "  " + "TND"];
      });
      var doc = new jsPDF();
      let date = new Date();
      let title = "Maystro_Delivery_" + Translate("payement", "invoicedetail") + "_To_" + dtStoreinfo.name + "_" + FormatDate(date);
      doc.setProperties({
        title: title,
        author: "SARL Maystro Services",
      });
      // set font
      doc.addFont("/Amiri-Regular.ttf", "Amiri", "normal");
      doc.addFont("/Amiri-Bold.ttf", "Amiri bold", "bold");
      // set header maystro
      doc.setFont("Amiri bold", "bold");
      doc.setTextColor(65, 65, 65);
      doc.setFontSize(11);
      doc.addImage(this.state.ImgHead, "jpeg", 85, 12, 40, 20);
      doc.text(18, 38, officeConstants.COMPANY_NAME);
      doc.setFontSize(10);
      doc.setFont("Amiri");
      doc.text(18, 44, this.props?.dataMaystro?.address);
      doc.text(18, 49, this.props?.dataMaystro?.Email);
      doc.text(18, 54, "Tel : " + this.props?.dataMaystro?.["Phone number"]);
      // set header magasin
      doc.setFont("Amiri bold", "bold");
      doc.setFontSize(11);
      doc.text(95, 38, Translate("payement", "invoiceto"));
      doc.setFontSize(10);
      doc.setFont("Amiri");
      doc.text(95, 44, dtStoreinfo.name || dtStoreinfo.store_name);
      doc.text(95, 49, "Tel : " + dtStoreinfo.phone || dtStoreinfo.store_phone);
      // set header date
      doc.setFont("Amiri bold", "bold");
      doc.setFontSize(11);
      doc.text(155, 38, Translate("payement", "invoicedate"));
      doc.setFontSize(10);
      doc.text(155, 44, " ـ " + FormatDate(window.dateinvoice) + " ـ ");
      doc.setLineWidth(2);
      doc.setFont("Amiri bold", "bold");
      doc.setFontSize(12);

      /********************  product livré ************ */

      this.AlignText(doc, Translate("payement", "facturedeliveredproduct"), 10, 65, lang === "ar" ? 1 : 0);
      doc.page = 1;
      jsPDF.autoTableSetDefaults({
        styles: {
          fontSize: 11,
          font: "Amiri",
        },
        columnStyles: {
          fontSize: 11,
          font: "Amiri",
        },
        headerStyles: {
          font: "Amiri bold",
        },
      });
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        startY: 70,
        addPageContent: function (data) {
          thisBind.writeFooter(doc, "page ", doc.page);
          if (lang === "ar") {
            thisBind.writeHeader(doc, (dtStoreinfo.name || dtStoreinfo.store_name) + " : " + Translate("payement", "invoicedetail"));
          } else {
            thisBind.writeHeader(doc, Translate("payement", "invoicedetail") + " : " + (dtStoreinfo.name || dtStoreinfo.store_name));
          }
          doc.page++;
        },
      });
      let lastY = doc.previousAutoTable.finalY;
      if (lastY >= 270) {
        doc.addPage();
        lastY = 10;
      }
      let wdtpr = this.props?.dtStoreinfo?.country?.id === 1 ? doc.getTextWidth(FormatCurrency.format(Facturedata.total_facture_delivered_product)) : doc.getTextWidth(Facturedata.total_facture_delivered_product + "  " + "TND");
      this.AlignText(doc, this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(Facturedata.total_facture_delivered_product) : Facturedata.total_facture_delivered_product + "  " + "TND", 10, lastY + 10, lang === "ar" ? 0 : 1);
      this.AlignText(doc, Translate("payement", "tfacturedeliveredproduct"), wdtpr + 13, lastY + 10, lang === "ar" ? 0 : 1);
      doc.line(10, lastY + 15, 200, lastY + 15);

      /***************** end product */

      /***************** facture echanage  */

      this.AlignText(doc, Translate("payement", "factureechange"), 10, lastY + 23, lang === "ar" ? 1 : 0);
      tableRows = Facturedata.facture_echange.map((elem, key) => {
        return this.props?.dtStoreinfo?.country?.id === 1 ? (lang === "ar" ? [FormatCurrency.format(elem.amount), elem.description, key] : [key, elem.description, FormatCurrency.format(elem.amount)]) : lang === "ar" ? [elem.amount + "  " + "TND", elem.description, key] : [key, elem.description, elem.amount + "  " + "TND"];
      });
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        startY: lastY + 28,
        addPageContent: function (data) {
          thisBind.writeFooter(doc, "page ", doc.page);
          if (lang === "ar") {
            thisBind.writeHeader(doc, (dtStoreinfo.name || dtStoreinfo.store_name) + " : " + Translate("payement", "invoicedetail"));
          } else {
            thisBind.writeHeader(doc, Translate("payement", "invoicedetail") + " : " + (dtStoreinfo.name || dtStoreinfo.store_name));
          }
          doc.page++;
        },
      });
      lastY = doc.previousAutoTable.finalY;
      if (lastY >= 270) {
        doc.addPage();
        lastY = 10;
      }
      wdtpr = this.props?.dtStoreinfo?.country?.id === 1 ? doc.getTextWidth(FormatCurrency.format(Facturedata.total_facture_echange)) : doc.getTextWidth(Facturedata.total_facture_echange + "  " + "TND");
      this.AlignText(doc, this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(Facturedata.total_facture_echange) : Facturedata.total_facture_echange + "  " + "TND", 10, lastY + 10, lang === "ar" ? 0 : 1);
      this.AlignText(doc, Translate("payement", "tfactureechange"), wdtpr + 13, lastY + 10, lang === "ar" ? 0 : 1);
      doc.line(10, lastY + 15, 200, lastY + 15);

      /******************* end echange */

      /*** ************* extrat service *****/

      this.AlignText(doc, Translate("payement", "factureextraservice"), 10, lastY + 23, lang === "ar" ? 1 : 0);

      tableRows = Facturedata.facture_extra_service.map((elem, key) => {
        return this.props?.dtStoreinfo?.country?.id === 1 ? (lang === "ar" ? [FormatCurrency.format(elem.amount), elem.description, key] : [key, elem.description, FormatCurrency.format(elem.amount)]) : lang === "ar" ? [elem.amount + "  " + "TND", elem.description, key] : [key, elem.description, elem.amount + "  " + "TND"];
      });
      doc.autoTable({
        head: tableColumn,
        body: tableRows,
        startY: lastY + 28,
        addPageContent: function (data) {
          thisBind.writeFooter(doc, "page ", doc.page);
          if (lang === "ar") {
            thisBind.writeHeader(doc, (dtStoreinfo.name || dtStoreinfo.store_name) + " : " + Translate("payement", "invoicedetail"));
          } else {
            thisBind.writeHeader(doc, Translate("payement", "invoicedetail") + " : " + (dtStoreinfo.name || dtStoreinfo.store_name));
          }
          doc.page++;
        },
      });
      lastY = doc.previousAutoTable.finalY;
      if (lastY >= 270) {
        doc.addPage();
        lastY = 10;
      }
      wdtpr = this.props?.dtStoreinfo?.country?.id === 1 ? doc.getTextWidth(FormatCurrency.format(Facturedata.total_facture_extra_service)) : doc.getTextWidth(Facturedata.total_facture_extra_service + "  " + "TND");
      this.AlignText(doc, this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format(Facturedata.total_facture_extra_service) : Facturedata.total_facture_extra_service, 10, lastY + 10, lang === "ar" ? 0 : 1);
      this.AlignText(doc, Translate("payement", "tfactureextraservice"), wdtpr + 13, lastY + 10, lang === "ar" ? 0 : 1);
      doc.line(10, lastY + 15, 200, lastY + 15);

      /************ end exxtra service  */

      /********************* facture retour */
      // this.AlignText(
      //   doc,
      //   Translate("payement", "factureretoure"),
      //   10,
      //   lastY + 23,
      //   lang === "ar" ? 1 : 0
      // );
      // tableRows = Facturedata.facture_retoure.map((elem, key) => {
      //   return lang === "ar"
      //     ? [FormatCurrency.format(elem.amount), elem.description, key]
      //     : [key, elem.description, FormatCurrency.format(elem.amount)];
      // });
      // doc.autoTable({
      //   head: tableColumn,
      //   body: tableRows,
      //   startY: lastY + 28,
      //   addPageContent: function (data) {
      //     thisBind.writeFooter(doc, "page ", doc.page);
      //     if (lang === "ar") {
      //       thisBind.writeHeader(
      //         doc,
      //         (dtStoreinfo.name || dtStoreinfo.store_name) +
      //           " : " +
      //           Translate("payement", "invoicedetail")
      //       );
      //     } else {
      //       thisBind.writeHeader(
      //         doc,
      //         Translate("payement", "invoicedetail") +
      //           " : " +
      //           (dtStoreinfo.name || dtStoreinfo.store_name)
      //       );
      //     }
      //     doc.page++;
      //   },
      // });
      // lastY = doc.previousAutoTable.finalY;
      // if (lastY >= 270) {
      //   doc.addPage();
      //   lastY = 10;
      // }
      // wdtpr = doc.getTextWidth(
      //   FormatCurrency.format(Facturedata.total_facture_retoure)
      // );
      // this.AlignText(
      //   doc,
      //   FormatCurrency.format(Facturedata.total_facture_retoure),
      //   10,
      //   lastY + 10,
      //   lang === "ar" ? 0 : 1
      // );
      // this.AlignText(
      //   doc,
      //   Translate("payement", "tfactureretoure"),
      //   wdtpr + 13,
      //   lastY + 10,
      //   lang === "ar" ? 0 : 1
      // );
      // doc.line(10, lastY + 15, 200, lastY + 15);

      /**************** end retour */

      doc.setFontSize(15);
      doc.setTextColor(52, 152, 219);
      let total = this.props?.dtStoreinfo?.country?.id === 1 ? FormatCurrency.format((Facturedata.total_facture_delivered_product || 0) + (Facturedata.total_facture_echange || 0) + (Facturedata.total_facture_extra_service || 0) + (Facturedata.total_facture_retoure || 0)) : (Facturedata.total_facture_delivered_product || 0) + (Facturedata.total_facture_echange || 0) + (Facturedata.total_facture_extra_service || 0) + (Facturedata.total_facture_retoure || 0) + "  " + "TND";
      wdtpr = doc.getTextWidth(total);
      this.AlignText(doc, total, 10, lastY + 23, lang === "ar" ? 0 : 1);
      this.AlignText(doc, Translate("payement", "total"), wdtpr + 13, lastY + 23, lang === "ar" ? 0 : 1);
      doc.save(title + ".pdf");
      window.location = doc.output("bloburl");
    }
  }
  render() {
    // return "please wait you file is process";
    const bonRamassage = window.fromHtml ? true : false;
    return (
      <div className="FlHg">
        {this.state.loaded && bonRamassage ? (
          <PDFDownloadLink document={Doc} fileName="somename.pdf" ref={this.downloadLinkRef}>
            {({ blob, url, loading, error }) => {
              if (loading) return "Loading document...";
              else {
                return <div className="FlWd FlHg InFlx AlgnItm JstfCnt">Download now!</div>;
              }
            }}
          </PDFDownloadLink>
        ) : (
          <div className="FlWd FlHg InFlx AlgnItm JstfCnt">Please wait you file is in process ...</div>
        )}
      </div>
    );
  }
}

function mapState(state) {
  const { gtStoreInf, dtStoreinfo } = state.user;

  const { GetingInfomaystro, dataMaystro } = state.common;
  return {
    gtStoreInf,
    dtStoreinfo,

    GetingInfomaystro,
    dataMaystro,
  };
}

const actionCreators = {
  GetInfoMaystro: commonActions.GetInfoMaystro,
};

const connectedFacturePayment = connect(mapState, actionCreators)(PDFgenrator);
export { connectedFacturePayment as PDFgenrator };
