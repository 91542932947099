import { Bundle, DataShop, ProductShop } from "../types";
type Key = keyof Object;

export const convertTimeIntoString: (value: number) => string = (value) => {
  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value - hours * 3600) / 60);
  const seconds = value - hours * 3600 - minutes * 60;

  const hoursString = hours === 0 ? "" : `${hours}h `;
  const minutesString = minutes === 0 ? "" : `${minutes}m `;
  const secondsString = `${seconds}s`;

  return hoursString + minutesString + secondsString;
};

export const convertMoneyIntoString: (
  value: number,
  currency: string
) => string = (value, currency) => {
  if (value === 0) return value + currency;
  return (
    value
      .toString()
      .split("")
      .reverse()
      .map((char, i) => (!!(i % 3) || i === 0 ? char : char + ","))
      .reverse()
      .join("") + currency
  );
};

export const filterObjectByChangingValues: (
  object1: Object,
  object2: Object
) => Object = (object1, object2) => {
  let result = {};
  for (const key in object1) {
    if (
      typeof object1[key as Key] !== "undefined" &&
      typeof object2[key as Key] !== "undefined" &&
      JSON.stringify(object1[key as Key]) !==
        JSON.stringify(object2[key as Key])
    ) {
      result = { ...result, [key]: object2[key as Key] };
    }
  }

  return result;
};

export const getProductFromResponse: (data: any) => ProductShop = (data) => {
  return {
    title: data.title,
    slug: data.slug,
    options: data.options?.map((option: any) => {
      return {
        name: option.name,
        quantity: option.quantity,
        reduced_price: option.reduced_price,
        price: option.price,
        id: option.id,
      };
    }),
    images: data.images?.map((image: any) => {
      return {
        url: image.url,
        id: image.id,
        order: image.order,
      };
    }),
    categories: data.categories,
    description: data.description,
    how_to_use: data.how_to_use,
    specifications: data.specifications,
    product_id: data.product_id,
    display_id: data.display_id,
    published: data.published,
    id: data.id,
    landing_page: data.landing_page,
    lang: data.lang,
    rate: parseInt(data.rate),
  };
};

export const getBundleFromResponse: (data: any) => Bundle = (data) => {
  return {
    product_bundle: data.product_bundle?.map((prod: any) => {
      return {
        title: prod.title,
        id: prod.id,
        display_id: prod.display_id,
        product: prod.product,
        quantity: prod.quantity,
      };
    }),
    price: data.price,
    reduced_price: data.reduced_price,
    id: data.id,
    display_id: data.display_id,
  };
};

export const getSettingsFromResponse: (data: any) => DataShop = (data) => {
  return {
    settings_stage: data.settings_stage,
    logo: data.logo,
    name: data.name,
    website_title: data.website_title,
    store_categories: data.store_categories,
    theme_template: data.theme_template,
    subdomain_slug: data.subdomain_slug,
    pixel_key: data.pixel_key,
    published: data.published,
    domain: data.domain,
    products: data.products?.map((product: any) => {
      return getProductFromResponse(product);
    }),
    shop_bundles: data.shop_bundles?.map((bundle: any) => {
      return getBundleFromResponse(bundle);
    }),
  };
};

export const createFormDataFromObject = (element: Object) => {
  const formData = new FormData();
  for (const key in element) {
    const value: any = element[key as Key];

    if (Array.isArray(value)) {
      value.map((elem: any) => {
        if (
          typeof elem === "object" &&
          !(elem instanceof File) &&
          !Array.isArray(elem) &&
          elem !== null
        ) {
          return formData.append(key, JSON.stringify(elem));
        }
        return formData.append(key, elem);
      });
    } else {
      formData.append(key, value);
    }
  }
  return formData;
};

export const createObjectFromKeys = (element: Object, keys: string[]) => {
  let result = {};
  keys.forEach((key: string) => {
    result = { ...result, [key]: element[key as Key] };
  });
  return result;
};

export const createFormDataFromArray = (array: any[], key: string) => {
  const formData = new FormData();
  array.forEach((elem) => {
    formData.append(key, elem);
  });
  return formData;
};

export const checkUserHasShopPermissionById = (id: string) => {
  const users = [
    "3a2855aa-9319-481e-ac50-164083b8a297",
    "544756fb-69da-42aa-a627-2aa6a85258e9",
    "a4d8cb3f-a89d-4391-a21d-4a3ec3a96c83",
    "e8efe3ae-07d6-41d4-87fa-267f447c0265",
    "b9d702b3-07ab-471a-a040-b7dafa4dec5d",
    "8353abb8-be14-41c2-bba4-43b51df465dc",
    "a370f230-0062-445e-9786-b794634f782d",
    "59d6bfc8-da37-4e04-9f94-d51ac5a816c5",
    "e49c3b56-50dd-4819-8316-9aa84cdd8af3",
    "f4b66e9e-2c72-43d5-8400-2ecdee4f2faf",
    "99cac869-7bdf-4a33-b512-9239e373e254",
    "c7b9a77a-cced-43f8-8d56-cda8afbddf82",
    "ea585f76-6700-4682-bc8a-b0da80a6034b",
    "120149d0-3f4e-4a39-95c8-528dafe0be9f",
    "8c07be94-8a6e-4032-bfa3-183ddc770a2d",
    "08ca38ad-0154-46d7-ad19-5358f2c675c6",
    "ad3b39ba-664d-4894-b08a-629ca0ee46f6",
    "42b82ac8-76e5-4f3d-aa16-2a5cb6c808bd",
    "e33249de-c8f3-4ccb-9286-6734d2eb8f83",
    "10a1ee75-29e3-4947-a52e-60f066c969c2",
    "47e1b114-975b-410c-b705-5129bcd0683c",
    "0aadaee0-4556-4258-8748-d9404223e331",
    "3aa75460-eb41-44fd-abec-ce8e84c09f2c",
    "118aa70b-317c-4b19-8ea3-5f5c273c19bf",
    "b5fba0d3-5c0d-4a01-804a-6eb2f478925b",
    "383688d3-bb53-4c8c-b651-bbbcbe37a1ff",
    "722ef24b-1002-4d88-8685-61e2b4873855",
  ];
  return users.some((user) => user === id);
};
