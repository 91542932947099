import React from "react";
import { DataShop } from "../../../../types";
import { Translate } from "../../../../utils/lang/translate";
import {
  CheckSvg2,
  FacebookSvg,
  ShopSvg,
  ThemeSvg,
  WebSvg,
} from "../../_Common/IconSvg";
import NavigationBubble from "./NavigationBubble";
import { Event } from "../../_Common";

interface Props {
  dataShop: DataShop;
  history: any;
  location: any;
}

const NavigationMobileTop: React.FC<Props> = ({
  dataShop,
  history,
  location,
}) => {
  const elements = [
    {
      svg: ShopSvg,
      title: Translate("titles", "shopInfo"),
      isActive: location.pathname === "/settings/shop/store",
      isDone: dataShop.settings_stage >= 1 && !dataShop.published,
      handleClick: () => {
        Event("SHOP", "BUBBLE_STORE", "CLICK_EVENT");
        history.push("/settings/shop/store");
      },
      scale: 1.3 * 0.75,
    },
    {
      svg: ThemeSvg,
      title: Translate("titles", "themeTemplate"),
      isActive: location.pathname === "/settings/shop/theme",
      isDone: dataShop.settings_stage >= 2 && !dataShop.published,
      isDisabled: dataShop.settings_stage < 1,
      handleClick: () => {
        Event("SHOP", "BUBBLE_THEME", "CLICK_EVENT");
        history.push("/settings/shop/theme");
      },
      scale: 0.9 * 0.75,
    },
    {
      svg: WebSvg,
      title: Translate("titles", "domainSetup"),
      isActive: location.pathname === "/settings/shop/domain",
      isDone: dataShop.settings_stage >= 3 && !dataShop.published,
      isDisabled: dataShop.settings_stage < 2,
      handleClick: () => {
        Event("SHOP", "BUBBLE_DOMAIN", "CLICK_EVENT");
        history.push("/settings/shop/domain");
      },
      scale: 0.95 * 0.75,
    },
    {
      svg: FacebookSvg,
      title: Translate("titles", "facebookPixel"),
      isActive: location.pathname === "/settings/shop/facebook",
      isDone: dataShop.settings_stage >= 4 && !dataShop.published,
      isDisabled: dataShop.settings_stage < 3,
      handleClick: () => {
        Event("SHOP", "BUBBLE_FACEBOOK", "CLICK_EVENT");
        history.push("/settings/shop/facebook");
      },
      scale: 2 * 0.75,
    },
  ];
  const finishElement = dataShop.published
    ? []
    : [
        {
          svg: CheckSvg2,
          title: Translate("titles", "finish"),
          isActive: location.pathname === "/settings/shop/finish",
          isDisabled: dataShop.settings_stage < 4,
          handleClick: () => {
            Event("SHOP", "BUBBLE_FINISH", "CLICK_EVENT");
            history.push("/settings/shop/finish");
          },
          isDone: false,
          scale: 0.9 * 0.75,
        },
      ];

  const locationName = location.pathname.toString().split("/").at(-1);

  const stage =
    locationName === "store"
      ? 0
      : locationName === "theme"
      ? 1
      : locationName === "domain"
      ? 2
      : locationName === "facebook"
      ? 3
      : locationName === "finish"
      ? 4
      : 5;

  const elementsDisplay = [...elements, ...finishElement].map(
    (elem, key, arr) => (
      <>
        {key <= stage && (
          <NavigationBubble
            svg={elem.svg}
            title={elem.title}
            isActive={elem.isActive}
            isDone={elem.isDone}
            isDisabled={elem.isDisabled}
            scale={elem.scale}
            handleClick={elem.handleClick}
          />
        )}

        {key < stage && (
          <div
            className={`line ${
              dataShop.settings_stage >= key + 1 && !dataShop.published
                ? "active"
                : ""
            }`}
          />
        )}
      </>
    )
  );

  return (
    <>
      <div className="navigation responsive-mobile">{elementsDisplay}</div>
    </>
  );
};

export default NavigationMobileTop;
