import React, { Component } from "react";
import { connect } from "react-redux";
import {
  productActions,
  ordersActions,
  commonActions,
  alertActions,
} from "../../../actions";
import ContainerPage from "../_Common/ContainerPage";
import { FeatureLoked } from "../_Common/FeatureLoked";
import { Translate } from "../../../utils/lang/translate";
import GroupeForm from "../../_Common/GroupeForm";
import SelectForm from "../../_Common/SelectForm";
import SelectFormSearch from "../../_Common/SelectFormSearch";
import Button from "../../_Common/Button";
import Loader from "../../_Common/Loader";
import { Link, Redirect } from "react-router-dom";
import { initGAPg, Event, Select } from "../_Common";
import { CloseSvg } from "../_Common/IconSvg";
import Cookies from "js-cookie";

const lang = Cookies.get("lang");

const allowed_editions = (status, premium_store, all_allowed = false) => {
  if (all_allowed)
    return {
      edit_products: true,
      edit_customer_infos: true,
      edit_delivery_infos: true,
    };
  let allowed = {
    edit_products: false,
    edit_customer_infos: true,
    edit_delivery_infos: false,
  };
  if (premium_store) {
    if ([8, 9, 10, 11, 12, 15, 22, 50].indexOf(status) > -1) {
      allowed.edit_products = true;
      if (status !== 8) allowed.edit_delivery_infos = true;
    }
  } else {
    if ([4, 5, 6].indexOf(status) > -1) {
      allowed.edit_products = true;
      allowed.edit_delivery_infos = true;
    }
  }

  return allowed;
};
class AddOrderPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      product: [{ product_id: "", quantity: 1, logistical_description: "" }],
      customername: "",
      customerphonenumber: "",
      adresse: "",
      district: null,
      city: "",
      orderprice: 0,
      notetodriver: "",
      delivryprice: 0,
      totalprice: 0,
      error: {},
      express_delivery: false,
      notfound: false,
      nbproduct: 1,
      productnames: [],
      filtersource:
        this.props.OrderGeting === "2" ? this.props.OrderGeting.filter : "",
      titlepage: Translate("orders", "addneworder"),
      titlebtn: Translate("sidebar", "addorder"),
      filtersource: 1,
      tab_id: null,
      source: this.$_GET("source"),
      permmisionedit:
        this.props.dataProfile.is_store || this.props.dataProfile.change_order,
      permmisionadd:
        this.props.dataProfile.is_store || this.props.dataProfile.add_order,
      allowed_editions: {},
      country_id: "",
      wilaya: "",
      commune: "",
    };
    this.isExternCity = null;
    this.isExternCommune = null;
    this.submit = false;
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChangeProduct = this.handleChangeProduct.bind(this);
    this.AddFieldProduct = this.AddFieldProduct.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
  }
  componentWillReceiveProps(nextProps) {
    const {
      data,
      ProductFinded,
      GetingDelivery,
      dataDelivery,
      OrderUpdating,
      UpdinunfOrd,
      OrderAdded,
      GetigCommune,
      DataCommune,
    } = nextProps;
    if (ProductFinded === "2") {
      this.setState({ productnames: data.list });
    }
    if (this.props.GetingDelivery === "1" && GetingDelivery === "2") {
      this.setState({
        delivryprice: dataDelivery.delivery_price,
        orderprice: this.state.totalprice - dataDelivery.delivery_price,
      });
    }
    if (
      (OrderAdded === "3" || UpdinunfOrd === "3" || OrderUpdating === "3") &&
      this.submit
    ) {
      document.getElementById("Container-root").scrollTo(0, 0);
    }
    if (this.props.OrderUpdating === "1" && OrderUpdating === "2") {
      this.props.SendAlert("41", Translate("alert", "orupd"), "a01");
      this.props.history.push(
        (this.state.source === "order" ? "/orders" : "/search") +
          this.state.filtersource
      );
    }
    if (
      this.isExternCommune &&
      this.props.GetingCommunestore === "1" &&
      this.props.GetingCommunestore === "0" &&
      Array.isArray(this.props.dataCommuneStore)
    ) {
      let commune = this.props.dataCommuneStore.find(
        (elem) =>
          elem.id === this.isExternCommune || elem.name === this.isExternCommune
      );
      this.handleSelect(commune, "district");
      this.isExternCommune = null;
    }
  }
  componentWillUnmount() {
    if (this.props.UpdinunfOrd === "3" || this.props.UpdinunfOrd === "2") {
      this.props.ClearStore("CLEAR_UNF_ORD");
    }
    this.props.ClearStore("CLEAR_COMMON");
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "order");
    initGAPg();
    this.props.GetCountry();
    this.props.GetWilayasStore(this.props.dtStoreinfo?.country?.id);

    const {
      match: { params },
    } = this.props;
    let idorder = params.idorder;
    if (idorder !== "new") {
      var dataCommed = null;
      var externTitle, FilterSource;
      if (this.state.source === "order") {
        const { OrderGeting, OrdersList } = this.props;
        if (OrderGeting !== "2") {
          this.props.history.push("/orders");
        } else {
          dataCommed = OrdersList.ressuc.list.results.filter(
            (elem) => elem.display_id === idorder
          )[0];
          if (!dataCommed) this.props.history.push("/orders");
          else {
            FilterSource = OrdersList.filter;
            this.isExternCommune = dataCommed.commune;
            externTitle = (
              <div className="StDrc">
                <Link className="StOpcVal" to={"/orders" + FilterSource}>
                  {Translate("titles", "order")}
                </Link>
                <div className="Inlflx StWdDst">
                  {Translate("orders", "flech")}
                </div>
                <div className="Inlflx">{Translate("orders", "editorder")}</div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{dataCommed.display_id}</div>
              </div>
            );
          }
        }
      } else if (this.state.source === "unfOrder") {
        const { GetinunfOrd, dataInfOrd } = this.props;
        if (GetinunfOrd !== "2") {
          this.props.history.push("/unfulfilled/order");
        } else {
          dataCommed = dataInfOrd.ressuc.results.filter(
            (elem) => elem.display_id.toString() === idorder
          )[0];
          FilterSource = dataInfOrd.filter;
          this.isExternCommune = dataCommed.commune;
          externTitle = (
            <div className="StDrc">
              <Link
                className="StOpcVal"
                to={"/unfulfilled/order" + FilterSource}
              >
                {Translate("titles", "unfulfilledorder")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("orders", "acceptorder")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{dataCommed.display_id}</div>
            </div>
          );
        }
      } else if (this.state.source === "search") {
        const { DataSearch, Searching } = this.props;
        if (Searching !== "1") {
          this.props.history.push("/search");
        } else {
          dataCommed = DataSearch.ressuc.results.filter(
            (elem) => elem.display_id === idorder
          )[0];
          FilterSource = DataSearch.filter;
          this.isExternCommune = dataCommed.commune_name;
          externTitle = (
            <div className="StDrc">
              <Link className="StOpcVal" to={"/search" + FilterSource}>
                {Translate("titles", "search")}
              </Link>
              <div className="Inlflx StWdDst">
                {Translate("orders", "flech")}
              </div>
              <div className="Inlflx">{Translate("orders", "editorder")}</div>
              <div className="Inlflx StWdDst">#</div>
              <div className="Inlflx">{dataCommed.display_id}</div>
            </div>
          );
        }
      }
      if (dataCommed) {
        this.isExternCity = dataCommed.wilaya;
        this.setState({
          id: dataCommed.id,
          product: dataCommed.products,
          customername: dataCommed.customer_name,
          customerphonenumber: dataCommed.customer_phone,
          adresse: dataCommed.destination_text,
          orderprice: dataCommed.product_price,
          notetodriver: dataCommed.note_to_driver,
          delivryprice: dataCommed.price ? dataCommed.price : 0,
          totalprice:
            (dataCommed.price ? dataCommed.price : 0) +
            dataCommed.product_price,
          express_delivery: dataCommed.express,
          notfound: false,
          status: dataCommed.status,
          nbproduct: dataCommed.products.length,
          titlepage: externTitle,
          titlebtn: Translate("orders", "save"),
          filtersource: FilterSource,
          allowed_editions: allowed_editions(
            dataCommed.status,
            this.props.dtStoreinfo.stock_managed,
            dataCommed.source === "Maystro_Shop" ? true : false
          ),
        });
      }
    } else {
      // If it's a new order to be created, all edition are allowed of course
      this.setState({ allowed_editions: allowed_editions(null, null, true) });
    }
    this.props.Getwilayas();
    if (this.props.ProductFinded !== "2") {
      this.props.GetAllProduct();
    }
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.state.city && prevState.city !== this.state.city) {
      this.props.GetCommuneStore(this.state.city[0]);
    }
  }
  handleChange(e) {
    const { name, value } = e.target;
    if (name === "express_delivery") {
      if (this.state.district && this.state.district.id) {
        this.props.GetDelivery(this.state.district.id, value);
      }
    }
    if (name === "orderprice") {
      let prix = parseInt(value.replace(/\D/g, ""));
      this.setState({
        orderprice:
          prix >= this.state.delivryprice ? prix - this.state.delivryprice : 0,
        totalprice: prix ? prix : 0,
      });
      return;
    }
    this.setState({ [name]: value });
  }

  handleChangeProduct(e) {
    const { name, value } = e.target;
    let product = this.state.product;
    let tabn = name.split("_");
    let indx = parseInt(tabn[1]);
    if (tabn[0] === "productname") {
      let key_elem = this.state.productnames.filter(
        (elem) => elem.logistical_description === value
      )[0];
      if (key_elem) {
        product[indx - 1].product_id = key_elem.id;
        product[indx - 1].logistical_description = value;
        this.setState({
          product: product,
          notfound: false,
        });
      }
      if (!key_elem) {
        product[indx - 1].logistical_description = value;
        product[indx - 1].product_id = "";
        this.setState({
          notfound: true,
          product: product,
        });
      }
    } else {
      product[indx - 1].quantity = value;
      this.setState({
        product: product,
      });
    }
  }
  handleSelect(elem, name) {
    if (elem) {
      if (
        name === "city" &&
        ((this.state.city && this.state.city[0] !== elem[0]) ||
          !this.state.city)
      ) {
        this.setState({ district: null });
        this.props.Getcommunes("?wilaya=" + elem[0]);
      }
      if (name === "district") {
        this.props.GetDelivery(elem.id, this.state.express_delivery);
      }
    }
    this.setState({
      [name]: elem,
    });
  }
  AddFieldProduct() {
    Event("ADD_ORDER", "USE_ADD_PRODUCT_BUTTON", "CLICK_EVENT");
    let nb = this.state.nbproduct + 1;
    let product = this.state.product;
    product.push({ product_id: "", quantity: 1 });
    this.setState({
      nbproduct: nb,
      product: product,
    });
  }
  RemoveFieldProduct(pos) {
    Event("ADD_ORDER", "USE_REMOVE_PRODUCT_BUTTON", "CLICK_EVENT");
    let nb = this.state.nbproduct;
    let product = this.state.product;
    if (nb > 1) {
      product.splice(pos, 1);
      this.setState({
        nbproduct: nb - 1,
        product: product,
      });
    }
  }
  handleSubmit(e) {
    e.preventDefault();

    let contin = true;
    let order = {},
      error = {};
    if (this.state.city && this.state.city[0]) {
      order.wilaya = this.state.city[0];
    } else {
      error.ercity = Translate("error", "eo1");
      contin = false;
    }
    if (this.state.district && this.state.district.id) {
      order.commune = this.state.district.id;
    } else {
      error.ercommune = Translate("error", "eo2");
      contin = false;
    }
    if (this.state.adresse) {
      order.destination_text = this.state.adresse;
    } else {
      error.eradress = Translate("error", "eo7");
      contin = false;
    }
    if (this.state.customerphonenumber) {
      order.customer_phone = this.state.customerphonenumber;
    } else {
      error.erphone = Translate("error", "eo5");
      contin = false;
    }
    if (this.state.customername) {
      order.customer_name = this.state.customername;
    } else {
      error.ername = Translate("error", "eo4");
      contin = false;
    }
    if (this.state.totalprice) {
      order.product_price = this.state.totalprice;
    } else {
      error.erprice = Translate("error", "eo6");
      contin = false;
    }
    /************ */
    if (contin) {
      this.submit = true;
      order.source = 1;
      order.express = this.state.express_delivery === "true";
      if (this.state.status === 50) {
        order.status = 11;
      }
      order.note_to_driver = this.state.notetodriver;
      order.products = this.state.product;
      if (this.state.permmisionedit) {
        switch (this.state.source) {
          case "unfOrder":
            Event("UPDATE_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
            order.status = 1;
            this.props.UpdateUnfOrder(order, this.state.id);
            break;
          case "search":
          case "order":
            Event("UPDATE_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
            this.props.UpdateOrder(order, this.state.id);
            break;
          default:
            break;
        }
      }
      if (this.state.permmisionadd && !this.state.source) {
        Event("ADD_ORDER", "USE_SUBMIT_BUTTON", "CLICK_EVENT");
        this.props.AddOrder(order);
      }
    } else {
      Event(
        "ADD_ORDER",
        "ERROR_INFORMATION_ENTER",
        `[${Object.keys(error).join(",")}]`
      );
      this.setState({
        error: error,
      });
    }
  }
  render() {
    const {
      OrderAdded,
      OrderUpdating,
      UpdinunfOrd,
      ProductFinded,
      dataAddOrder,
      dataUpInfOrd,
      DataUpdateOrder,
      dataWilaya,
      DataCommune,
      GetingWilaya,
      GetigCommune,
      dataWilayaStore,
      dataCommuneStore,
      dtStoreinfo,
    } = this.props;

    // if(Array.isArray(DataCommune)){
    //     var text=""
    //     DataCommune.map(elem=>{
    //         text+=elem.name+"\n"
    //     })
    //     var a = document.createElement("a");
    //     var file = new Blob([text], {type: text});
    //     a.href = URL.createObjectURL(file);
    //     a.download = "saida.txt";
    //     a.click();
    // }
    if (OrderAdded === "2" || UpdinunfOrd === "2") {
      this.props.GetOrders("");
      if (OrderAdded === "2") {
        this.props.SendAlert("41", Translate("alert", "ordadd"), "a01");
        return <Redirect to="/orders" />;
      } else {
        this.props.SendAlert("41", Translate("alert", "ordadd"), "a03");
        return <Redirect to={"/unfulfilled/order" + this.state.filtersource} />;
      }
    }
    let Alertfail = "";
    if (
      (OrderAdded === "3" || UpdinunfOrd === "3" || OrderUpdating === "3") &&
      this.submit
    ) {
      if (dataAddOrder && dataAddOrder.response && dataAddOrder.response.data) {
        Alertfail = Translate("error", dataAddOrder.response.data.detail);
      }
      if (dataUpInfOrd && dataUpInfOrd.response && dataUpInfOrd.response.data) {
        Alertfail = Translate("error", dataUpInfOrd.response.data.detail);
      }
      if (
        DataUpdateOrder &&
        DataUpdateOrder.response &&
        DataUpdateOrder.response.data
      ) {
        Alertfail = Translate("error", DataUpdateOrder.response.data.detail);
      }
      this.submit = false;
      this.setState({
        error: {},
      });
      this.props.SendAlert("50", Translate("alert", "failaddorde"), "a02");
    }
    if (
      this.isExternCity &&
      this.props.GetingWilaya === "1" &&
      Array.isArray(dataWilayaStore)
    ) {
      let wilaya = dataWilayaStore.find(
        (elem) => elem[1] === this.isExternCity
      );
      this.handleSelect(wilaya, "city");
      this.isExternCity = null;
    }

    if (!this.state.permmisionadd && !this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "addneworder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    if (!this.state.permmisionedit && this.state.source) {
      return (
        <ContainerPage page_title={Translate("orders", "editorder")}>
          <FeatureLoked />
        </ContainerPage>
      );
    }
    return (
      <ContainerPage page_title={this.state.titlepage}>
        {(OrderAdded === "1" ||
          OrderUpdating === "1" ||
          UpdinunfOrd === "1") && <Loader />}
        <div className="ClSidTh StBrdTb StBgbrds widthAdd">
          <div className="FlWd TxtCn StRedClr">{Alertfail}</div>
          <form method="post" onSubmit={this.handleSubmit} autoComplete="off">
            {this.state.allowed_editions.edit_products && (
              <ContainerPage
                subContainer
                page_title={Translate("orders", "orderinfo")}
                small_title={true}
              >
                {Array.isArray(this.state.product) &&
                  this.state.product.map((product, key) => {
                    return (
                      <div>
                        <div className="FlWd InFlx responsFlx flxDrc" key={key}>
                          <div className="Hlwd">
                            <SelectFormSearch
                              id={"productname_" + (key + 1)}
                              name={"productname_" + (key + 1)}
                              index={"logistical_description"}
                              option={this.state.productnames}
                              text={Translate("product", "productname")}
                              workfun={this.handleChangeProduct}
                              placeholder={
                                ProductFinded === "2"
                                  ? Translate("product", "productname")
                                  : Translate("alert", "load")
                              }
                              value={
                                this.state.product[key].logistical_description
                              }
                            />
                          </div>
                          <div className="Hlwd Mrg2 RlPs">
                            <div className="Flwd InFlx flxDrc AlgnItm">
                              <GroupeForm
                                id={"quantity_" + (key + 1)}
                                name={"quantity_" + (key + 1)}
                                placeholder={Translate("orders", "quantity")}
                                text={Translate("orders", "quantity")}
                                type={"number"}
                                value={this.state.product[key].quantity}
                                min={1}
                                workfun={this.handleChangeProduct}
                                style={{ width: "80%" }}
                              />
                              {this.state.nbproduct > 1 ? (
                                <div
                                  className="CrsPoi InFlx JstfCnt"
                                  style={{
                                    alignItems: "flex-end",
                                    margin: "5% 0% 0% 2%",
                                  }}
                                  onClick={() => this.RemoveFieldProduct(key)}
                                >
                                  {CloseSvg}
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                            <div></div>
                            {/* {this.state.nbproduct>1 ?
                                            <div className="StRedClr CrsPoi" onClick={()=>this.RemoveFieldProduct(key)}>-{" "+Translate("orders","removeproduct")}</div>:''} */}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                <span>{this.state.error.erproduct}</span>
                {this.state.notfound ? (
                  <div className="FlWd StdirLn">
                    <span className="CrsPoi">
                      {" " + Translate("product", "prdntfond")}
                    </span>
                    <Link to="/products/add" className="CrsPoi StBle">
                      {" " + Translate("product", "addnewproduct")}
                    </Link>
                  </div>
                ) : (
                  ""
                )}
                <div className="FlWd StdirLn">
                  <span className="StBle CrsPoi" onClick={this.AddFieldProduct}>
                    +{" " + Translate("orders", "addanotherproduct")}
                  </span>
                </div>
              </ContainerPage>
            )}
            <ContainerPage
              subContainer
              page_title={Translate("orders", "clientinfo")}
              small_title={true}
            >
              <div className="FlWd InFlx responsFlx flxDrc">
                <div className="Hlwd">
                  <GroupeForm
                    id={"customername"}
                    name={"customername"}
                    placeholder={Translate("orders", "customername")}
                    text={Translate("orders", "customername")}
                    type={"text"}
                    value={this.state.customername}
                    workfun={this.handleChange}
                    error={this.state.error.ername}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"customerphonenumber"}
                    name={"customerphonenumber"}
                    placeholder={Translate("orders", "customerphonenumber")}
                    text={Translate("orders", "customerphonenumber")}
                    type={"tel"}
                    pattern="^(((\+216)?[1-9]\d{7})|((0?|(00|\+)?213)[56789]\d{8}))$"
                    value={this.state.customerphonenumber}
                    workfun={this.handleChange}
                    error={this.state.error.erphone}
                  />
                </div>
              </div>
            </ContainerPage>
            <ContainerPage
              subContainer
              withOvrl={true}
              page_title={Translate("orders", "deliverydetails")}
              small_title={true}
            >
              <div
                className="FlWd InFlx responsFlx flxDrc"
                style={{ height: "100%" }}
              >
                <div className="Hlwd">
                  <GroupeForm
                    id={"adresse"}
                    name={"adresse"}
                    placeholder={Translate("orders", "adresse")}
                    text={Translate("orders", "adresse")}
                    type={"text"}
                    value={this.state.adresse}
                    workfun={this.handleChange}
                    error={this.state.error.eradress}
                  />
                  <div
                    className="FlWd InFlx responsFlx flxDrc"
                    style={{ margin: "28px 0 28px 0" }}
                  >
                    <div className="Hlwd">
                      <Select
                        label={Translate("orders", "city")}
                        placholder={Translate("orders", "citySearch")}
                        search={true}
                        Options={dataWilayaStore}
                        fieldShow={1}
                        name="city"
                        loading={GetingWilaya === "0"}
                        value={this.state.city}
                        onChange={
                          this.state.allowed_editions.edit_delivery_infos
                            ? this.handleSelect
                            : () => null
                        }
                        maxSize="200px"
                        readOnly={
                          !this.state.allowed_editions.edit_delivery_infos
                        }
                        disabled={true}
                      />
                    </div>
                    <div className="Hlwd Mrg2">
                      <Select
                        label={Translate("orders", "district")}
                        placholder={Translate("orders", "districtSearch")}
                        search={true}
                        Options={dataCommuneStore}
                        fieldShow={"name"}
                        name="district"
                        loading={GetigCommune === "0"}
                        value={this.state.district}
                        onChange={this.handleSelect}
                        maxSize="200px"
                      />
                    </div>
                  </div>
                  <GroupeForm
                    id={"orderprice"}
                    name={"orderprice"}
                    placeholder={Translate("orders", "totalprice")}
                    text={Translate("orders", "totalprice")}
                    type={"text"}
                    value={this.state.totalprice}
                    workfun={this.handleChange}
                    error={this.state.error.erprice}
                  />
                </div>
                <div className="Hlwd Mrg2">
                  <GroupeForm
                    id={"notetodriver"}
                    name={"notetodriver"}
                    placeholder={Translate("orders", "notetodriver")}
                    text={Translate("orders", "notetodriver")}
                    type={"text"}
                    value={this.state.notetodriver}
                    workfun={this.handleChange}
                    textarea={true}
                  />
                  {this.state.city && this.state.city[0] === 16 ? (
                    <div className="SmMarS2">
                      <SelectForm
                        id={"expressdelivery"}
                        name={"express_delivery"}
                        option={[
                          { text: Translate("orders", "no"), val: false },
                          { text: Translate("orders", "yes"), val: true },
                        ]}
                        value={this.state.express_delivery}
                        text={Translate("orders", "expressdelivery")}
                        workfun={this.handleChange}
                      />
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </ContainerPage>
            <span
              className="DsBlc FlWd"
              style={{
                height: "1px",
                backgroundColor: "var(--fntClr)",
                opacity: ".2",
              }}
            ></span>
            <div className="FlWd TxtCn StRedClr">
              {this.state.city &&
                !this.state.city.id &&
                this.state.city.name &&
                Translate("error", "Order out of our service")}
            </div>
            <div className="StMarMx stbold">
              <div className="PsinLan SmMarS">
                <span>{Translate("orders", "orderprice") + " :"}</span>
                <span className="Mrg2">
                  {lang == "ar"
                    ? this.state.orderprice +
                      "   " +
                      this.props.dtStoreinfo.country.ar_currency
                    : this.state.orderprice +
                      "   " +
                      this.props.dtStoreinfo.country.lt_currency}
                </span>
              </div>
              <div className="PsinLan SmMarS">
                <span>{Translate("orders", "deliveryprice") + " :"}</span>
                <span className="Mrg2">
                  {lang == "ar"
                    ? this.state.delivryprice +
                      "   " +
                      this.props.dtStoreinfo.country.ar_currency
                    : this.state.delivryprice +
                      "   " +
                      this.props.dtStoreinfo.country.lt_currency}
                </span>
              </div>
              <div className="PsinLan SmMarS">
                <span>{Translate("orders", "totalprice") + " :"}</span>
                <span className="Mrg2">
                  {lang == "ar"
                    ? this.state.totalprice +
                      "   " +
                      this.props.dtStoreinfo.country.ar_currency
                    : this.state.totalprice +
                      "   " +
                      this.props.dtStoreinfo.country.lt_currency}
                </span>
              </div>
            </div>

            <div className="MrAot StMarMx responseDiv" style={{ width: "25%" }}>
              <Button BtnText={this.state.titlebtn} />
            </div>
          </form>
        </div>
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href
      .replace(window.location.hash, "")
      .replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
        vars[key] = value !== undefined ? value : "";
      });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    GetingDelivery,
    dataDelivery,
    dataCountry,
    GetingPays,
    GetingWilayastore,
    dataWilayaStore,
    GetingCommunestore,
    dataCommuneStore,
  } = state.common;
  const { ProductFinded, data } = state.product;
  const {
    OrderAdded,
    dataAddOrder,
    OrderGeting,
    OrdersList,
    OrderUpdating,
    DataUpdateOrder,
  } = state.orders;
  const { GetinunfOrd, dataInfOrd, UpdinunfOrd, dataUpInfOrd } =
    state.unfOrders;
  const { DataSearch, Searching } = state.search;
  const { dataProfile, dtStoreinfo } = state.user;
  return {
    GetingWilaya,
    dataWilaya,
    GetigCommune,
    DataCommune,
    data,
    ProductFinded,
    OrderAdded,
    dataAddOrder,
    GetingDelivery,
    dataDelivery,
    OrderGeting,
    OrdersList,
    OrderUpdating,
    DataUpdateOrder,
    GetinunfOrd,
    dataInfOrd,
    UpdinunfOrd,
    dataUpInfOrd,
    DataSearch,
    Searching,
    dataProfile,
    dtStoreinfo,
    dataCountry,
    GetingPays,
    GetingWilayastore,
    dataWilayaStore,
    GetingCommunestore,
    dataCommuneStore,
  };
}

const actionCreators = {
  Getwilayas: commonActions.Getwilayas,
  Getcommunes: commonActions.Getcommunes,
  GetAllProduct: productActions.GetAllProduct,
  GetDelivery: commonActions.GetDelivery,
  AddOrder: ordersActions.AddOrder,
  UpdateOrder: ordersActions.UpdateOrder,
  GetOrders: ordersActions.GetOrders,
  UpdateUnfOrder: ordersActions.UpdateUnfOrder,
  SendAlert: alertActions.SendAlert,
  ClearStore: commonActions.ClearStore,
  GetCountry: commonActions.GetCountry,
  GetWilayasStore: commonActions.GetWilayasStore,
  GetCommuneStore: commonActions.GetCommuneStore,
};

const connectedAddOrderPage = connect(mapState, actionCreators)(AddOrderPage);
export { connectedAddOrderPage as AddOrderPage };
