import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Translate } from "../../../utils/lang/translate";
import { SocialMediaIcons } from "../_Common/IconSvg";
import { Event, initGAPg } from "../_Common";

import { connect } from "react-redux";

import "./Footer.scss";
import { commonActions, userActions } from "../../../actions";

function Footer(props) {
  useEffect(() => {
    props.GetInfoMaystro(props?.dtStoreinfo?.country?.id);
  }, []);
  useEffect(() => {
    initGAPg();
    props.GetProfileUser();
  }, []);

  const handleSocialLinkClick = (platform) => {
    Event("SOCIAL_MEDIA_FOLLOW", "FOLLOW_ON_" + platform, "CLICK_FROM_FOOTER");
  };
  const handleClickLink = (label) => {
    Event("FOOTER", "CLICK_ON_FOOTER_LINK", label.toUpperCase());
  };
  return (
    <footer
      className="hide-on-mobile ClSidTh FxPs StAbsvlm StBxSh StWdNvb StrVl Zindxsm stbd"
      style={{
        bottom: 0,
        insetInline: "auto",
        width: "calc(100% - var(--wdsid))",
      }}
    >
      <div className="InFlx flxDrc responsFlx FlHg FlWd RlPs">
        <div className="col-half TxtCn">
          &copy; Copyright {new Date().getFullYear()}
          <a href="https://maystro-delivery.com/"> Maystro Delivery </a>. All rights reserved.
        </div>

        <div className="InFlx flxDrc responsFlx spcEvn FlHg RlPs col-half">
          <a target="_blank" href="https://maystro-delivery.com/" onClick={() => handleClickLink("aboutus")} className="TxtCn">
            {Translate("footer", "aboutus")}
          </a>
          <Link to="/contactus" onClick={() => handleClickLink("contactus")} className="TxtCn">
            {Translate("footer", "contactus")}
          </Link>
          <Link to="/rubric" onClick={() => handleClickLink("rubric")} className="TxtCn">
            {Translate("footer", "help")}
          </Link>

          <div className="InFlx flxDrc spcEvn FlHg RlPs">
            <a target="blank" href={props?.dataMaystro?.Facebook} onClick={() => handleSocialLinkClick("FACEBOOK")}>
              {SocialMediaIcons.sm.Facebook}
            </a>
            <a target="blank" href={props?.dataMaystro?.Instagram} onClick={() => handleSocialLinkClick("IG")}>
              {SocialMediaIcons.sm.Instagram}
            </a>
            <a target="blank" href={props?.dataMaystro?.Tiktok} onClick={() => handleSocialLinkClick("TIKTOK")}>
              {SocialMediaIcons.sm.TikTok}
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}

Footer.propTypes = {};

function mapState(state) {
  const { GetingInfomaystro, dataMaystro } = state.common;
  const { dtStoreinfo } = state.user;
  return { GetingInfomaystro, dataMaystro, dtStoreinfo };
}

const actionCreators = {
  GetInfoMaystro: commonActions.GetInfoMaystro,
  GetStoreInfo: userActions.GetStoreInfo,
  GetProfileUser: userActions.GetProfileUser,
};

const connectedFooterPage = connect(mapState, actionCreators)(Footer);
export { connectedFooterPage as Footer };
