import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate, FormatCurrency, FormatDate } from "../../../utils/lang/translate";
import Table from "../_Common/Table";
import { alertActions, ordersActions, searchActions } from "../../../actions";
import GroupeBtnSp from "../_Common/GroupeBtnSp";
import { FlechSvg, DeletSvg, EditSvg, PackageSvg, CarSvg, PriceSvg, LocationSvg, PersonSvg, CallSvg, TransitSvg, PendingSvg, ShipedSvg, ManagentSvg, CreatedSvg, WaitingSvg, DelivredSvg, CancledSvg, ToReturnSvg, PostpondSvg, ExpressSvg, StoreSvg, InHubSvg, CachbackSvg, ExchangeSvg, RefreshSvg, OutOfStockSvg } from "../_Common/IconSvg";
import ListDetailIcon from "../_Common/ListDetailIcon";
import { Link } from "react-router-dom";
import Loader from "../../_Common/Loader";
import BtnLg from "../_Common/BtnLg";
import TabShow from "../_Common/TabShow";
import { initGAPg, Event } from "../_Common";

const getStatusIcon = (status) => {
  switch (status) {
    case 4:
      return CreatedSvg;
    case 5:
      return WaitingSvg;
    case 8:
      return InHubSvg;
    case 9:
      return TransitSvg;
    case 10:
      return TransitSvg;
    case 11:
      return CreatedSvg;
    case 12:
      return OutOfStockSvg;
    case 15:
      return PendingSvg;
    case 31:
      return ShipedSvg;
    case 32:
      return ShipedSvg;
    case 41:
      return DelivredSvg;
    case 42:
      return PostpondSvg;
    case 50:
      return CancledSvg;
    case 51:
      return WaitingSvg;
    case 52:
      return ToReturnSvg;
    case 53:
      return CancledSvg;

    default:
      return CarSvg;
  }
};
const getStatusIconColor = (status) => {
  switch (status) {
    case 4:
    case 5:
      return "ElmSvgWt";
    case 8:
    case 9:
      return "StBlFil";
    case 11:
      return "ElmSvgWt";
    case 12:
      return "StNoFil";
    case 15:
    case 31:
      return "StBlFil";
    case 32:
      return "ElmSvgWarn";
    case 41:
      return "ElmSvgDl";
    case 42:
      return "ElmSvgPs";
    case 50:
      return "ElmSvgCn";
    case 51:
      return "ElmSvgGrey";
    case 52:
      return "ElmSvgDl";
    case 53:
      return "ElmSvgCn";

    default:
      return "ElmSvgWt";
  }
};
class OrdersDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titlepage: (
        <div>
          <Link className="StOpcVal" to="/orders">
            {Translate("titles", "order")}
          </Link>
          " ➔ "
        </div>
      ),
      product: [],
      orderproducts: [],
      is_exchange: false,
      is_refund: false,
      totalprice: 0,
      productprice: 0,
      deliveryprice: 0,
      expressdeliery: null,
      maxdate: "",
      adress: "",
      clientname: "",
      phoneclient: "",
      deliverystatus: "",
      id: "",
      status: "",
      pagesource: 1,
      alertdelet: null,
      agent: null,
      created_at: null,
      in_hub_at: null,
      ready_to_shipped_at: null,
      delivered_at: null,
      shipped_at: null,
      taken_by_store_at: null,
      premisionedit: this.props.dataProfile.is_store || this.props.dataProfile.change_order,
    };
    this.IndexState = 0;
    this.GotToEdit = this.GotToEdit.bind(this);
    this.removeOrder = this.removeOrder.bind(this);
  }
  ConcatProduStr(product) {
    return (
      Array.isArray(product) &&
      product.map((elem) => {
        return [<div className="wharp_spce">{" (" + elem.quantity + ") " + elem.logistical_description}</div>];
      })
    );
  }
  componentWillReceiveProps(nextProps) {
    const {
      match: { params },
      OrderUpdSta,
      OrderGeting,
      OrdersList,
    } = nextProps;

    if (this.submit && this.props.OrderUpdSta === "0" && OrderUpdSta === "2") {
      this.submit = false;
      this.props.SendAlert("50", Translate("alert", "failupdateorder"), "");
    }
    if (this.submit && this.props.OrderUpdSta === "0" && OrderUpdSta === "1") {
      this.submit = false;
      this.props.SendAlert("41", Translate("alert", "successcancelorder"), "");
      if (this.state.source === "order") {
        this.props.history.push("/orders");
      } else if (this.state.source === "search") {
        this.props.history.push("/search" + this.state.pagesource);
        this.props.SearchOrders(this.state.pagesource);
      }
    }
    if (this.props.OrderGeting === "1" && OrderGeting === "2") {
      let idorder = params.idorder;
      if (idorder) {
        let order = OrdersList.ressuc.list.results.filter((elem) => elem.display_id === idorder)[0];
        if (order) {
          this.setState({
            titlepage: (
              <div className="StDrc">
                <Link className="StOpcVal" to={"/orders" + OrdersList.filter}>
                  {Translate("titles", "order")}
                </Link>
                <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
                <div className="Inlflx">{Translate("orders", "orderdetail")}</div>
                <div className="Inlflx StWdDst">#</div>
                <div className="Inlflx">{idorder}</div>
              </div>
            ),
            product: this.ConcatProduStr(order.products),
            orderproducts: order.products,
            is_exchange: order.is_exchange,
            exchanged_for_order: order.exchanged_for_order,
            is_refund: order.is_refund,
            refunded_for_order: order.refunded_for_order,
            totalprice: order.product_price + order.price,
            productprice: order.product_price,
            deliveryprice: order.price,
            expressdeliery: order.express ? Translate("orders", "yes") : Translate("orders", "no"),
            maxdate: order.max_delivery_date,
            wilaya: order.wilaya,
            commune_name: order.commune_name,
            adress: order.destination_text,
            clientname: order.customer_name,
            phoneclient: order.customer_phone,
            deliverystatus: order.status,
            id: order.id,
            display_id: order.display_id,
            status: order.status,
            pagesource: this.state.pagesource,
            agent: order.agent,
            created_at: order.ordered_at,
            pickup_requested_at: order.pickup_requested_at,
            in_hub_at: order.in_hub_at,
            in_transit_at: order.in_transit_at,
            ready_to_shipped_at: order.ready_to_shipped_at,
            delivered_at: order.delivered_at,
            shipped_at: order.shipped_at,
            postponed_to: order.postponed_to,
            taken_by_store_at: order.taken_by_store_at,
            dataCancel: order.aborted_at,
          });
        }
      } else {
        this.props.history.push("/orders" + this.state.pagesource);
      }
    }
  }
  componentDidUpdate() {
    var UpdateStatus = document.querySelector(".HidLstELm_" + (this.IndexState - 1));
    if (UpdateStatus && UpdateStatus.style) {
      UpdateStatus.style.visibility = "hidden";
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "order");
    initGAPg();
    const {
      match: { params },
    } = this.props;
    var idorder = params.idorder;
    var source = this.$_GET("source");
    var dataCommed = null;
    var externTitle, FilterSource;
    if (source === "order") {
      const { OrderGeting, OrdersList } = this.props;
      if (OrderGeting !== "2") {
        this.props.history.push("/orders");
      } else {
        dataCommed = OrdersList.ressuc.list.results.filter((elem) => elem.display_id === idorder)[0];
        FilterSource = OrdersList.filter;
        externTitle = (
          <div className="StDrc">
            <Link onClick={() => Event("ORDER_DETAIL", "USE_BACK_BUTTON_TO_ORDERS", "CLICK_EVENT")} className="StOpcVal" to={"/orders" + FilterSource}>
              {Translate("titles", "order")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("orders", "orderdetail")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{idorder}</div>
          </div>
        );
      }
    } else if (source === "search") {
      const { Searching, DataSearch } = this.props;
      if (Searching !== "1") {
        this.props.history.push("/search");
      } else {
        dataCommed = DataSearch.ressuc.results.filter((elem) => elem.display_id === idorder)[0];
        FilterSource = DataSearch.filter;
        externTitle = (
          <div className="StDrc">
            <Link onClick={() => Event("ORDER_DETAIL", "USE_BACK_BUTTON_TO_SEARCH", "CLICK_EVENT")} className="StOpcVal" to={"/search" + FilterSource}>
              {Translate("titles", "search")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("orders", "orderdetail")}</div>
            <div className="Inlflx StWdDst">#</div>
            <div className="Inlflx">{idorder}</div>
          </div>
        );
      }
    } else {
      this.props.history.push("/orders");
    }
    if (dataCommed) {
      this.setState({
        titlepage: externTitle,
        product: this.ConcatProduStr(dataCommed.products),
        orderproducts: dataCommed.products,
        is_exchange: dataCommed.is_exchange,
        exchanged_for_order: dataCommed.exchanged_for_order,
        is_refund: dataCommed.is_refund,
        refunded_for_order: dataCommed.refunded_for_order,
        totalprice: dataCommed.product_price + dataCommed.price,
        productprice: dataCommed.product_price,
        deliveryprice: dataCommed.price,
        expressdeliery: dataCommed.express ? Translate("orders", "yes") : Translate("orders", "no"),
        maxdate: dataCommed.max_delivery_date,
        wilaya: dataCommed.wilaya,
        notDrive: dataCommed.note_to_driver,
        commune_name: dataCommed.commune_name,
        adress: dataCommed.destination_text,
        clientname: dataCommed.customer_name,
        phoneclient: dataCommed.customer_phone,
        deliverystatus: dataCommed.status,
        id: dataCommed.id,
        display_id: dataCommed.display_id,
        status: dataCommed.status,
        pagesource: FilterSource,
        agent: dataCommed.agent,
        created_at: dataCommed.ordered_at,
        pickup_requested_at: dataCommed.pickup_requested_at,
        in_hub_at: dataCommed.in_hub_at,
        in_transit_at: dataCommed.in_transit_at,
        ready_to_shipped_at: dataCommed.ready_to_shipped_at,
        delivered_at: dataCommed.delivered_at,
        shipped_at: dataCommed.shipped_at,
        postponed_to: dataCommed.postponed_to,
        taken_by_store_at: dataCommed.taken_by_store_at,
        cnlcreaison: dataCommed.abort_reason,
        dataCancel: dataCommed.aborted_at,
        source: source,
      });
      this.props.GetOrderHistory(dataCommed.id);
    }
  }
  GotToEdit(path) {
    Event("ORDER_DETAIL", "GO_TO_EDIT_FROM_DETAIL_PAGE", "CLICK_EVENT");
    this.props.history.push(path);
  }
  removeOrder(id) {
    Event("ORDER_DETAIL", "REMOVE_ORDER_FROM_DETAIL", "CLICK_EVENT");
    let idremove = this.state.alertdelet;
    this.submit = true;
    if (idremove) {
      this.props.UpdateStaOrder({ status: 50 }, idremove);
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }
  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  trigger_action(action, id) {
    switch (action) {
      case "edit":
        this.GotToEdit("/order/add/" + id + "?source=order");
        break;
      case "cancel":
        this.removeOrder(id);
        break;
      case "exchange":
        Event("ORDER_DETAIL", "MAKE_EXCHANGE_FROM_DETAIL", "CLICK_EVENT");
        this.props.history.push("/order/exchange/" + id);
        break;
      case "refund":
        Event("ORDER_DETAIL", "MAKE_REFUND_FROM_DETAIL", "CLICK_EVENT");
        this.props.history.push("/order/reimbursement/" + id);
        break;
      default:
        break;
    }
  }
  render() {
    const { OrderUpdSta, OrderHist, dtStoreinfo } = this.props;
    let OrderHistory = (OrderHist && OrderHist.ressuc && Array.isArray(OrderHist.ressuc) && OrderHist.ressuc.length && OrderHist.ressuc.reverse().filter((el) => el.status !== 22)) || [];

    let btns,
      act_btns = [];
    if (this.state.premisionedit) {
      const { id, display_id, refunded_for_order, exchanged_for_order, is_refund } = this.state;
      const EDIT_ACTION = { type: "BDgInpc", action: () => this.trigger_action("edit", display_id), svgbtn: EditSvg };
      const DELETE_ACTION = { type: "BDgInpc", action: () => this.trigger_action("cancel", id), svgbtn: DeletSvg };

      if (this.state.status < 31) act_btns.push(EDIT_ACTION);
      if ([4, 11, 12].indexOf(this.state.status) > -1) act_btns.push(DELETE_ACTION);
      if (dtStoreinfo.stock_managed && this.state.status === 50) act_btns.push({ type: "BDgInpc", action: () => this.trigger_action("relaunch", display_id), svgbtn: RefreshSvg, title: "Relancer la commande" });

      if (this.state.status === 41) {
        let acts = [];
        if ((dtStoreinfo && dtStoreinfo.is_canary && dtStoreinfo.is_canary.toString() === "true") || dtStoreinfo.stock_managed) {
          if (!refunded_for_order && !is_refund) acts.push({ type: "BDgInpc", action: () => this.trigger_action("refund", display_id), svgbtn: CachbackSvg, title: Translate("orders", "makerefund") });

          if (!exchanged_for_order && !is_refund) acts.push({ type: "BDgInpc", action: () => this.trigger_action("exchange", display_id), svgbtn: ExchangeSvg, title: Translate("orders", "makeexchange") });
          act_btns = acts;
        }
      } else {
        // act_btns.push({type:"BDgInpc",action:()=>this.GotToEdit("/order/report/"+this.state.id),svgbtn:WarningSvg})
      }
    }
    btns = <GroupeBtnSp data_btn={act_btns} />;

    this.IndexState = 0;
    let statuship = [[<ListDetailIcon icon={CreatedSvg} Styleicon="ElmSvgWt" title={Translate("statusorder", "created")} details={[FormatDate(this.state.created_at), <span className={"StAbs status_4 LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]];
    this.IndexState += 1;
    if (this.state.pickup_requested_at) {
      statuship.push([<ListDetailIcon icon={WaitingSvg} Styleicon="ElmSvgWt" title={Translate("statusorder", "5")} details={[FormatDate(this.state.pickup_requested_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.in_hub_at) {
      statuship.push([<ListDetailIcon icon={WaitingSvg} Styleicon="StBlFil" title={Translate("statusorder", "8")} details={[FormatDate(this.state.in_hub_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.in_transit_at) {
      statuship.push([<ListDetailIcon icon={TransitSvg} Styleicon="StBlFil" title={Translate("statusorder", "9")} details={[FormatDate(this.state.in_transit_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.ready_to_shipped_at) {
      statuship.push([<ListDetailIcon icon={PendingSvg} Styleicon="StBlFil" title={Translate("statusorder", "15")} details={[FormatDate(this.state.ready_to_shipped_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.shipped_at) {
      statuship.push([<ListDetailIcon icon={ShipedSvg} Styleicon="StBlFil" title={Translate("statusorder", "31")} details={[FormatDate(this.state.shipped_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.postponed_to) {
      statuship.push([<ListDetailIcon icon={PostpondSvg} Styleicon="ElmSvgPs" title={Translate("statusorder", "42")} details={[FormatDate(this.state.postponed_to), <span className={"StAbs status_42 LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.delivered_at) {
      statuship.push([<ListDetailIcon icon={DelivredSvg} Styleicon="ElmSvgDl" title={Translate("statusorder", "41")} details={[FormatDate(this.state.delivered_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.dataCancel) {
      statuship.push([<ListDetailIcon icon={CancledSvg} Styleicon="ElmSvgCn" title={Translate("statusorder", "50")} details={[FormatDate(this.state.dataCancel), this.state.cnlcreaison ? Translate("abortRason", this.state.cnlcreaison) : Translate("abortRason", "rsnunkon"), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.ready_to_return_at) {
      statuship.push([<ListDetailIcon icon={CancledSvg} Styleicon="ElmSvgCn" title={Translate("statusorder", "51")} details={[FormatDate(this.state.ready_to_return_at), <span className={"StAbs BdgBlcl status_42 LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    if (this.state.status + "" === "52") {
      statuship.push([<ListDetailIcon icon={ToReturnSvg} Styleicon="ElmSvgDl" title={Translate("statusorder", "52")} details={[FormatDate(this.state.taken_by_store_at), <span className={"StAbs BdgBlcl LeftSet StTpval2 WdSmP HidLstELm_" + this.IndexState} style={{ height: "100%" }}></span>]} />]);
      this.IndexState += 1;
    }
    let statusTimeline =
      OrderHistory && OrderHistory.length
        ? OrderHistory.map((row, index) => {
            let details = [FormatDate(row.created_at)].concat(row.status === 50 ? (this.state.cnlcreaison ? [Translate("abortRason", this.state.cnlcreaison)] : [Translate("abortRason", "rsnunkon")]) : []).concat([<span className={`StAbs status_${row.status} LeftSet StTpval2 WdSmP HidLstELm_${index}`} style={{ height: "100%" }}></span>]);
            return [<ListDetailIcon icon={getStatusIcon(row.status)} Styleicon={getStatusIconColor(row.status)} title={Translate("statusorder", row.status + "")} details={details} />];
          })
        : [];
    if (statusTimeline.length > statuship.length) this.IndexState = OrderHistory && OrderHistory.length ? statusTimeline.length : 0;

    let ProductsDiv;
    if (this.state.orderproducts.filter((el) => el.is_exchange === true).length === 0) ProductsDiv = [[<ListDetailIcon icon={PackageSvg} title={Translate("product", "productname")} details={this.ConcatProduStr(this.state.orderproducts.filter((el) => el.is_exchange === false))} />]];
    else
      ProductsDiv = [
        [<ListDetailIcon icon={PackageSvg} title={Translate("product", "productname")} details={this.ConcatProduStr(this.state.orderproducts.filter((el) => el.is_exchange === false))} />],
        [
          <ListDetailIcon
            icon={
              <div className="StMrtg2 InFlx Stclmnf AlgnItm JstfCnt StBlFil">
                {PackageSvg} {FlechSvg}
              </div>
            }
            title={Translate("orders", "productstoreturn")}
            details={this.ConcatProduStr(this.state.orderproducts.filter((el) => el.is_exchange === true))}
          />,
        ],
      ];
    return (
      <ContainerPage page_title={this.state.titlepage} data_top={btns}>
        <div className="FlWd InFlx responsFlx flxDrc">
          {OrderUpdSta === "0" && <Loader />}
          <div className="Hlwd">
            <Table
              ntdir={true}
              thead_elem={[Translate("orders", "detailorder")]}
              tbody_elem={[
                ...ProductsDiv,
                [
                  <div className="FlWd InFlx responsFlx flxDrc">
                    {this.props?.dtStoreinfo?.country?.id === 1 ? (
                      <div>
                        {" "}
                        <div className="Hlwd">
                          <ListDetailIcon icon={PriceSvg} title={this.state.is_exchange ? Translate("exchanges", "clientamount") : this.state.is_refund ? Translate("exchanges", "amountrefund") : Translate("orders", "totalprice")} details={[FormatCurrency.format(this.state.is_refund || this.state.is_exchange ? this.state.productprice : this.state.totalprice)]} />
                        </div>
                        <div className="Hlwd Mrg2">
                          <ListDetailIcon icon={CarSvg} title={Translate("orders", "deliveryprice")} details={[FormatCurrency.format(this.state.deliveryprice)]} />
                        </div>{" "}
                      </div>
                    ) : (
                      <div>
                        {" "}
                        <div className="Hlwd">
                          <ListDetailIcon icon={PriceSvg} title={this.state.is_exchange ? Translate("exchanges", "clientamount") : this.state.is_refund ? Translate("exchanges", "amountrefund") : Translate("orders", "totalprice")} details={[this.state.is_refund || this.state.is_exchange ? this.state.productprice : this.state.totalprice + "  " + "TND"]} />
                        </div>
                        <div className="Hlwd Mrg2">
                          <ListDetailIcon icon={CarSvg} title={Translate("orders", "deliveryprice")} details={[this.state.deliveryprice + "  " + "TND"]} />
                        </div>{" "}
                      </div>
                    )}
                  </div>,
                ],
                [
                  <div className="FlWd InFlx responsFlx flxDrc">
                    <div className="Hlwd">
                      <ListDetailIcon icon={LocationSvg} title={Translate("orders", "adresse")} details={[this.state.adress]} />
                    </div>
                    <div className="Hlwd Mrg2">
                      <ListDetailIcon icon={LocationSvg} title={Translate("orders", "district")} details={[this.state.commune_name + " ( " + this.state.wilaya + " ) "]} />
                    </div>
                  </div>,
                ],
                [<ListDetailIcon icon={ExpressSvg} title={Translate("orders", "expressdelivery")} details={[this.state.expressdeliery]} />],
                [<ListDetailIcon icon={PersonSvg} title={Translate("orders", "customername")} details={[this.state.clientname]} />],
                [<ListDetailIcon icon={CallSvg} title={Translate("orders", "customerphonenumber")} details={[this.state.phoneclient]} />],
              ]}
            />
            <div className="StMrtp">
              <Table ntdir={true} thead_elem={[Translate("orders", "notes")]} tbody_elem={[[<ListDetailIcon icon={<i className="InFlx StBlFil st_smpld">{StoreSvg}</i>} title={Translate("orders", "notetodriver")} details={[<div className="wharp_spce">{this.state.notDrive}</div>]} />]]} />
            </div>
          </div>
          <div className="Hlwd Mrg2">
            <div>
              <Table ntdir={true} thead_elem={[Translate("orders", "delivrystatus")]} tbody_elem={statusTimeline.length >= statuship.length || new Date(this.state.created_at) >= new Date("10-08-2021") ? statusTimeline : statuship} />
            </div>
            {this.state.agent && (
              <div className="StMrtp">
                <Table ntdir={true} thead_elem={[Translate("orders", "deliveryagent")]} tbody_elem={[[<ListDetailIcon icon={ManagentSvg} title={Translate("auths", "fullname")} details={[this.state.agent.first_name + " " + this.state.agent.last_name]} />], [<ListDetailIcon icon={CallSvg} title={Translate("auths", "phone")} details={[this.state.agent.phone]} />]]} />
              </div>
            )}
          </div>
        </div>
        {this.state.alertdelet && (
          <TabShow tab_title={Translate("profile", "confirmaction")} wdth="550px" NotCls={true}>
            <div className="MrAot" style={{ width: "94%" }}>
              <p className="StdirLn FlWd DltTpMar">{Translate("orders", "confrmdelete")}</p>
              <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.showOpt("alertdelet")}>
                  <BtnLg text={Translate("profile", "cancel")} classSet={""} />
                </div>
                <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={this.removeOrder}>
                  <BtnLg text={Translate("profile", "delete")} classSet={"TxDng"} />
                </div>
              </div>
            </div>
          </TabShow>
        )}
      </ContainerPage>
    );
  }
  $_GET(param) {
    var vars = {};
    window.location.href.replace(window.location.hash, "").replace(/[?&]+([^=&]+)=?([^&]*)?/gi, function (m, key, value) {
      vars[key] = value !== undefined ? value : "";
    });
    if (param) {
      return vars[param] ? vars[param] : null;
    }
    return vars;
  }
}
function mapState(state) {
  const { OrderGeting, OrdersList, GettingOrderHist, OrderHist, OrderUpdSta, DataUpdSta } = state.orders;
  const { Searching, DataSearch } = state.search;
  const { dtStoreinfo, dataProfile } = state.user;
  return { OrderGeting, OrdersList, GettingOrderHist, OrderHist, OrderUpdSta, DataUpdSta, Searching, DataSearch, dtStoreinfo, dataProfile };
}
const actionCreators = {
  GetOrders: ordersActions.GetOrders,
  GetOrderHistory: ordersActions.GetOrderHistory,
  UpdateStaOrder: ordersActions.UpdateStaOrder,
  SearchOrders: searchActions.SearchOrders,
  SendAlert: alertActions.SendAlert,
};

const connectedOrdersDetailPage = connect(mapState, actionCreators)(OrdersDetailPage);
export { connectedOrdersDetailPage as OrdersDetailPage };
