import React, { useState, useEffect } from "react";
import GroupeForm from "../../../_Common/GroupeForm";
import Button from "../../../_Common/Button";
import { Translate } from "../../../../utils/lang/translate";
import { DataShop, UpdateDataShopSettings } from "../../../../types";
import useExitPrompt from "../../../../utils/useExitPrompt";
import { Prompt } from "react-router-dom";
import { Event } from "../../_Common";

interface Props {
  dataShop: DataShop;
  history: any;
  UpdateDataShop: (
    data: DataShop,
    settings: UpdateDataShopSettings,
    successCallback?: () => void
  ) => void;
  SendAlert: (code: string, text: string, action?: string) => void;
}

const FacebookSettingsForm: React.FC<Props> = ({
  dataShop,
  history,
  UpdateDataShop,
  SendAlert,
}) => {
  const [pixel_key, setPixelKey] = useState("");
  const [isChange, setIsChange] = useExitPrompt(false);

  useEffect(() => {
    return () => {
      setIsChange(false);
    };
  }, []);

  useEffect(() => {
    if (typeof dataShop?.pixel_key !== "undefined")
      setPixelKey(dataShop.pixel_key);
  }, [dataShop]);

  const handleChange: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => void = (event) => {
    const { value } = event.target as HTMLInputElement;
    setPixelKey(value);
    setIsChange(true);
  };

  const handleSkip = () => {
    Event("SHOP", "META_KEY_SKIP", "CLICK_EVENT");
    UpdateDataShop(
      dataShop,
      {
        settings_stage:
          dataShop.settings_stage <= 3 ? 4 : dataShop.settings_stage,
      },
      () => {
        history.push("/settings/shop/finish");
      }
    );
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    Event("SHOP", "META_KEY_SAVE", "CLICK_EVENT");
    e.preventDefault();
    if (dataShop.published || pixel_key.length > 0) {
      UpdateDataShop(
        dataShop,
        {
          pixel_key,
          settings_stage:
            dataShop.settings_stage <= 3 ? 4 : dataShop.settings_stage,
        },
        () => {
          if (!dataShop.published) history.push("/settings/shop/finish");
          else SendAlert("41", Translate("shop", "changesSaved"));
        }
      );
    }
  };

  return (
    <>
      <Prompt when={isChange} message={Translate("alert", "prompt")} />
      <form
        className="FacebookSettingsForm"
        method="post"
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <GroupeForm
          id={"pixel_key"}
          name={"pixel_key"}
          placeholder={Translate("shop", "facebookKeyPlaceholder")}
          text={Translate("shop", "pixel_key")}
          type={"pixel_key"}
          value={pixel_key}
          workfun={handleChange}
        />
        <a
          href="https://www.facebook.com/business/help/952192354843755?id=1205376682832142"
          target="_blank"
          className="link"
        >
          {Translate("shop", "getFacebookKey")}
        </a>
        <div className="buttons">
          {!dataShop.published && (
            <Button
              BtnText={Translate("shop", "skip")}
              onClick={handleSkip}
              type="button"
              gray
            />
          )}
          <Button
            BtnText={Translate(
              "shop",
              !dataShop.published ? "addPixel" : "save"
            )}
            disabled={!dataShop.published && pixel_key?.length < 1}
            type="submit"
          />
        </div>
      </form>
    </>
  );
};

export default FacebookSettingsForm;
