import { productConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
const store_id = Cookies.get("c_store");
export const productActions = {
  AddProduct,
  GetAllProduct,
  GetProductPage,
  ViewStockProduct,
  GetAvailableQuantity,
  ViewStockDetails,
  GetProductHistory,
  GetProductPerformance,
  UpdateProduct,
  DeletProduct,
  GetUnlinkedProduct,
  GetAllReceiveProducts,
  GetAllexitsProducts,
  GetDetailsReceiveProducts,
  GetDetailsTakenProducts,
};
function AddProduct(product) {
  const { product_name, product_id } = product;
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.ADD_PRODUCT_REQUEST));
    RequestService.PostRequest(
      "stores/product/",
      {
        logistical_description: product_name,
        product_id: product_id,
        store_id: store_id,
      },
      { Authorization: token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res, productConstants.ADD_PRODUCT_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, productConstants.ADD_PRODUCT_FAILURE)
        );
      });
  };
}
function GetAllProduct() {
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.GET_PRODUCT_REQUEST));
    RequestService.GetRequest("stores/product_all/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res.data, productConstants.GET_PRODUCT_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_PRODUCT_FAILURE
          )
        );
      });
  };
}
function GetProductPage(filter) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(productConstants.GET_PRODUCT_PAGE_REQUEST)
    );
    RequestService.GetRequest("stores/product/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_PRODUCT_PAGE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_PRODUCT_PAGE_FAILURE
          )
        );
      });
  };
}
function ViewStockProduct(idproduct) {
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.STOCK_PRODUCT_REQUEST));
    let url =
      "stores/view_stock/?store_id=" +
      store_id +
      (idproduct && idproduct !== "" ? "&product_id=" + idproduct : "");
    RequestService.GetRequest(url, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.STOCK_PRODUCT_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.STOCK_PRODUCT_FAILURE
          )
        );
      });
  };
}
function GetAvailableQuantity(idproduct) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(productConstants.GET_AVAILABLE_QUANTITY_REQUEST)
    );
    let url = "stores/availibale_quantity/" + idproduct;
    RequestService.GetRequest(url, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_AVAILABLE_QUANTITY_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_AVAILABLE_QUANTITY_FAILURE
          )
        );
      });
  };
}

function ViewStockDetails(idproduct) {
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.STOCK_PRODUCT_REQUEST));
    let url =
      "stores/stock_detail/?store_id=" +
      store_id +
      (idproduct && idproduct !== "" ? "&product_id=" + idproduct : "");
    RequestService.GetRequest(url, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.STOCK_PRODUCT_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.STOCK_PRODUCT_FAILURE
          )
        );
      });
  };
}
function GetProductHistory(productid, filter) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(productConstants.GET_PRODUCT_HISTORY_REQUEST)
    );
    let url = "stores/history_product/" + filter;
    RequestService.PostRequest(
      url,
      { product: productid },
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_PRODUCT_HISTORY_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_PRODUCT_HISTORY_FAILURE
          )
        );
      });
  };
}
function GetProductPerformance(productid, filter) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(productConstants.GET_PRODUCT_PERFORMANCE_REQUEST)
    );
    let url = "stores/performance_product/" + filter;
    RequestService.PostRequest(
      url,
      { product: productid },
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_PRODUCT_PERFORMANCE_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_PRODUCT_PERFORMANCE_FAILURE
          )
        );
      });
  };
}
function UpdateProduct(product) {
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.UPDATE_PRODUCT_REQUEST));
    let url = "stores/product/" + product.id + "/";
    RequestService.PatchRequest(url, product, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, productConstants.UPDATE_PRODUCT_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.UPDATE_PRODUCT_FAILURE
          )
        );
      });
  };
}
function DeletProduct(idproduct) {
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.DELETE_PRODUCT_REQUEST));
    let url = "stores/product/" + idproduct + "/";
    RequestService.PatchRequest(
      url,
      { deleted: true },
      { Authorization: "Token " + token }
    )
      .then((res) => {
        dispatch(
          FunctionService.succes(res, productConstants.DELETE_PRODUCT_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.DELETE_PRODUCT_FAILURE
          )
        );
      });
  };
}

/* $ -----------$*/
function GetUnlinkedProduct() {
  return (dispatch) => {
    dispatch(FunctionService.request(productConstants.UNLINK_PRODUCT_REQUEST));
    RequestService.GetRequest("stores/product_variant/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.UNLINK_PRODUCT_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.UNLINK_PRODUCT_FAILURE
          )
        );
      });
  };
}

/* $ -----------$*/

function GetAllReceiveProducts(page) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(
        productConstants.GET_ALL_RECEIVED_PRODUCTS_REQUEST
      )
    );
    RequestService.GetRequest(page ? page : "delivery/vouchers/receipt/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_ALL_RECEIVED_PRODUCTS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_ALL_RECEIVED_PRODUCTS_FAILURE
          )
        );
      });
  };
}
function GetAllexitsProducts(page) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(productConstants.GET_ALL_EXIT_PRODUCTS_REQUEST)
    );
    RequestService.GetRequest(page ? page : "delivery/vouchers/exit/", {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_ALL_EXIT_PRODUCTS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_ALL_EXIT_PRODUCTS_FAILURE
          )
        );
      });
  };
}

function GetDetailsReceiveProducts(idProd) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(
        productConstants.GET_DETAILS_RECEIVED_PRODUCTS_REQUEST
      )
    );
    RequestService.GetRequest("delivery/vouchers/receipt/" + idProd, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_DETAILS_RECEIVED_PRODUCTS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_DETAILS_RECEIVED_PRODUCTS_FAILURE
          )
        );
      });
  };
}

function GetDetailsTakenProducts(idProd) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(
        productConstants.GET_DETAILS_TAKEN_PRODUCTS_REQUEST
      )
    );
    RequestService.GetRequest("delivery/vouchers/exit/" + idProd, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            productConstants.GET_DETAILS_TAKEN_PRODUCTS_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            productConstants.GET_DETAILS_TAKEN_PRODUCTS_FAILURE
          )
        );
      });
  };
}
