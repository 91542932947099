import React, { Component } from "react";
import { connect } from "react-redux";
import ContainerPage from "../_Common/ContainerPage";
import { Translate, FormatCurrency, FormatDate } from "../../../utils/lang/translate";
import Table from "../_Common/Table";
import { ordersActions, searchActions } from "../../../actions";
import { PackageSvg, PriceSvg, LocationSvg, PersonSvg, CallSvg, TransitSvg, PendingSvg, ShipedSvg, ManagentSvg, CreatedSvg, WaitingSvg, DelivredSvg, CancledSvg, ToReturnSvg, PostpondSvg, StoreSvg, InHubSvg } from "../_Common/IconSvg";
import ListDetailIcon from "../_Common/ListDetailIcon";
import { Link } from "react-router-dom";
import Loader from "../../_Common/Loader";
import BtnLg from "../_Common/BtnLg";
import TabShow from "../_Common/TabShow";
import { initGAPg, Event } from "../_Common";
import { ORDER_HELPERS } from "../../helpers/functions";

const getStatusIcon = (status) => {
  switch (status) {
    case 4:
      return CreatedSvg;
    case 5:
      return WaitingSvg;
    case 8:
      return InHubSvg;
    case 9:
      return TransitSvg;
    case 10:
      return TransitSvg;
    case 11:
      return CreatedSvg;
    case 15:
      return PendingSvg;
    case 31:
      return ShipedSvg;
    case 41:
      return DelivredSvg;
    case 42:
      return PostpondSvg;
    case 50:
      return CancledSvg;
    case 51:
      return WaitingSvg;
    case 52:
      return ToReturnSvg;
    case 53:
      return CancledSvg;

    default:
      return CallSvg;
  }
};

class OrdersDetailPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      titlepage: (
        <div>
          <Link className="StOpcVal" to="/confirmation">
            {Translate("titles", "confirmation")}
          </Link>
          " ➔ "
        </div>
      ),
      product: [],
      orderproducts: [],
      is_exchange: false,
      is_refund: false,
      totalprice: 0,
      productprice: 0,
      deliveryprice: 0,
      expressdeliery: null,
      maxdate: "",
      adress: "",
      clientname: "",
      phoneclient: "",
      deliverystatus: "",
      id: "",
      status: "",
      pagesource: 1,
      alertdelet: null,
      agent: null,
      created_at: null,
      in_hub_at: null,
      ready_to_shipped_at: null,
      delivered_at: null,
      shipped_at: null,
      taken_by_store_at: null,
    };
    this.IndexState = 0;
    this.GotToEdit = this.GotToEdit.bind(this);
    this.removeOrder = this.removeOrder.bind(this);
  }
  ConcatProduStr(product) {
    return (
      Array.isArray(product) &&
      product.map((elem) => {
        return [<div className="wharp_spce">{" (" + elem.quantity + ") " + elem.logistical_description}</div>];
      })
    );
  }
  componentWillReceiveProps(nextProps) {
    const {
      match: { params },
      GettingConfirmOrders,
      ConfOrdersList,
    } = nextProps;

    if (this.props.GettingConfirmOrders === "0" && GettingConfirmOrders === "1" && ConfOrdersList) {
      let idorder = params.idorder;
      if (idorder) {
        let order = ConfOrdersList.ressuc.results.find((elem) => elem.id === idorder);
        if (order) {
          let products = order.products || [{ logistical_description: order.product_name, quantity: order.product_quantity }];
          const { external_id } = order;
          this.setState({
            titlepage: (
              <div className="StDrc">
                <Link className="StOpcVal" to={"/confirmation" + ConfOrdersList.filter}>
                  {Translate("titles", "confirmation")}
                </Link>
                <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
                <div className="Inlflx">{Translate("orders", "orderdetail")}</div>
                {external_id && <div className="Inlflx StWdDst">#</div>}
                <div className="Inlflx">{external_id}</div>
              </div>
            ),
            id: order.id,
            external_id: order.external_id,
            product: this.ConcatProduStr(products),
            orderproducts: products,
            is_exchange: order.is_exchange,
            is_refund: order.is_refund,
            totalprice: order.total_price,
            // productprice:order.product_price,
            // deliveryprice:order.price,
            expressdeliery: order.express ? Translate("orders", "yes") : Translate("orders", "no"),
            maxdate: order.max_delivery_date,
            wilaya: order.wilaya,
            commune: order.commune,
            adress: order.customer_address,
            clientname: order.customer_name,
            phoneclient: order.customer_phone,
            deliverystatus: order.status,
            // status:order.status,
            status: ORDER_HELPERS.mapStatusToCode(order.status),
            history: order.history,
            cancellation_reason: order.cancellation_reason,
            pagesource: this.state.pagesource,
            agent: order.agent,
            created_at: order.ordered_at,
          });
        } else {
          this.props.history.push("/confirmation" + this.state.pagesource);
        }
      } else {
        this.props.history.push("/confirmation" + this.state.pagesource);
      }
    }
  }
  componentDidUpdate() {
    var UpdateStatus = document.querySelector(".HidLstELm_" + (this.IndexState - 1));
    if (UpdateStatus && UpdateStatus.style) {
      UpdateStatus.style.visibility = "hidden";
    }
  }
  componentDidMount() {
    document.title = "Maystro Delivrey - " + Translate("titles", "confirmation");
    initGAPg();
    const {
      match: { params },
      ConfOrdersList,
    } = this.props;
    let idorder = params.idorder;
    let dataCommed = null;
    if (!(ConfOrdersList && ConfOrdersList.ressuc.results && ConfOrdersList.ressuc.results.find((order) => order.id === idorder))) {
      this.props.GetOrders(`?id=${idorder}`, false);
    } else {
      dataCommed = ConfOrdersList.ressuc.results.find((order) => order.id === idorder);
    }
    if (dataCommed) {
      let products = dataCommed.products || [{ logistical_description: dataCommed.product_name, quantity: dataCommed.product_quantity }];
      const { external_id } = dataCommed;
      this.setState({
        titlepage: (
          <div className="StDrc">
            <Link className="StOpcVal" to={"/confirmation" + ConfOrdersList.filter}>
              {Translate("titles", "confirmation")}
            </Link>
            <div className="Inlflx StWdDst">{Translate("orders", "flech")}</div>
            <div className="Inlflx">{Translate("orders", "orderdetail")}</div>
            {external_id && <div className="Inlflx StWdDst">#</div>}
            <div className="Inlflx">{external_id}</div>
          </div>
        ),
        id: dataCommed.id,
        external_id: dataCommed.external_id,
        product: this.ConcatProduStr(products),
        orderproducts: products,
        is_exchange: dataCommed.is_exchange,
        is_refund: dataCommed.is_refund,
        totalprice: dataCommed.total_price,
        // productprice:dataCommed.product_price,
        // deliveryprice:dataCommed.price,
        expressdeliery: dataCommed.express ? Translate("orders", "yes") : Translate("orders", "no"),
        maxdate: dataCommed.max_delivery_date,
        wilaya: dataCommed.wilaya,
        commune: dataCommed.commune,
        adress: dataCommed.customer_address,
        clientname: dataCommed.customer_name,
        phoneclient: dataCommed.customer_phone,
        deliverystatus: dataCommed.status,
        status: ORDER_HELPERS.mapStatusToCode(dataCommed.status),
        history: dataCommed.history,
        cancellation_reason: dataCommed.cancellation_reason,
        pagesource: this.state.pagesource,
        agent: dataCommed.agent,
        created_at: dataCommed.ordered_at,
      });
    }
  }
  GotToEdit(path) {
    Event("CONFIRM_ORDER_DETAIL", "GO_TO_EDIT_FROM_DETAIL_PAGE", "CLICK_EVENT");
    this.props.history.push(path, { page: this.state.pagesource });
  }
  removeOrder(id) {
    Event("CONFIRM_ORDER_DETAIL", "REMOVE_ORDER_FROM_DETAIL", "CLICK_EVENT");
    let idremove = this.state.alertdelet;
    if (idremove) {
      this.props.UpdateStaOrder({ status: 50 }, idremove);
      this.setState({
        alertdelet: null,
      });
    } else {
      this.setState({
        alertdelet: id,
      });
    }
  }
  showOpt(name) {
    let act = this.state[name];
    this.setState({
      [name]: !act,
    });
  }
  render() {
    const { OrderUpdSta } = this.props;

    let btns;

    this.IndexState = 0;
    let statusTimeline =
      this.state.history && this.state.history.length
        ? this.state.history.map((row, index) => {
            let statusCode = ORDER_HELPERS.mapStatusToCode(row.status);
            let details = [FormatDate(row.created_at)].concat(statusCode === 100 ? (this.state.cancellation_reason ? [Translate("abortRason", this.state.cancellation_reason.id)] : [Translate("abortRason", "rsnunkon")]) : []).concat([<span className={`StAbs status_${row.status} LeftSet StTpval2 WdSmP HidLstELm_${index}`} style={{ height: "100%" }}></span>]);
            return [<ListDetailIcon icon={getStatusIcon(row.status)} Styleicon={ORDER_HELPERS.getStatusIconColor(statusCode)} title={Translate("statusconfirm", statusCode + "")} details={details} />];
          })
        : [];

    this.IndexState = statusTimeline.length;

    let ProductsDiv = [[<ListDetailIcon icon={PackageSvg} title={Translate("product", "productname")} details={this.state.product} />]];
    return (
      <ContainerPage page_title={this.state.titlepage} data_top={btns}>
        <div className="FlWd InFlx responsFlx flxDrc">
          {OrderUpdSta === "0" && <Loader />}
          <div className="Hlwd">
            <Table
              ntdir={true}
              thead_elem={[Translate("orders", "detailorder")]}
              tbody_elem={[
                ...ProductsDiv,
                [
                  <div className="FlWd InFlx responsFlx flxDrc">
                    <div className="Hlwd">{this.props?.dtStoreinfo?.country?.id === 1 ? <ListDetailIcon icon={PriceSvg} title={Translate("orders", "totalprice")} details={[FormatCurrency.format(this.state.totalprice)]} /> : <ListDetailIcon icon={PriceSvg} title={Translate("orders", "totalprice")} details={[this.state.totalprice + "  " + "TND"]} />}</div>
                  </div>,
                ],
                [
                  <div className="FlWd InFlx responsFlx flxDrc">
                    <div className="Hlwd">
                      <ListDetailIcon icon={LocationSvg} title={Translate("orders", "adresse")} details={[this.state.adress]} />
                    </div>
                    <div className="Hlwd Mrg2">
                      <ListDetailIcon
                        icon={LocationSvg}
                        title={Translate("orders", "district")}
                        details={[
                          <span>
                            {this.state.commune} / {this.state.wilaya}
                          </span>,
                        ]}
                      />
                    </div>
                  </div>,
                ],
                [<ListDetailIcon icon={PersonSvg} title={Translate("orders", "customername")} details={[this.state.clientname]} />],
                [<ListDetailIcon icon={CallSvg} title={Translate("orders", "customerphonenumber")} details={[this.state.phoneclient]} />],
              ]}
            />
            <div className="StMrtp">
              <Table ntdir={true} thead_elem={[Translate("orders", "notes")]} tbody_elem={[[<ListDetailIcon icon={<i className="InFlx StBlFil st_smpld">{StoreSvg}</i>} title={Translate("orders", "notetodriver")} details={[<div className="wharp_spce">{this.state.notDrive}</div>]} />]]} />
            </div>
          </div>
          <div className="Hlwd Mrg2">
            <div>
              <Table ntdir={true} thead_elem={[Translate("confirmation", "timeline")]} tbody_elem={statusTimeline} />
            </div>
            {this.state.agent && (
              <div className="StMrtp">
                <Table ntdir={true} thead_elem={[Translate("orders", "deliveryagent")]} tbody_elem={[[<ListDetailIcon icon={ManagentSvg} title={Translate("auths", "fullname")} details={[this.state.agent.first_name + " " + this.state.agent.last_name]} />], [<ListDetailIcon icon={CallSvg} title={Translate("auths", "phone")} details={[this.state.agent.phone]} />]]} />
              </div>
            )}
          </div>
        </div>
        {this.state.alertdelet && (
          <TabShow tab_title={Translate("profile", "confirmaction")} wdth="550px" NotCls={true}>
            <div className="MrAot" style={{ width: "94%" }}>
              <p className="StdirLn FlWd DltTpMar">{Translate("orders", "confrmdelete")}</p>
              <div className="InFlx flxDrc StMrtp MrAot StwdMaxC">
                <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={() => this.showOpt("alertdelet")}>
                  <BtnLg text={Translate("profile", "cancel")} classSet={""} />
                </div>
                <div className="StwdMaxC CrsPoi StWdDst" style={{ height: "30px" }} onClick={this.removeOrder}>
                  <BtnLg text={Translate("profile", "delete")} classSet={"TxDng"} />
                </div>
              </div>
            </div>
          </TabShow>
        )}
      </ContainerPage>
    );
  }
}
function mapState(state) {
  const { GettingConfirmOrders, ConfOrdersList, OrderUpdSta, DataUpdSta } = state.confOrders;
  const { Searching, DataSearch } = state.search;
  const { dataProfile, dtStoreinfo } = state.user;
  return { GettingConfirmOrders, ConfOrdersList, OrderUpdSta, DataUpdSta, Searching, DataSearch, dtStoreinfo };
}
const actionCreators = {
  GetOrders: ordersActions.GetConfirmationOrders,
  UpdateStaOrder: ordersActions.UpdateStaOrder,
  SearchOrders: searchActions.SearchOrders,
};

const connectedOrdersDetailPage = connect(mapState, actionCreators)(OrdersDetailPage);
export { connectedOrdersDetailPage as OrdersDetailPage };
