import { ordersConstants } from "../constants";
import { RequestService, FunctionService } from "../services";
import Cookies from "js-cookie";
const token = Cookies.get("auth");
export const ordersActions = {
  AddOrder,
  GetOrders,
  GetOrderHistory,
  UpdateOrder,
  UpdateStaOrder,
  GetUnfOrders,
  UpdateUnfOrder,
  GetConfirmationOrders,
  GetConfirmationSummary,
  ExportOrder,
  ImportOrder,
  ClearOrderStore,
};
function AddOrder(order) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.ADD_ORDER_REQUEST));
    RequestService.PostRequest("stores/orders/", order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, ordersConstants.ADD_ORDER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.ADD_ORDER_FAILURE)
        );
      });
  };
}
function ImportOrder(orders, product) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.IMPORT_ORDER_REQUEST));
    RequestService.PostRequest("stores/import_order/" + product + "/", orders, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, ordersConstants.IMPORT_ORDER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.IMPORT_ORDER_FAILURE)
        );
      });
  };
}
function UpdateOrder(order, idorder) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.UPDATE_ORDER_REQUEST));
    RequestService.PatchRequest("stores/orders/" + idorder + "/", order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(res, ordersConstants.UPDATE_ORDER_SUCCESS)
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.UPDATE_ORDER_FAILURE)
        );
      });
  };
}
function UpdateStaOrder(order, idorder) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.UPDATE_STATUS_ORDER_REQUEST)
    );
    RequestService.PatchRequest("shared/status/" + idorder + "/", order, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res,
            ordersConstants.UPDATE_STATUS_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err,
            ordersConstants.UPDATE_STATUS_ORDER_FAILURE
          )
        );
      });
  };
}

function ExportOrder(filter, fileEx, isTempOrder) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.EXPORT_ORDER_REQUEST));
    const url =
      isTempOrder === "IS_TEMP_ORDER"
        ? "stores/temp_orders/?pagination=false&status=0"
        : "stores/orders/?pagination=false" + filter.replace("?", "&");
    RequestService.GetRequest(url, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            {
              data:
                isTempOrder === "IS_TEMP_ORDER"
                  ? { list: res.data.results }
                  : res.data,
              fileType: fileEx,
            },
            ordersConstants.EXPORT_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.EXPORT_ORDER_FAILURE)
        );
      });
  };
}
function GetUnfOrders(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_UNF_ORDER_REQUEST));
    RequestService.GetRequest("stores/temp_orders/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: filter },
            ordersConstants.GET_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_UNF_ORDER_FAILURE
          )
        );
      });
  };
}

function UpdateUnfOrder(order, dataorder) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.UPDATE_UNF_ORDER_REQUEST));
    let url = "stores/temp_orders/" + dataorder + "/";
    RequestService.PutRequest(url, order, { Authorization: "Token " + token })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            res.data,
            ordersConstants.UPDATE_UNF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(err, ordersConstants.UPDATE_UNF_ORDER_FAILURE)
        );
      });
  };
}

function GetOrders(filter, persistFilter = true) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_ORDER_REQUEST));
    RequestService.GetRequest("stores/orders/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: persistFilter ? filter : "" },
            ordersConstants.GET_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_ORDER_FAILURE
          )
        );
      });
  };
}
function GetOrderHistory(filter) {
  return (dispatch) => {
    dispatch(
      FunctionService.request(ordersConstants.GET_ORDER_HISTORY_REQUEST)
    );
    RequestService.GetRequest("stores/history_order/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data },
            ordersConstants.GET_ORDER_HISTORY_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_ORDER_HISTORY_FAILURE
          )
        );
      });
  };
}
function GetConfirmationOrders(filter, persistFilter = true) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_CONF_ORDER_REQUEST));
    RequestService.GetRequest("confirmation/orders_2b_confirmed/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: persistFilter ? filter : "" },
            ordersConstants.GET_CONF_ORDER_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_CONF_ORDER_FAILURE
          )
        );
      });
  };
}
function GetConfirmationSummary(filter) {
  return (dispatch) => {
    dispatch(FunctionService.request(ordersConstants.GET_CONF_SUMMARY_REQUEST));
    RequestService.GetRequest("confirmation/reports/" + filter, {
      Authorization: "Token " + token,
    })
      .then((res) => {
        dispatch(
          FunctionService.succes(
            { ressuc: res.data, filter: filter },
            ordersConstants.GET_CONF_SUMMARY_SUCCESS
          )
        );
      })
      .catch((err) => {
        dispatch(
          FunctionService.failure(
            err.response,
            ordersConstants.GET_CONF_SUMMARY_FAILURE
          )
        );
      });
  };
}

function ClearOrderStore() {
  return (dispatch) => {
    dispatch({ type: "CLEAR" });
  };
}
