export const ordersConstants = {
    ADD_ORDER_SUCCESS: 'ADD_ORDER_SUCCESS',
    ADD_ORDER_FAILURE: 'ADD_ORDER_FAILURE',
    ADD_ORDER_REQUEST: 'ADD_ORDER_REQUEST',

    IMPORT_ORDER_SUCCESS: 'IMPORT_ORDER_SUCCESS',
    IMPORT_ORDER_FAILURE: 'IMPORT_ORDER_FAILURE',
    IMPORT_ORDER_REQUEST: 'IMPORT_ORDER_REQUEST',

    UPDATE_ORDER_SUCCESS: 'UPDATE_ORDER_SUCCESS',
    UPDATE_ORDER_FAILURE: 'UPDATE_ORDER_FAILURE',
    UPDATE_ORDER_REQUEST: 'UPDATE_ORDER_REQUEST',

    UPDATE_STATUS_ORDER_SUCCESS: 'UPDATE_STATUS_ORDER_SUCCESS',
    UPDATE_STATUS_ORDER_FAILURE: 'UPDATE_STATUS_ORDER_FAILURE',
    UPDATE_STATUS_ORDER_REQUEST: 'UPDATE_STATUS_ORDER_REQUEST',

    EXPORT_ORDER_SUCCESS: 'EXPORT_ORDER_SUCCESS',
    EXPORT_ORDER_FAILURE: 'EXPORT_ORDER_FAILURE',
    EXPORT_ORDER_REQUEST: 'EXPORT_ORDER_REQUEST',

    GET_ORDER_SUCCESS: 'GET_ORDER_SUCCESS',
    GET_ORDER_FAILURE: 'GET_ORDER_FAILURE',
    GET_ORDER_REQUEST: 'GET_ORDER_REQUEST',

    GET_ORDER_HISTORY_SUCCESS: 'GET_ORDER_HISTORY_SUCCESS',
    GET_ORDER_HISTORY_FAILURE: 'GET_ORDER_HISTORY_FAILURE',
    GET_ORDER_HISTORY_REQUEST: 'GET_ORDER_HISTORY_REQUEST',

    GET_UNF_ORDER_SUCCESS: 'GET_UNF_ORDER_SUCCESS',
    GET_UNF_ORDER_FAILURE: 'GET_UNF_ORDER_FAILURE',
    GET_UNF_ORDER_REQUEST: 'GET_UNF_ORDER_REQUEST',

    UPDATE_UNF_ORDER_SUCCESS: 'UPDATE_UNF_ORDER_SUCCESS',
    UPDATE_UNF_ORDER_FAILURE: 'UPDATE_UNF_ORDER_FAILURE',
    UPDATE_UNF_ORDER_REQUEST: 'UPDATE_UNF_ORDER_REQUEST',

    GET_CONF_SUMMARY_SUCCESS: 'GET_CONF_SUMMARY_SUCCESS',
    GET_CONF_SUMMARY_FAILURE: 'GET_CONF_SUMMARY_FAILURE',
    GET_CONF_SUMMARY_REQUEST: 'GET_CONF_SUMMARY_REQUEST',

    GET_CONF_ORDER_SUCCESS: 'GET_CONF_ORDER_SUCCESS',
    GET_CONF_ORDER_FAILURE: 'GET_CONF_ORDER_FAILURE',
    GET_CONF_ORDER_REQUEST: 'GET_CONF_ORDER_REQUEST',

    UPDATE_CONF_ORDER_SUCCESS: 'UPDATE_CONF_ORDER_SUCCESS',
    UPDATE_CONF_ORDER_FAILURE: 'UPDATE_CONF_ORDER_FAILURE',
    UPDATE_CONF_ORDER_REQUEST: 'UPDATE_CONF_ORDER_REQUEST',
};